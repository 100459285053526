import { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { UserArchivePopup } from 'pages/CRUDUser/UserArchivePopup'
import { getCommonsUsersList } from './api'
import { useDebounce } from 'hooks/hooks'
import useRouter from 'hooks/useRouter'
import { setLoading } from 'redux/store/common/slice'
import { routes } from 'router/Config/config.routes'
import { getUser } from 'redux/store/user/getters'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { AVAILABLE_FOR_CCS, columns } from './constants'
import { fullFormatPhoneNumber, isTableHasParams } from 'helper/common'
import { ability, ACTIONS, SUBJECTS, Can, ROLES } from 'features/Permission'
import { USER_STATUSES } from 'constants/common'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import {
  TCommonsUser,
  TCommonsUsersRequestData,
  TCommonsUsersFilters,
  TCommonsUsersFiltersFunc,
  TCommonsUsersParams
} from './types'
import './styles.scss'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'

export const CommonsUsers = () => {
  const [tableData, setTableData] = useState<TCommonsUser[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<TCommonsUser[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TCommonsUsersRequestData>()
  const [filters, setFilters] = useState<TCommonsUsersFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { push } = useRouter()
  const debouncedSearch = useDebounce(searchValue, 500)

  const isCCS = useMemo(
    () => Boolean(user.role === ROLES.COMMONS_CONTRACT_STEWARD),
    [user.role]
  )

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (user.commons) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
  }, [debouncedSearch, user.commons])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TCommonsUsersRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TCommonsUsersParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getCommonsUsersList({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const createUser = () =>
    push(`${routes.createUserProfile}/new`, {
      state: { redirectTab: SETTINGS_TABS[6].key }
    })
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: TCommonsUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  return (
    <div className="table-wrapper Commons-users">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              placeholder: PLACEHOLDERS.SEARCH_USERS_BY_NAME,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header_buttons">
          <Can I={ACTIONS.CRUD} a={SUBJECTS.COMMONS_USERS}>
            {!selectedRowKeys.length && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={createUser}
                upperCase
              >
                {BTN_TXT.CREATE_USER}
              </Button>
            )}
            {!!selectedRows.length &&
              selectedRows.filter((i) => i.status !== USER_STATUSES.ARCHIVED)
                .length === selectedRows.length && (
                <UserArchivePopup<TCommonsUser>
                  selected={selectedRows}
                  callback={() => {
                    setSelectedRowKeys([])
                    setSelectedRows([])
                    getTableData({ ...sortParams, ...filters })
                  }}
                  isTable
                />
              )}
          </Can>
        </div>
      </div>
      {!firstLoad && (
        <Table<TCommonsUser>
          dataSource={tableData}
          className="rfp-users-data-list-table"
          columns={columns({
            applyFilters,
            filters
          })}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          onChangePage={handleChangePageSize}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TCommonsUsersFilters>(
            debouncedSearch,
            filters
          )}
          createButton={
            ability.can(ACTIONS.CRUD, SUBJECTS.COMMONS_USERS)
              ? {
                  buttonText: BTN_TXT.CREATE_USER,
                  availability: true,
                  action: createUser
                }
              : undefined
          }
          rowSelection={
            ability.can(ACTIONS.CRUD, SUBJECTS.COMMONS_USERS)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  getCheckboxProps: (record) => ({
                    disabled:
                      record.uuid === user.uuid ||
                      (isCCS &&
                        !AVAILABLE_FOR_CCS.find((i) => i === record?.role))
                  })
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
