import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { subject } from '@casl/ability'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { useAbility } from '@casl/react'

import {
  TAwardVendorsRequestData,
  TQueryKeysForRFP,
  TRFPBiddingData,
  TRFPDetailsResponse,
  TRFPDocument,
  TUpdate
} from 'features/RFP/RFPDetails/types'
import {
  initialBiddingData,
  initialState,
  initialUpdateData,
  RFP_DETAILS_TABS
} from 'features/RFP/RFPDetails/constants'
import {
  awardVendorsRequestAsync,
  cancelRFP,
  createRFPTimelineAsync,
  getRfpDetails,
  updateRFP,
  updateRFPByVendor,
  getBiddingData,
  uploadFiles,
  uploadVendorFiles,
  startVendorsSelectionRequestAsync
} from 'features/RFP/RFPDetails/api'
import {
  RFP_ACCEPTANCE_STATUSES,
  RFP_STATUSES,
  RFP_STATUSES_NAMES,
  RFP_STATUSES_TO_HIDE_BUTTONS_FOR_VENDOR
} from 'features/RFP/constants'
import {
  timelinesRequestDataConverter,
  timelinesResponseDataConverter
} from 'features/RFP/RFPDetails/utils'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { AbilityContext, ACTIONS, ROLES, SUBJECTS } from 'features/Permission'
import { TDocumentTypeUnion } from 'components/FileUpload/types'
import {
  ITimeline,
  ITimelineValidation,
  TTimelines,
  TTimelinesValidation
} from 'components/Timelines/types'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import { getUser } from 'redux/store/user/getters'
import { setLoaderProps, setLoading } from 'redux/store/common/slice'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { getAssignedUsers } from '../../RFPHealthSystemUsers/api'

type ContextProps = {
  state: {
    data: TRFPDetailsResponse
    biddingData: TRFPBiddingData
    timelineValidation: TTimelinesValidation<typeof RFP_STATUSES>
    timelineForm: TTimelines<typeof RFP_STATUSES>
    isCurrentUserStakeholder: boolean
    isVendor: boolean
    canEditRfp: boolean
    canAddRfpUser: boolean
    isCanSelectUsers: boolean
    isUpdate: TUpdate
    isVCS: boolean
    isVendorNotAnswerRfp: boolean
    isCanManageTimelines: boolean
    isCommons: boolean
    canResendBid: boolean
    initQueryParams: TQueryKeysForRFP
    isVendorCanUploadDocuments: boolean
    canSeeAssignBanner: boolean
    canCRUDCommonsResponsibles: boolean
    canReassignCommons: boolean
    isCurrentUserCommonsStakeholderAndFU: boolean
    isAllCommonsResponsiblesWasAdded: boolean
    canManageVendors: boolean
    canSeeBidResendReason: boolean
    canCRUDCommonsLegalAndAnalystsResponsibles: boolean
  } & TabsReturnState
  actions: {
    handleGetRfpData: () => Promise<void>
    handleCancelRFP: VoidFunction
    createRfpTimelinesAsync: (values: ITimeline<typeof RFP_STATUSES>[]) => void
    awardVendorsAsync: (data: TAwardVendorsRequestData) => void
    handleUploadFile: (
      files: File[],
      documentType: TDocumentTypeUnion
    ) => Promise<void>
    handleUploadVendorFiles: (
      files: File[],
      documentType: string
    ) => Promise<void>
    handleChangeRFPStatus: (status: string, callback?: () => void) => void
    handleDeleteAttachment: (uuid: string) => Promise<void>
    getDocumentsByType: (type: TDocumentTypeUnion) => TRFPDocument[]
    resetInitQueryParams: () => void
    handleGetBiddingData: () => Promise<void>
    handleChangeRFPAcceptance: (status: string) => Promise<void>
    handleDeleteVendorAttachment: (uuid: string) => Promise<void>
    startVendorsSelectionAsync: () => Promise<void>
    checkIsDeadlineMissed: () => boolean
    setIsUpdate: Dispatch<SetStateAction<TUpdate>>
  } & TabsReturnActions
}

const RFPDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const RFPDetailsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const { id, tab } = useParams()
  const { search } = useLocation()
  const user = useSelector(getUser)
  const ability = useAbility<any>(AbilityContext)
  const [initQueryParams, setInitQueryParams] = useState<TQueryKeysForRFP>({})
  const [isFacilityUser, setIsFacilityUser] = useState<boolean>(false)

  useEffect(() => {
    setInitQueryParams(Object.fromEntries(new URLSearchParams(search)))
  }, [])

  const resetInitQueryParams = useCallback(() => setInitQueryParams({}), [])

  const {
    sendRFPPopup,
    cancelRFPPopup,
    awardVendorsPopup,
    changeRFPResponsePopup
  } = useRFPDetailsPopupContext()

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: RFP_DETAILS_TABS,
    activeTab: tab || RFP_DETAILS_TABS[0].key
  })

  const { setTabs } = tabsActions

  const [data, _setData] = useState<TRFPDetailsResponse>(initialState)

  const [timelineForm, _setTimelineForm] = useState<
    TTimelines<typeof RFP_STATUSES>
  >(timelinesResponseDataConverter(data.timelines || []))
  const [timelineValidation] = useState<ITimelineValidation>(
    {} as ITimelineValidation
  )
  const [biddingData, setBiddingData] =
    useState<TRFPBiddingData>(initialBiddingData)
  const [isUpdate, setIsUpdate] = useState<TUpdate>(initialUpdateData)

  const getDocumentsByType = useCallback(
    (type: TDocumentTypeUnion) =>
      data.default_documents.filter((file) => file.document_type === type),
    [data.default_documents]
  )
  const canSeeAssignBanner = useMemo(() => {
    return ability.can(
      ACTIONS.VIEW,
      subject(SUBJECTS.ASSIGN_RFP_BANNER, { ...data })
    )
  }, [ability, data])

  const canManageVendors = useMemo(() => {
    return ability.can(ACTIONS.CRUD, subject(SUBJECTS.VENDORS_TAB, { ...data }))
  }, [ability, data])

  const canCRUDCommonsResponsibles = useMemo(() => {
    return (
      ability.can(
        ACTIONS.CREATE,
        subject(SUBJECTS.COMMONS_RESPONSIBLE_USER, { ...data })
      ) &&
      data.status !== RFP_STATUSES.ARCHIVED &&
      data.status !== RFP_STATUSES.CANCELED
    )
  }, [ability, data])

  const canCRUDCommonsLegalAndAnalystsResponsibles = useMemo(() => {
    return (
      ability.can(
        ACTIONS.CREATE,
        subject(SUBJECTS.COMMONS_RESPONSIBLE_USERS, { ...data })
      ) &&
      data.status !== RFP_STATUSES.ARCHIVED &&
      data.status !== RFP_STATUSES.CANCELED
    )
  }, [ability, data])

  const canReassignCommons = useMemo(
    () => user.role === ROLES.COMMONS_VICE_PRESIDENT,
    [user]
  )
  const isCurrentUserStakeholder = useMemo(
    () => user.role === ROLES.STAKEHOLDER,
    [user]
  )

  const isCurrentUserCommonsStakeholderAndFU = useMemo(() => {
    return user.role === ROLES.COMMONS_STAKEHOLDER && isFacilityUser
  }, [user, isFacilityUser])

  const getIsFacilityUser = () => {
    if (!id || id === 'new') return
    getAssignedUsers(id as string, {
      params: {
        limit: 1,
        offset: 0,
        search: user.email
      }
    }).then((res) => {
      setIsFacilityUser(!!res.data.results?.length)
    })
  }

  const canEditRfp = useMemo(
    () => ability.can(ACTIONS.EDIT, subject(SUBJECTS.RFP, { ...data })),
    [ability, data]
  )

  const canAddRfpUser = useMemo(
    () =>
      canEditRfp ||
      ability.can(
        ACTIONS.CREATE,
        subject(SUBJECTS.HOSPITAL_USERS, { ...data })
      ),
    [ability, data, canEditRfp]
  )

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])
  const isCommons = useMemo(() => Boolean(user.commons), [user.commons])

  const canSeeBidResendReason = useMemo(() => {
    return (
      isVendor &&
      !!data.resend &&
      data.status !== RFP_STATUSES.CANCELED &&
      data.status !== RFP_STATUSES.ARCHIVED
    )
  }, [data.status, data.resend, isVendor])

  const isVendorNotAnswerRfp = useMemo(
    () =>
      isVendor &&
      (!biddingData.acceptance_status ||
        (biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.OVERDUE &&
          !data.resend)) &&
      !!RFP_STATUSES_TO_HIDE_BUTTONS_FOR_VENDOR.find((i) => i === data.status),
    [isVendor, biddingData.acceptance_status, data.status, data.resend]
  )

  const isVendorCanUploadDocuments = useMemo(() => {
    return (
      isVendor &&
      user.role === ROLES.VENDOR_CONTRACT_STEWARD &&
      ((data.status === RFP_STATUSES.BIDDING_IN_PROGRESS &&
        biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.ACCEPTED) ||
        (data.status === RFP_STATUSES.PRICE_FILES_ANALYSIS &&
          !!data.resend &&
          biddingData.acceptance_status !== RFP_ACCEPTANCE_STATUSES.DECLINED))
    )
  }, [data.status, biddingData.acceptance_status])

  const checkIsDeadlineMissed = useCallback(
    () => moment(data.expire_at).isSameOrBefore(moment()),
    [data.expire_at]
  )

  const canResendBid = useMemo(() => {
    return ability.can(ACTIONS.RESEND, subject(SUBJECTS.BID, { ...data }))
  }, [ability, data])

  const handleGetBiddingData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const resp = await getBiddingData(id as string)

      if (resp.data) {
        setBiddingData(resp.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const handleGetRfpData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const resp = await getRfpDetails(id as string)
      if (resp.data) {
        _setData({
          ...resp.data,
          ['autogenerated_default_documents']:
            resp.data.autogenerated_default_documents.map((i) => ({
              ...i,
              isCannotBeDeleted: true
            }))
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const handleCancelRFP = useCallback(() => {
    dispatch(setLoading(true))
    cancelRFP(data.uuid)
      .then(() => {
        handleGetRfpData()
        cancelRFPPopup.actions.close()
      })
      .catch(() => dispatch(setLoading(false)))
  }, [cancelRFPPopup.actions, data.uuid, dispatch, handleGetRfpData])

  const createRfpTimelinesAsync = useCallback(
    async (values: ITimeline<typeof RFP_STATUSES>[]) => {
      if (!id) {
        throw new Error('RFP ID not provided')
      }
      if (
        data.default_documents.filter((i) => i.document_type === 'document')
          .length === 0
      ) {
        notification.error({
          message: VALIDATION_MESSAGES.SM0024
        })
        return
      }
      if (
        data.default_documents.filter((i) => i.document_type === 'questions')
          .length === 0
      ) {
        notification.error({
          message: VALIDATION_MESSAGES.SM0036
        })
        return
      }

      try {
        dispatch(setLoading(true))

        const createTimelinesResponse = await createRFPTimelineAsync(id, {
          timelines: timelinesRequestDataConverter(values)
        })
        await handleGetRfpData()

        if (createTimelinesResponse) {
          sendRFPPopup.actions.close()
          isUpdate
            ? notification.success({
                message:
                  VALIDATION_MESSAGES.THE_TIMEFRAMES_FOR_RFP_STEPS_HAVE_BEEN_UPDATED
              })
            : notification.success({
                message:
                  VALIDATION_MESSAGES.VENDOR_ACCEPTANCE_STEP_HAS_BEEN_STARTED
              })
        }
      } catch (response) {
        const { status, data } = response as any

        if (status === 400 && data?.details && data.details[0]) {
          notification.error({
            message: data.details[0]
          })
        }
        if (
          status === 400 &&
          data?.non_field_errors &&
          data.non_field_errors[0]
        ) {
          notification.error({
            message: data.non_field_errors[0]
          })
        }
        if (status === 400 && data?.timelines[0].expire_at.expire_at) {
          notification.error({ message: VALIDATION_MESSAGES.SM0030 })
        }
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      id,
      data.default_documents,
      dispatch,
      handleGetRfpData,
      sendRFPPopup.actions,
      isUpdate
    ]
  )

  const handleChangeRFPAcceptance = useCallback(
    async (status: string) => {
      dispatch(setLoading(true))
      try {
        await updateRFPByVendor(
          id as string,
          { acceptance_status: status },
          `${
            status === RFP_ACCEPTANCE_STATUSES.ACCEPTED
              ? VALIDATION_MESSAGES.SM0026
              : VALIDATION_MESSAGES.SM0027
          }`
        )
        await handleGetRfpData()
        await handleGetBiddingData()
        changeRFPResponsePopup.actions.close()
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      dispatch,
      id,
      handleGetRfpData,
      handleGetBiddingData,
      changeRFPResponsePopup.actions
    ]
  )

  const startVendorsSelectionAsync = useCallback(async () => {
    if (!id) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      await startVendorsSelectionRequestAsync(id, {
        status: RFP_STATUSES.VENDORS_SELECTION
      })

      await handleGetRfpData()
    } catch (err: any) {
      console.error(err)
      if (!!err.data?.details) {
        notification.error({
          message: err.data?.details[0]
        })
      } else if (!!err.data?.non_field_errors) {
        notification.error({
          message: err.data?.non_field_errors[0]
        })
      }
    } finally {
      dispatch(setLoading(false))
    }
  }, [id, dispatch, handleGetRfpData])

  const awardVendorsAsync = useCallback(
    async (data: TAwardVendorsRequestData) => {
      if (!id) {
        throw new Error('RFP ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await awardVendorsRequestAsync(id, data)
        notification.success({
          message: VALIDATION_MESSAGES.SM0043
        })

        await handleGetRfpData()

        awardVendorsPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, handleGetRfpData, awardVendorsPopup.actions]
  )

  const handleUploadFile = useCallback(
    async (files: File[], documentType: string) => {
      dispatch(setLoading(true))
      dispatch(
        setLoaderProps({
          isFileUpload: true,
          message: 'We are uploading the document. This might take some time...'
        })
      )

      const formData = new FormData()
      formData.append(documentType, files[0], files[0].name)

      try {
        const response = await uploadFiles({
          document_type: documentType,
          file: formData.get(documentType)
        })

        await updateRFP(data.uuid, {
          default_documents_ids: [
            ...data.default_documents.map((i) => i.uuid),
            response.data.uuid
          ]
        })

        notification.success({ message: VALIDATION_MESSAGES.SM0044 })

        await handleGetRfpData()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
        dispatch(
          setLoaderProps({
            isFileUpload: false,
            message: ''
          })
        )
      }
    },
    [data.default_documents, data.uuid, dispatch, handleGetRfpData]
  )

  const handleUploadVendorFiles = useCallback(
    async (files: File[], documentType: string) => {
      dispatch(setLoading(true))
      dispatch(
        setLoaderProps({
          isFileUpload: true,
          message: 'We are uploading the document. This might take some time...'
        })
      )

      const formData = new FormData()
      formData.append(documentType, files[0], files[0].name)

      try {
        const response = await uploadVendorFiles({
          document_type: documentType,
          file: formData.get(documentType)
        })

        await updateRFPByVendor(data.uuid, {
          document_ids: [
            ...biddingData.documents.map((i) => i.uuid),
            response.data.uuid
          ]
        })

        notification.success({ message: VALIDATION_MESSAGES.SM0044 })

        await handleGetBiddingData()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
        dispatch(
          setLoaderProps({
            isFileUpload: false,
            message: ''
          })
        )
      }
    },
    [biddingData.documents, data.uuid, dispatch, handleGetBiddingData]
  )

  const isVCS = useMemo(
    () => Boolean(user.role === ROLES.VENDOR_CONTRACT_STEWARD),
    [user.role]
  )
  const isAllCommonsResponsiblesWasAdded = useMemo(() => {
    return Boolean(
      data?.commons_responsibles?.find(
        (u) => u.role === ROLES.COMMONS_ANALYST
      ) &&
        data?.commons_responsibles?.find((u) => u.role === ROLES.COMMONS_LEGAL)
    )
  }, [data])

  const isCanManageTimelines = useMemo(
    () =>
      Boolean(
        ((user.role === ROLES.VICE_PRESIDENT ||
          user.role === ROLES.CONTRACT_STEWARD) &&
          !!data?.community_responsibles?.find((i) => i.uuid === user.uuid)) ||
          (data.participation_type === 'clinically_led_central' &&
            (user.role === ROLES.COMMONS_VICE_PRESIDENT ||
              user.role === ROLES.COMMONS_CONTRACT_STEWARD) &&
            !!data?.commons_responsibles?.find((i) => i.uuid === user.uuid)) ||
          data?.creator?.uuid === user.uuid
      ),
    [user, data.community_responsibles, data.creator, data.participation_type]
  )
  const handleChangeRFPStatus = useCallback(
    (status: string, callback?: () => void) => {
      dispatch(setLoading(true))
      updateRFP(data.uuid, { status })
        .then(() => {
          handleGetRfpData()
          notification.success({
            message:
              status === RFP_STATUSES.WAITING_FOR_ASSIGNMENT
                ? 'RFP has been sent to Cognus Commons'
                : `${RFP_STATUSES_NAMES[status]} step has been started`
          })
          !!callback && callback()
        })
        .catch((err) => {
          if (!!err.data?.details) {
            notification.error({
              message: err.data?.details[0]
            })
          } else if (!!err.data?.non_field_errors) {
            notification.error({
              message: err.data?.non_field_errors[0]
            })
          }

          dispatch(setLoading(false))
        })
    },
    [data.uuid, handleGetRfpData, dispatch]
  )

  const isCanSelectUsers = useMemo(
    () =>
      Boolean(
        data.status === RFP_STATUSES.ARCHIVED ||
          data.status === RFP_STATUSES.CANCELED
      ),
    [data.status]
  )

  const handleDeleteVendorAttachment = useCallback(
    async (uuid: string) => {
      dispatch(setLoading(true))
      try {
        await updateRFPByVendor(data.uuid, {
          document_ids: biddingData.documents
            .map((i) => i.uuid)
            .filter((i) => i !== uuid)
        })
        await handleGetBiddingData()
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, data.uuid, biddingData.documents, handleGetBiddingData]
  )

  const handleDeleteAttachment = useCallback(
    async (uuid: string) => {
      dispatch(setLoading(true))

      try {
        await updateRFP(data.uuid, {
          default_documents_ids: data.default_documents
            .map((i) => i.uuid)
            .filter((i) => i !== uuid)
        })

        handleGetRfpData()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [data.default_documents, data.uuid, dispatch, handleGetRfpData]
  )

  useEffect(() => {
    if (id && id !== 'new') {
      handleGetRfpData()

      if (user.vendor) {
        handleGetBiddingData()
      }
    }
  }, [id, handleGetRfpData, user.vendor, handleGetBiddingData])

  useLayoutEffect(() => {
    const isUserStakeholderOrCommonsStakeholder =
      isCurrentUserStakeholder || isCurrentUserCommonsStakeholderAndFU
    setTabs(
      RFP_DETAILS_TABS.map((i) => {
        const isVotingDisabled =
          (i.key === RFP_DETAILS_TABS[4].key &&
            isUserStakeholderOrCommonsStakeholder &&
            data.status !== RFP_STATUSES.VOTING_IN_PROGRESS &&
            data.status !== RFP_STATUSES.VENDORS_SELECTION) ||
          (i.key === RFP_DETAILS_TABS[4].key &&
            data.status !== RFP_STATUSES.PRICE_FILES_ANALYSIS &&
            data.status !== RFP_STATUSES.VOTING_IN_PROGRESS &&
            data.status !== RFP_STATUSES.VENDORS_SELECTION &&
            data.status !== RFP_STATUSES.ARCHIVED)

        const isResultsDisabled =
          i.key === RFP_DETAILS_TABS[7].key &&
          !(
            data.status === RFP_STATUSES.ARCHIVED &&
            ability.can(
              ACTIONS.VIEW,
              subject(SUBJECTS.RFP_RESULTS, { ...data })
            )
          )

        const isCurrentCSCanVote = isCurrentUserCommonsStakeholderAndFU
          ? ability.can(ACTIONS.VIEW, subject(SUBJECTS.VOTING, { ...data }))
          : true

        const isVendorsUsersDanger =
          i.key === RFP_DETAILS_TABS[5].key && !user.vendor
        const isItemListDanger =
          i.key === RFP_DETAILS_TABS[1].key &&
          (Boolean(user.vendor) || isUserStakeholderOrCommonsStakeholder)
        const isVendorsDanger =
          i.key === RFP_DETAILS_TABS[2].key &&
          (Boolean(user.vendor) || isUserStakeholderOrCommonsStakeholder)
        const isHSUsersDanger =
          i.key === RFP_DETAILS_TABS[3].key &&
          isUserStakeholderOrCommonsStakeholder
        const isVotingDanger =
          i.key === RFP_DETAILS_TABS[4].key &&
          (Boolean(user.vendor) ||
            ability.cannot(
              ACTIONS.VIEW,
              subject(SUBJECTS.VOTING, { ...data })
            ) ||
            !isCurrentCSCanVote)
        const isBiddingDanger =
          i.key === RFP_DETAILS_TABS[6].key && !user.vendor

        return {
          ...i,
          disabled:
            (i.key !== RFP_DETAILS_TABS[0].key && !data.uuid) ||
            isVotingDisabled,
          danger:
            isItemListDanger ||
            isVendorsDanger ||
            isHSUsersDanger ||
            isVotingDanger ||
            isBiddingDanger ||
            isResultsDisabled ||
            isVendorsUsersDanger
        }
      })
    )
  }, [
    ability,
    data,
    data.status,
    data.uuid,
    isCurrentUserStakeholder,
    setTabs,
    user,
    isCurrentUserCommonsStakeholderAndFU
  ])

  useEffect(() => {
    _setTimelineForm(timelinesResponseDataConverter(data.timelines))
  }, [data.timelines])

  useEffect(() => {
    getIsFacilityUser()
  }, [data, ability])

  const state = useMemo(
    () => ({
      ...tabsState,
      isVCS,
      data,
      timelineForm,
      timelineValidation,
      biddingData,
      isCurrentUserStakeholder,
      isVendor,
      isCanSelectUsers,
      isCommons,
      canEditRfp,
      canAddRfpUser,
      isUpdate,
      isVendorNotAnswerRfp,
      isCanManageTimelines,
      initQueryParams,
      isVendorCanUploadDocuments,
      canResendBid,
      canSeeAssignBanner,
      canCRUDCommonsResponsibles,
      canCRUDCommonsLegalAndAnalystsResponsibles,
      isCurrentUserCommonsStakeholderAndFU,
      isAllCommonsResponsiblesWasAdded,
      canManageVendors,
      canSeeBidResendReason,
      canReassignCommons
    }),
    [
      data,
      isCanSelectUsers,
      tabsState,
      isVCS,
      timelineForm,
      timelineValidation,
      biddingData,
      isCurrentUserStakeholder,
      isVendor,
      canEditRfp,
      canAddRfpUser,
      isUpdate,
      isVendorNotAnswerRfp,
      isCanManageTimelines,
      isCommons,
      initQueryParams,
      isVendorCanUploadDocuments,
      canResendBid,
      canSeeAssignBanner,
      canCRUDCommonsResponsibles,
      canCRUDCommonsLegalAndAnalystsResponsibles,
      isCurrentUserCommonsStakeholderAndFU,
      isAllCommonsResponsiblesWasAdded,
      canManageVendors,
      canSeeBidResendReason,
      canReassignCommons
    ]
  )

  const actions = useMemo(
    () => ({
      ...tabsActions,
      handleGetRfpData,
      handleCancelRFP,
      createRfpTimelinesAsync,
      handleUploadFile,
      getDocumentsByType,
      handleDeleteAttachment,
      awardVendorsAsync,
      handleUploadVendorFiles,
      handleChangeRFPStatus,
      handleGetBiddingData,
      handleDeleteVendorAttachment,
      handleChangeRFPAcceptance,
      startVendorsSelectionAsync,
      checkIsDeadlineMissed,
      resetInitQueryParams,
      setIsUpdate
    }),
    [
      tabsActions,
      handleGetRfpData,
      handleCancelRFP,
      createRfpTimelinesAsync,
      handleUploadFile,
      getDocumentsByType,
      handleDeleteAttachment,
      awardVendorsAsync,
      handleUploadVendorFiles,
      handleChangeRFPStatus,
      handleGetBiddingData,
      handleDeleteVendorAttachment,
      handleChangeRFPAcceptance,
      startVendorsSelectionAsync,
      checkIsDeadlineMissed,
      resetInitQueryParams,
      setIsUpdate
    ]
  )

  return (
    <RFPDetailsContext.Provider value={{ state, actions }}>
      {children}
    </RFPDetailsContext.Provider>
  )
}

export const useRFPDetailsContext = () => useContext(RFPDetailsContext)

export default RFPDetailsContextProvider
