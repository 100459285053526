import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { UserArchivePopup } from 'pages/CRUDUser/UserArchivePopup'
import { getHealthSystemUsers } from './api'
import { setLoading } from 'redux/store/common/slice'
import { columns } from './constants'
import { routes } from 'router/Config/config.routes'
import useRouter from 'hooks/useRouter'
import { useDebounce } from 'hooks/hooks'
import { fullFormatPhoneNumber, isTableHasParams } from 'helper/common'
import { getUser } from 'redux/store/user/getters'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import {
  THealthSystemUsersList,
  THealthSystemTableRequestData,
  THealthSystemUsersParams,
  THealthSystemUsersFilters,
  THealthSystemUsersFiltersFunc
} from './types'
import {
  Typography,
  TYPOGRAPHY_ALIGNMENT,
  TYPOGRAPHY_WEIGHT
} from 'components/Typography'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import { USER_STATUSES } from 'constants/common'
import { ReactComponent as Hospital } from '../../assets/svg/Hospital.svg'
import './styles.scss'
import { ACTIONS, SUBJECTS, Can, ROLES } from 'features/Permission'

export const HealthSystemUsers = () => {
  const [tableData, setTableData] = useState<THealthSystemUsersList[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<THealthSystemUsersList[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<THealthSystemTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [filters, setFilters] = useState<THealthSystemUsersFilters>({})
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { push } = useRouter()
  const debouncedSearch = useDebounce(searchValue, 500)
  const handleRedirect = () => {
    push(`${routes.createUserProfile}/${selectedRowKeys[0]}`)
  }

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (user.community && user.health_system) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
  }, [debouncedSearch, user.community, user.health_system])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: THealthSystemTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: THealthSystemUsersParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getHealthSystemUsers(
      user.community as string,
      user.health_system as string,
      {
        cancelToken: tableDataTokenRef.current?.token,
        params
      }
    )
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }
  const createUser = () =>
    push(`${routes.createUserProfile}/new?hsUser=${user.health_system}`, {
      state: { redirectTab: SETTINGS_TABS[3].key }
    })
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: THealthSystemUsersFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  return user.health_system ? (
    <div className="table-wrapper hs-users">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              placeholder: PLACEHOLDERS.SEARCH_USERS,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header_buttons row">
          {!selectedRowKeys.length && user.role !== ROLES.COMMUNITY_RCA && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              icon={<Plus16 />}
              onClick={createUser}
              upperCase
            >
              {BTN_TXT.CREATE_USER}
            </Button>
          )}
          {selectedRowKeys.length === 1 && (
            <Can I={ACTIONS.EDIT} a={SUBJECTS.USER}>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                onClick={handleRedirect}
                icon={<Edit16 />}
                upperCase
                className="table-wrapper__header_buttons__edit-btn"
              >
                {BTN_TXT.EDIT}
              </Button>
            </Can>
          )}
          {selectedRowKeys.length > 0 &&
            selectedRows.find((i) => i?.status !== USER_STATUSES.ARCHIVED) && (
              <>
                <UserArchivePopup<THealthSystemUsersList>
                  callback={() => {
                    setSelectedRowKeys([])
                    setSelectedRows([])
                    getTableData({ ...sortParams, ...filters })
                  }}
                  selected={selectedRows}
                  isTable
                />
              </>
            )}
        </div>
      </div>
      {!firstLoad && (
        <Table<THealthSystemUsersList>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            community: user.community as string
          })}
          className="hs-users-data-list-table"
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          hasSearchOrFilters={isTableHasParams<THealthSystemUsersFilters>(
            debouncedSearch,
            filters
          )}
          createButton={
            user.role !== ROLES.COMMUNITY_RCA
              ? {
                  buttonText: BTN_TXT.CREATE_USER,
                  availability: true,
                  action: createUser
                }
              : undefined
          }
          rowSelection={
            user.role !== ROLES.COMMUNITY_RCA
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  getCheckboxProps: (record) => ({
                    disabled: record.uuid === user.uuid
                  })
                }
              : undefined
          }
        />
      )}
    </div>
  ) : (
    <div className="hs-users">
      <div className="hs-users__list--empty">
        <Hospital />
        <Typography.Body1
          weight={TYPOGRAPHY_WEIGHT.BOLD}
          align={TYPOGRAPHY_ALIGNMENT.CENTER}
        >
          Health system is not assigned.
          <br />
          Please contact Community President in your profile
        </Typography.Body1>
      </div>
    </div>
  )
}
