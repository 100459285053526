import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TVendorUsersResponse, TVendorUsersRequest } from './types'

const fetcher = new Fetcher({})

export const getVendorUsersList: TVendorUsersRequest = async (uuid, data) =>
  fetcher.request<unknown, TVendorUsersResponse>({
    url: `${API_DICTIONARY.VENDORS}${uuid}/${API_DICTIONARY.USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
