import './styles.scss'

import { safeCurrency } from 'helper/common'
import { defaultDateFormat } from 'helper/dates'
import { BTN_TXT, LABELS } from 'constants/txt'
import { Typography } from 'components/Typography'
import { Container } from 'components/Container/Container'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import AdditionalInfoPreview from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/AdditionalInfoPreview'
import ContractDetailsDocuments from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractDetailsDocuments'
import ContractExtensionHistory from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsPreview/ContractExtensionHistory/ContractExtensionHistory'

const extensionHistory = {
  title: 'Extension history',
  width: 675
}

const ContractDetailsPreview = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state

  const expectedSpend = safeCurrency(details.expected_spend).slice(1)
  const expectedSavings = safeCurrency(details.expected_savings).slice(1)

  const hasExtensionHistory = details.contract_extension_documents.length > 0

  return (
    <div className="contract-details-preview">
      <div className="contract-details-preview__container">
        <div className="contract-details-preview__grid">
          <div className="contract-details-preview__row">
            <FieldWithLabel title={LABELS.START_DATE}>
              <Typography.Body1>
                {defaultDateFormat(details.start_date)}
              </Typography.Body1>
            </FieldWithLabel>
            <Container.WithHistory
              hasVersionHistory={hasExtensionHistory}
              versionHistory={<ContractExtensionHistory />}
              versionHistoryButtonText={BTN_TXT.SHOW_EXTENSION_HISTORY}
              popupProps={extensionHistory}
            >
              <FieldWithLabel title={LABELS.FINISH_DATE}>
                <Typography.Body1>
                  {defaultDateFormat(details.finish_date)}
                </Typography.Body1>
              </FieldWithLabel>
            </Container.WithHistory>
          </div>
          <div className="contract-details-preview__row">
            <FieldWithLabel title={LABELS.ADMIN_FEE}>
              <Typography.Body1>{details.admin_fee}</Typography.Body1>
            </FieldWithLabel>
          </div>
          <div className="contract-details-preview__row">
            <FieldWithLabel title={LABELS.EXPECTED_SPEND}>
              <Typography.Body1>{expectedSpend}</Typography.Body1>
            </FieldWithLabel>
            <FieldWithLabel title={LABELS.EXPECTED_SAVINGS}>
              <Typography.Body1>{expectedSavings}</Typography.Body1>
            </FieldWithLabel>
          </div>
        </div>
        <AdditionalInfoPreview />
      </div>
      <ContractDetailsDocuments />
    </div>
  )
}

export default ContractDetailsPreview
