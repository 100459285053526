import moment from 'moment'
import { FC, useCallback } from 'react'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { TOOLTIP } from '../../../constants'
import { ReactComponent as UnreadIndicator } from 'assets/svg/UnreadIndicator16.svg'
import { ReactComponent as ReadIndicator } from 'assets/svg/ReadIndicator16.svg'
import { NotificationCardProps } from './types'
import './styles.scss'

export const NotificationCard: FC<NotificationCardProps> = ({
  createdAt,
  isRead,
  onMarkAsRead,
  id
}) => {
  const onClick = useCallback(
    () => onMarkAsRead(id, { is_read: !isRead }),
    [id, isRead, onMarkAsRead]
  )

  return (
    <div className="notification-card column">
      <div className="notification-card__content flex space-between align-start">
        <div className="notification-card__message" />
        <div className="notification-card__info flex space-between align-center">
          <Typography.Label
            className="notification-card__created-at"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            {moment(createdAt).format('hh:mm a')}
          </Typography.Label>
          <Tooltip
            title={isRead ? TOOLTIP.MARK_AS_UNREAD : TOOLTIP.MARK_AS_READ}
            placement="topLeft"
            getPopupContainer={(node) => node}
          >
            <Button.Icon
              type={BUTTON_TYPES.GHOST}
              icon={!isRead ? <UnreadIndicator /> : <ReadIndicator />}
              onClick={onClick}
              small
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
