import Popup from '../../../../components/Popup/Popup'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../../../constants'
import { useCognusRepositoryPopup } from '../../Providers/CognusRepositoryPopupProvider'
import { BUTTON_TYPES, Button } from 'components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { Typography } from '../../../../components/Typography'
import { useState } from 'react'
import { UploadDocumentsOptions } from './contants'
import { TPropsModal } from './types'
import { AttachmentsList } from '../../../../components/AttachmentsList/AttachmentsList'
import { ReactComponent as Docs16Icon } from 'assets/svg/Docs16.svg'
import { uploadEvaluationDocuments } from '../../../ContractDetails/api'
import { setLoading } from '../../../../redux/store/common/slice'
import { notification } from '../../../../components/Notification'
import { useDispatch } from 'react-redux'
import { CheckboxValueType } from 'antd/es/checkbox/Group'

export const SupportInformationModal = ({
  documents,
  getUploaded
}: TPropsModal) => {
  const dispatch = useDispatch()
  const { uploadDocumentsPopup } = useCognusRepositoryPopup()
  const { state, actions } = uploadDocumentsPopup
  const [selected, setSelected] = useState<CheckboxValueType[]>([
    UploadDocumentsOptions[0].value
  ])

  const handleUpload = async () => {
    if (!documents?.uuid) {
      throw new Error('Document was not provided')
    }
    try {
      dispatch(setLoading(true))

      const result = await uploadEvaluationDocuments(documents?.uuid, {
        is_visible_for_community: !!selected.find((i) => i === '1'),
        is_visible_for_commons: !!selected.find((i) => i === '2')
      })
      actions.close()
      getUploaded()
      if (result?.data?.uuid) {
        notification.success({ message: VALIDATION_MESSAGES.SM0044 })
      }
    } catch (e: any) {
      notification.error({
        message: e?.data.errors ? e?.data.errors : e?.data.file.file
      })
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <Popup
      visible={state.visible}
      onCancel={actions.close}
      title={state.title}
      width={460}
      footer={
        <>
          <Button onClick={actions.close} type={BUTTON_TYPES.DEFAULT} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleUpload}
            upperCase
            disabled={!selected.length}
          >
            {BTN_TXT.UPLOAD}
          </Button>
        </>
      }
    >
      <>
        {!!documents?.uuid && (
          <div>
            <AttachmentsList
              label="Purchase_order"
              files={[documents]}
              disableActions
              withCreatedAt
              icon={<Docs16Icon />}
            />
          </div>
        )}

        <Checkbox.Group
          className="support-information-options mt-16"
          propsGroupCheckbox={{
            value: selected,
            onChange: setSelected,
            options: UploadDocumentsOptions.map((option) => ({
              value: option.value,
              label: <Typography.Body1>{option.label}</Typography.Body1>
            }))
          }}
        />
      </>
    </Popup>
  )
}
