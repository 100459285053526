import cn from 'classnames'
import { Popover } from 'antd'
import { Typography } from 'components/Typography'
import { ReactComponent as ArrowDown16 } from 'assets/svg/ArrowDown16.svg'
import { TButtonDefault as Props } from './types'
import './button.menu.scss'
import Button from './Button'
import { useState } from 'react'

const Menu = ({
  children,
  className,
  secondary,
  upperCase,
  small,
  isBody,
  options,
  onClick,
  ...props
}: Props): JSX.Element => {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (value) => {
    setVisible(value)
  }
  const content = (
    <div className="button-menu__options">
      {options?.map((item) => (
        <div
          key={item.text}
          className="button-menu__option"
          onClick={() => {
            item.onClick()
            setVisible(false)
          }}
        >
          <Typography.Body1>{item.text}</Typography.Body1>
        </div>
      ))}
    </div>
  )
  return (
    <Popover
      placement="bottomRight"
      overlayClassName="button-menu__popover"
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button
        className={cn('button-menu', className, {
          secondary,
          upperCase,
          small
        })}
        {...props}
      >
        {children}
        {options && (
          <div className="button-menu__arrow">
            <ArrowDown16 />
          </div>
        )}
      </Button>
    </Popover>
  )
}

export default Menu
