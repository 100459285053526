import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import Typography from '../../../../components/Typography/Typography'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { Container } from '../../../../components/Container/Container'
import { VendorUsers } from '../VendorUsers/VendorUsers'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { getVendorDetailsRequestAsync } from '../../../VendorDetails/api'
import { getVendorInfo } from '../../../VendorInfo/api'
import { getUser } from '../../../../redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { formatAddress, fullFormatPhoneNumber } from '../../../../helper/common'
import { VENDOR_ACCESS_OPTIONS } from '../VendorsList/constants'
import { ROLES_NAMES } from '../../../../constants'
import { BREADCRUMBS } from './constants'
import { TAddress } from '../../../../constants'
import { VendorData } from './types'
import './styles.scss'

export const VendorDetails = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [data, setData] = useState<VendorData>()
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    getDetails()
  }, [])

  const getDetails = async () => {
    try {
      dispatch(setLoading(true))
      if (user.vendor) {
        const { data } = await getVendorInfo(user.vendor)
        setData(data)
      } else {
        const { data } = await getVendorDetailsRequestAsync(id as string)
        setData(data)
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChangeCollapse = () => setIsCollapsed((prev) => !prev)

  return (
    <Container
      className={cn('vendor-info-container', {
        'vendor-info-container--no-padding': user.vendor
      })}
      title={!user.vendor && data?.name}
      breadcrumbs={!user.vendor ? BREADCRUMBS(data?.name || '') : undefined}
    >
      <div className="vendor-info">
        <div className="vendor-info__general">
          <div className="vendor-info__header row align-center">
            <Typography.Headline6>
              {user.vendor ? data?.name : 'General'}
            </Typography.Headline6>
          </div>
          {!isCollapsed && (
            <div className="vendor-info__general-content column">
              <div className="vendor-info__grid">
                <FieldWithLabel title="Vendor permission">
                  {
                    VENDOR_ACCESS_OPTIONS.find(
                      (i) => i.value === data?.vendor_permission
                    )?.label
                  }
                </FieldWithLabel>
                <FieldWithLabel title="Vendor’s phone number">
                  {fullFormatPhoneNumber(data?.phone_number)}
                </FieldWithLabel>
                <FieldWithLabel title="Address">
                  {formatAddress(data?.address as TAddress)}
                </FieldWithLabel>
                <FieldWithLabel title="Description">
                  {data?.description}
                </FieldWithLabel>
                <IconFullName
                  className="community-info__contact-person column"
                  firstName={data?.contract_admin?.first_name}
                  lastName={data?.contract_admin?.last_name}
                  roleName={
                    data?.contract_admin?.role
                      ? ROLES_NAMES[data?.contract_admin?.role]
                      : ''
                  }
                  src={data?.contract_admin?.avatar}
                  uuid={data?.contract_admin?.uuid}
                />
              </div>
            </div>
          )}
          <Button
            className="hs-info__show-btn"
            type={BUTTON_TYPES.LINK}
            onClick={onChangeCollapse}
            inline
          >
            {`${isCollapsed ? 'Show more' : 'Hide'} information`}
          </Button>
        </div>
        <VendorUsers />
      </div>
    </Container>
  )
}
