import { useEffect } from 'react'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

import './styles.scss'
import { ResponsibleForRFP } from 'features/RFP/RFPDetails/ResponsibleForRFP'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { FilesUpload } from '../RFPDetails/FilesUpload'

export const RFPBidding = () => {
  const rfpDetailsContext = useRFPDetailsContext()

  const { data, biddingData, isVendor, isVendorCanUploadDocuments } =
    rfpDetailsContext.state

  const { handleGetBiddingData } = rfpDetailsContext.actions

  useEffect(() => {
    if (data.uuid) {
      handleGetBiddingData()
    }
  }, [data.uuid, handleGetBiddingData])

  return (
    <div className="rfp-bidding">
      <div className="rfp-bidding__left">
        <div className="rfp-bidding__files">
          <FilesUpload
            isVendor={true}
            isViewMode={!isVendorCanUploadDocuments}
            communityAttachments={data.default_documents.filter(
              (i) => i.document_type === 'attachments'
            )}
            data={{
              default_documents: biddingData.documents,
              autogenerated_default_documents:
                data.autogenerated_default_documents
            }}
          />
        </div>
      </div>
      <div className="rfp-details-general__right">
        {isVendor && (
          <FieldWithLabel
            className="mt-24 rfp-bidding__label"
            title="Community contacts"
          />
        )}
        <ResponsibleForRFP />
      </div>
    </div>
  )
}
