import { useEffect, useRef, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { Input } from '../../../../components/Input'
import { Menu } from '../../../../components/Menu/Menu'
import { UserArchivePopup } from '../../../../pages/CRUDUser/UserArchivePopup'
import { Table } from '../../../../components/Table'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'
import useRouter from '../../../../hooks/useRouter'
import { useDebounce } from '../../../../hooks/hooks'
import { setLoading } from '../../../../redux/store/common/slice'
import { getVendorUsersList } from '../../../VendorUsers/api'
import {
  fullFormatPhoneNumber,
  isTableHasParams
} from '../../../../helper/common'
import { routes } from '../../../../router'
import { SETTINGS_TABS } from '../../../../pages/Settings/constants'
import { BTN_TXT, PLACEHOLDERS, USER_STATUSES } from '../../../../constants'
import { ability, ACTIONS, Can, SUBJECTS } from '../../../Permission'
import { DEFAULT_PAGE } from '../../../../components/Table/constants'
import { columns, VENDOR_USERS_TABS } from './constants'
import {
  TVendorUser,
  TVendorUsersFilters,
  TVendorUsersFiltersFunc,
  TVendorUsersParams,
  TVendorUsersRequestData
} from '../../../VendorUsers/types'
import './styles.scss'

export const VendorUsers = () => {
  const [activeTab, setActiveTab] = useState(VENDOR_USERS_TABS[0].key)
  const [tableData, setTableData] = useState<TVendorUser[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<TVendorUser[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TVendorUsersRequestData>()
  const [filters, setFilters] = useState<TVendorUsersFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { push } = useRouter()
  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (!firstLoad) {
      getTableData({ ...sortParams, ...filters })
    }
  }, [activeTab])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (user.vendor) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
  }, [debouncedSearch, user.vendor])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TVendorUsersRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TVendorUsersParams = { ...filters, roles: [activeTab] }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getVendorUsersList(user.vendor as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const createUser = () =>
    push(`${routes.createUserProfile}/new`, {
      state: { redirectTab: SETTINGS_TABS[6].key }
    })

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TVendorUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  return (
    <div className="vendor-users-list mt-32">
      <div className="vendor-users-list__header row align-center space-between">
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Users
        </Typography.Headline6>
        <div className="vendor-users-list__actions row align-center">
          {!!selectedRowKeys.length ? (
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
          ) : (
            <Input
              className="vendor-users-list__search"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH_USERS,
                prefix: <Search16 />,
                value: searchValue,
                onChange: (e) => setSearchValue(e.target.value)
              }}
            />
          )}
          <Can I={ACTIONS.CRUD} a={SUBJECTS.VENDOR_USERS}>
            {!selectedRowKeys.length && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={createUser}
                upperCase
              >
                {BTN_TXT.CREATE_USER}
              </Button>
            )}
            {!!selectedRows.length &&
              selectedRows.filter((i) => i.status !== USER_STATUSES.ARCHIVED)
                .length === selectedRows.length && (
                <UserArchivePopup<TVendorUser>
                  selected={selectedRows}
                  callback={() => {
                    setSelectedRowKeys([])
                    setSelectedRows([])
                    getTableData({ ...sortParams, ...filters })
                  }}
                  isTable
                />
              )}
          </Can>
        </div>
      </div>
      <Menu
        mode="horizontal"
        className="vendor-users-list__users-tabs mt-12"
        onSelect={(e) => setActiveTab(e.key)}
        selectedKeys={[activeTab]}
        defaultSelectedKeys={[activeTab]}
        items={VENDOR_USERS_TABS}
      />
      {!firstLoad && (
        <Table<TVendorUser>
          dataSource={tableData}
          className="rfp-users-data-list-table"
          columns={columns({
            applyFilters,
            filters,
            vendorId: user.vendor as string
          })}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          onChangePage={handleChangePageSize}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TVendorUsersFilters>(
            debouncedSearch,
            filters
          )}
          createButton={
            ability.can(ACTIONS.CRUD, SUBJECTS.VENDOR_USERS)
              ? {
                  buttonText: BTN_TXT.CREATE_USER,
                  availability: true,
                  action: createUser
                }
              : undefined
          }
          rowSelection={
            ability.can(ACTIONS.CRUD, SUBJECTS.VENDOR_USERS)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  getCheckboxProps: (record) => ({
                    disabled: record.uuid === user.uuid
                  })
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
