import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Navigate, Routes } from 'react-router-dom'

import SignUp from 'pages/SignUp/SignUp'
import SignIn from 'pages/SignIn/SignIn'
import EmailConfirmation from 'features/EmailConfirmation/EmailConfirmation'
import Portal from 'components/Portal/Portal'
import Settings from 'pages/Settings/Settings'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
// import Storybook from 'pages/Storybook'
import { EmptyState } from 'pages/EmptyState/EmptyState'
import { CRUDUser } from 'pages/CRUDUser/CRUDUser'
import { RFPList } from 'features/RFP/RFPList/RFPList'
import { RFPDetails } from 'features/RFP/RFPDetails'
import { routes } from './Config/config.routes'
import { Contacts } from 'pages/Contracts/Contracts'
import { Calendar } from 'pages/Calendar/Calendar'
import { ContractDetails } from 'features/ContractDetails'
import { Vendors } from 'pages/Vendors/Vendors'
import { VendorDetails } from 'features/VendorDetails'
import { VendorDetails as CommunityVendorDetails } from 'features/MyCommunity/Vendors/VendorDetails/VendorDetails'
import { Dashboard } from 'pages/Dashboard/Dashboard'
import { Financials } from 'pages/Financials/Financials'
import { Compliance } from 'pages/Compliance/Compliance'
import { getUser } from 'redux/store/user/getters'
import Chats from 'pages/Chats/Chats'
import { ROLES } from 'features/Permission'
import { PurchaseOrders } from '../pages/PurchaseOrders/PurchaseOrdersProvider'
// import { EMPTY_STATES_TYPES } from 'constants/common'
import FANumber from '../pages/FANumber/FANumber'
import FACode from '../pages/FACode/FACode'
import TermsOfUsePage from '../pages/TermsOfUse/TermsOfUse'
import PrivacyPolicyPage from '../pages/PrivacyPolicy/PrivacyPolicy'
import LandingPage from '../pages/Landing/Landing'
import useRouter from 'hooks/useRouter'
import { ProductRequest } from '../pages/ProductRequests/ProductRequest'
import { PRSDetails } from '../features/PRS/PRSDetails'
import { MyCommunity } from '../pages/MyCommunity/MyCommunity'
import { getLocalLastPage, setLocalLastPage } from '../helper/common'
import { getVendorInfo } from 'redux/store/vendorInfo/getters'
import { ClinicalReviewPage } from '../pages/ClinicalReview/ClinicalReviewPage'
import { RequestForAnalysisContainer } from '../pages/RequestForAnalysisDetaisPage/RequestForAnalysisContainer'
import ContractPipelineDetails from 'features/ContractsPipelineDetails/ContractPipelineDetails'
import { CheckIn } from '../pages/CheckIns/CheckIn'

export default (): JSX.Element => {
  const user = useSelector(getUser)
  const { pathname } = useRouter()
  // const isLanding = false
  const StakeholderAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.clinicalReview} element={<ClinicalReviewPage />} />
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.vendors} element={<EmptyState />} />
        <Route path={routes.vendors}>
          <Route index element={<Vendors />} />
          <Route path=":id" element={<VendorDetails />} />
        </Route>
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>

        <Route path="*" element={<Navigate to={routes.bidding} replace />} />
      </>
    ),
    []
  )

  const vendor = useSelector(getVendorInfo)
  const VendorCSAppRoutes = useMemo(
    () => (
      <>
        {vendor?.vendor_permission === 'full_access' && (
          <Route path={routes.bidding}>
            <Route index element={<RFPList />} />
            <Route path="rfp/:id" element={<RFPDetails />}>
              <Route path=":tab" element={<RFPDetails />} />
            </Route>
          </Route>
        )}
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.chat} element={<EmptyState />} />
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        {vendor?.vendor_permission === 'full_access' && (
          <Route path={routes.contracts}>
            <Route index element={<Contacts />} />
            <Route path=":id" element={<ContractDetails />}>
              <Route path=":tab" element={<ContractDetails />} />
            </Route>
          </Route>
        )}
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
        <Route path={routes.checkIns} element={<CheckIn />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>
        {vendor?.vendor_permission === 'full_access' ? (
          <Route path="*" element={<Navigate to={routes.bidding} replace />} />
        ) : (
          <Route
            path="*"
            element={<Navigate to={routes.productRequests} replace />}
          />
        )}
      </>
    ),
    [vendor]
  )
  const VendorCAAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.chat} element={<EmptyState />} />
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.contracts}>
          <Route index element={<Contacts />} />
          <Route path=":id" element={<ContractDetails />}>
            <Route path=":tab" element={<ContractDetails />} />
          </Route>
        </Route>
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const DefaultAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.dashboard} element={<Dashboard />} />
        <Route path={routes.financials} element={<Financials />} />
        <Route path={routes.compliance} element={<Compliance />} />
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route
          path={routes.contracts + routes.cognusRepository + '/:id'}
          element={<RequestForAnalysisContainer />}
        />
        <Route
          path={routes.contracts + '/:type/:id'}
          element={<ContractDetails />}
        >
          <Route path=":tab" element={<ContractDetails />} />
        </Route>
        <Route
          path={routes.contracts + routes.contractPipeline + '/:id'}
          element={<ContractPipelineDetails />}
        >
          <Route path=":tab" element={<ContractPipelineDetails />} />
        </Route>

        <Route path={routes.contracts} element={<Contacts />}>
          <Route path=":type" element={<Contacts />} />
        </Route>
        <Route path={routes.vendors} element={<EmptyState />} />
        <Route path={routes.purchaseOrders} element={<PurchaseOrders />} />
        <Route path={routes.vendors}>
          <Route index element={<Vendors />} />
          <Route path=":id" element={<VendorDetails />} />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>
        <Route path="*" element={<Navigate to={routes.dashboard} replace />} />
      </>
    ),
    []
  )
  const CommunityPresident = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.dashboard} element={<Dashboard />} />
        <Route path={routes.financials} element={<Financials />} />
        <Route path={routes.compliance} element={<Compliance />} />
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.contracts}>
          <Route index element={<Contacts />} />
          <Route path=":id" element={<ContractDetails />}>
            <Route path=":tab" element={<ContractDetails />} />
          </Route>
        </Route>
        <Route path={routes.vendors} element={<EmptyState />} />
        <Route path={routes.purchaseOrders} element={<PurchaseOrders />} />
        <Route path={routes.vendors}>
          <Route index element={<Vendors />} />
          <Route path=":id" element={<VendorDetails />} />
        </Route>
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>
        <Route path="*" element={<Navigate to={routes.dashboard} replace />} />
      </>
    ),
    []
  )

  const VendorFinanceOfficerAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.financials} element={<Financials />} />
        <Route path={routes.contracts}>
          <Route index element={<Contacts />} />
          <Route path=":id" element={<ContractDetails />}>
            <Route path=":tab" element={<ContractDetails />} />
          </Route>
        </Route>
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.financials} replace />} />
      </>
    ),
    []
  )
  const CommonsLegalAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.contracts}>
          <Route index element={<Contacts />} />
          <Route path=":id" element={<ContractDetails />}>
            <Route path=":tab" element={<ContractDetails />} />
          </Route>
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
      </>
    ),
    []
  )

  const CommonsAnalystAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route
          path={routes.contracts + routes.opportunityAnalysis + '/:id'}
          element={<RequestForAnalysisContainer />}
        />
        <Route
          path={routes.contracts + '/:type/:id'}
          element={<ContractDetails />}
        >
          <Route path=":tab" element={<ContractDetails />} />
        </Route>

        <Route path={routes.contracts} element={<Contacts />}>
          <Route path=":type" element={<Contacts />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )
  const CommonsStAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>
        <Route path="*" element={<Navigate to={routes.settings} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )
  const CommonsVpAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={routes.contracts + routes.opportunityAnalysis + '/:id'}
          element={<RequestForAnalysisContainer />}
        />
        <Route
          path={routes.contracts + '/:type/:id'}
          element={<ContractDetails />}
        >
          <Route path=":tab" element={<ContractDetails />} />
        </Route>
        <Route
          path={routes.contracts + routes.contractPipeline + '/:id'}
          element={<ContractPipelineDetails />}
        >
          <Route path=":tab" element={<ContractPipelineDetails />} />
        </Route>
        <Route path={routes.contracts} element={<Contacts />}>
          <Route path=":type" element={<Contacts />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )
  const CommonsCCAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route
          path={routes.contracts + '/:type/:id'}
          element={<ContractDetails />}
        >
          <Route path=":tab" element={<ContractDetails />} />
        </Route>
        <Route
          path={routes.contracts + routes.opportunityAnalysis + '/:id'}
          element={<RequestForAnalysisContainer />}
        />
        <Route path={routes.contracts} element={<Contacts />}>
          <Route path=":type" element={<Contacts />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )
  const CommonsCSAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route
          path={routes.contracts + routes.opportunityAnalysis + '/:id'}
          element={<RequestForAnalysisContainer />}
        />
        <Route
          path={routes.contracts + '/:type/:id'}
          element={<ContractDetails />}
        >
          <Route path=":tab" element={<ContractDetails />} />
        </Route>
        <Route
          path={routes.contracts + routes.contractPipeline + '/:id'}
          element={<ContractPipelineDetails />}
        >
          <Route path=":tab" element={<ContractPipelineDetails />} />
        </Route>

        <Route path={routes.contracts} element={<Contacts />}>
          <Route path=":type" element={<Contacts />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const RevenueCycleAdvisorAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.settings} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const AnalistAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.compliance} element={<Compliance />} />
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.financials} element={<Financials />} />
        <Route
          path={routes.contracts + routes.cognusRepository + '/:id'}
          element={<RequestForAnalysisContainer />}
        />
        <Route
          path={routes.contracts + '/:type/:id'}
          element={<ContractDetails />}
        >
          <Route path=":tab" element={<ContractDetails />} />
        </Route>

        <Route path={routes.contracts} element={<Contacts />}>
          <Route path=":type" element={<Contacts />} />
        </Route>
        {/*<Route path={routes.contracts}>*/}
        {/*  <Route index element={<Contacts />} />*/}
        {/*  <Route path=":tab" element={<Contacts />}>*/}
        {/*    <Route path=":id" element={<ContractDetails />}>*/}
        {/*      <Route path=":tab" element={<ContractDetails />} />*/}
        {/*    </Route>*/}
        {/*  </Route>*/}
        {/*</Route>*/}
        <Route path={routes.purchaseOrders} element={<PurchaseOrders />} />
        <Route path={routes.vendors}>
          <Route index element={<Vendors />} />
          <Route path=":id" element={<VendorDetails />} />
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.compliance} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const VLedalVAnalistLegalAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.contracts}>
          <Route index element={<Contacts />} />
          <Route path=":id" element={<ContractDetails />}>
            <Route path=":tab" element={<ContractDetails />} />
          </Route>
        </Route>
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.financials} element={<Financials />} />
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.contracts} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const HAInfluencerAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.bidding}>
          <Route index element={<RFPList />} />
          <Route path="rfp/:id" element={<RFPDetails />}>
            <Route path=":tab" element={<RFPDetails />} />
          </Route>
        </Route>
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.productRequests}>
          <Route index element={<ProductRequest />} />
          <Route
            path={`${routes.productRequests}/:id`}
            element={<PRSDetails />}
          >
            <Route path=":tab" element={<PRSDetails />} />
          </Route>
        </Route>
        <Route path={routes.financials} element={<Financials />} />
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path={routes.checkIns}>
          <Route index element={<CheckIn />} />
          <Route path={`${routes.checkIns}/:id`} element={<CheckIn />} />
        </Route>
        <Route path="*" element={<Navigate to={routes.bidding} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const SalesRepresentativeAppRoutes = useMemo(
    () => (
      <>
        <Route path={routes.settings} element={<Settings />}>
          <Route path=":tab" element={<Settings />} />
        </Route>
        <Route
          path={`${routes.createUserProfile}/:id`}
          element={<CRUDUser />}
        />
        <Route path={routes.chat}>
          <Route index element={<Chats />} />
          <Route path={`${routes.chat}/:id`} element={<Chats />} />
        </Route>
        <Route path={routes.financials} element={<Financials />} />
        <Route path={routes.calendar} element={<Calendar />} />
        <Route path="*" element={<Navigate to={routes.chat} replace />} />
        <Route path={routes.myCommunity}>
          <Route index element={<MyCommunity />} />
          <Route
            path="vendor-details/:id"
            element={<CommunityVendorDetails />}
          />
        </Route>
      </>
    ),
    []
  )

  const AppRoutes = useMemo(() => {
    switch (user.role) {
      case ROLES.STAKEHOLDER:
        return StakeholderAppRoutes
      case ROLES.VENDOR_CONTRACT_STEWARD:
        return VendorCSAppRoutes
      case ROLES.VENDOR_CONTRACT_ADMIN:
        return VendorCAAppRoutes
      case ROLES.HOSPITAL_ADMIN:
      case ROLES.INFLUENCER:
        return HAInfluencerAppRoutes
      case ROLES.SALES_REPRESENTATIVE:
        return SalesRepresentativeAppRoutes
      case ROLES.VENDOR_ANALYST:
      case ROLES.VENDOR_LEGAL:
      case ROLES.LEGAL:
        return VLedalVAnalistLegalAppRoutes
      case ROLES.VENDOR_FINANCE_OFFICER:
        return VendorFinanceOfficerAppRoutes
      case ROLES.COMMUNITY_RCA:
        return RevenueCycleAdvisorAppRoutes
      case ROLES.ANALYST:
        return AnalistAppRoutes
      case ROLES.COMMONS_LEGAL:
        return CommonsLegalAppRoutes
      case ROLES.COMMONS_ANALYST:
        return CommonsAnalystAppRoutes
      case ROLES.COMMONS_STAKEHOLDER:
        return CommonsStAppRoutes
      case ROLES.COMMONS_VICE_PRESIDENT:
        return CommonsVpAppRoutes
      case ROLES.COMMONS_CLINICAL_COORDINATOR:
        return CommonsCCAppRoutes
      case ROLES.COMMONS_CONTRACT_STEWARD:
        return CommonsCSAppRoutes
      case ROLES.COMMUNITY_PRESIDENT:
        return CommunityPresident
      default:
        return DefaultAppRoutes
    }
  }, [
    DefaultAppRoutes,
    StakeholderAppRoutes,
    VendorCAAppRoutes,
    VendorCSAppRoutes,
    HAInfluencerAppRoutes,
    SalesRepresentativeAppRoutes,
    VLedalVAnalistLegalAppRoutes,
    AnalistAppRoutes,
    VendorFinanceOfficerAppRoutes,
    user.role,
    vendor,
    CommunityPresident
  ])

  const LoginRoutes = useMemo(
    () => (
      <>
        <Route path={routes.login} element={<SignIn />} />
        <Route path={routes.confirmEmail} element={<EmailConfirmation />} />
        <Route path={routes.resetPassword} element={<EmailConfirmation />} />
        <Route path={routes.setPassword} element={<SignUp />} />
        <Route path={routes.faNumber} element={<FANumber />} />
        <Route path={routes.faCode} element={<FACode />} />
        <Route path={routes.termsOfUse} element={<TermsOfUsePage />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
        <Route path={routes.landing} element={<LandingPage />} />
        <Route path="*" element={<Navigate to={routes.landing} replace />} />
      </>
    ),
    []
  )
  const { push } = useRouter()

  useEffect(() => {
    if (user.uuid) {
      const page = getLocalLastPage()
      if (page) {
        push(page)
        setLocalLastPage('')
      }
    }
  }, [user.uuid])

  return (
    <>
      {!user.uuid && pathname === routes.landing && (
        <Routes>
          <Route path={routes.landing} element={<LandingPage />} />
          <Route path="*" element={<Navigate to={routes.landing} replace />} />
        </Routes>
      )}
      {pathname !== routes.landing && (
        <Portal>
          <Routes>
            {user.uuid ? AppRoutes : LoginRoutes}
            {/*<Route path={routes.financials} element={<EmptyState />} />*/}
            <Route path={routes.financials} element={<Financials />} />
            <Route path={routes.notFound} element={<PageNotFound />} />
            {/*<Route path={routes.storybook} element={<Storybook />} />*/}
            <Route
              path="*"
              element={<Navigate to={routes.notFound} replace />}
            />
          </Routes>
        </Portal>
      )}
    </>
  )
}
