import { Avatar } from 'antd'
import cn from 'classnames'
import { Typography } from 'components/Typography'
import { TIconNameItem } from './types'
import './styles.scss'
import { StatusIcon } from './StatusIcon'
import { Chips } from '../Chips/Chips'
import { formatFirstLastName } from '../../helper/common'
import Highlighter from 'react-highlight-words'

const IconNameItem = ({ ...props }: TIconNameItem) => {
  const {
    firstName,
    lastName,
    className,
    src,
    roleName,
    status,
    highlightedText = '',
    tagText,
    email
  } = props
  const name = formatFirstLastName({ firstName, lastName })
  return (
    <div className={cn('icon-name', className)}>
      <Avatar.Group>
        <StatusIcon status={status}>
          <Avatar src={src} gap={1} alt="user image">
            {name}
          </Avatar>
        </StatusIcon>
      </Avatar.Group>
      <div className="name-container">
        <div className="name-container__row row align-center">
          <Typography.Body1 weight="semi-bold">
            <Highlighter
              highlightTag="span"
              highlightClassName="name-container__row_highlighted p-0"
              searchWords={highlightedText?.split(' ') || ''}
              autoEscape
              textToHighlight={`${firstName} ${lastName}`}
            />
          </Typography.Body1>
          {tagText ? (
            <Chips closable={false} grey>
              {tagText}
            </Chips>
          ) : null}
        </div>
        {email && <Typography.Label>{email}</Typography.Label>}
        <Typography.Caption>{roleName}</Typography.Caption>
      </div>
    </div>
  )
}

// [NOTE]: if it's needed export other types of component like Component.Smth here

export default IconNameItem
