import Default from './popup.default'
import { ModalProps } from './types'
import { Discard } from './popup.discard'
import Terms from './popup.terms'

const Popup = (props: ModalProps) => <Default {...props} />

Popup.Default = Default
Popup.Discard = Discard
Popup.Terms = Terms

export default Popup
