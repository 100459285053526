import { useState } from 'react'
import { Container } from '../../components/Container/Container'
import { Menu } from '../../components/Menu/Menu'
import { HealthSystems } from './HealthSystems/HealthSystems'
import { Vendors } from './Vendors/Vendors'
import { MY_COMMUNITY_TABS } from './constants'

export const MyCommunity = () => {
  const [activeTab, setActiveTab] = useState(MY_COMMUNITY_TABS[0].key)

  return (
    <Container
      title="My Community"
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => setActiveTab(e.key)}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={MY_COMMUNITY_TABS}
        />
      }
    >
      {activeTab === MY_COMMUNITY_TABS[0].key && <HealthSystems />}
      {activeTab === MY_COMMUNITY_TABS[1].key && <Vendors />}
    </Container>
  )
}
