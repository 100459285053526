import { useMemo } from 'react'
import cn from 'classnames'

import './index.scss'

import { routes } from 'router'
import { TChatDetails } from './types'
import { CHAT_TYPE } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Members } from './Members/Members/Members'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import { ACTIONS, chatSubject, SUBJECTS } from 'features/Chat/Abilities'
import { safeString } from 'helper/common'
import useRouter from 'hooks/useRouter'

const ChatDetails = ({
  name,
  src,
  children,
  chatName,
  isLink = false,
  linkId = '',
  hideName
}: TChatDetails): JSX.Element => {
  const { push } = useRouter()

  const chatContext = useChatContext()

  const chatType = useMemo(
    () => chatContext.state.chat?.type,
    [chatContext.state.chat?.type]
  )

  const isChannel = chatType === CHAT_TYPE.CHANNEL

  const chatRedirect = () => {
    if (isLink) {
      push(`${routes.bidding}${routes.rfp}/${linkId}/`)
    }
  }

  return (
    <div className="chat-details-container">
      <Avatar
        className="user-form_avatar-icon"
        name={name}
        alt="User avatar"
        src={src}
        isChannel={isChannel}
      />
      <Typography.Headline5
        weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        className={cn('chat-details-container__chat-name', {
          link: isLink,
          hideName
        })}
        onClick={chatRedirect}
      >
        {safeString(chatName)}
      </Typography.Headline5>
      {children}
      <Can
        I={ACTIONS.VIEW}
        a={chatSubject(SUBJECTS.CHAT_MEMBERS, chatContext.state.chat)}
      >
        <Members />
      </Can>
    </div>
  )
}

export default ChatDetails
