import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import useDrawer, { DrawerReturnTypeWithPayload } from 'hooks/useDrawer'
import { TChatTypeUnion } from 'constants/types'

type ContextProps = {
  sidebarDrawer: DrawerReturnTypeWithPayload<{
    type: TChatTypeUnion
  }>
}

const basePopup = (title: string) => ({
  title
})

const ChatPopupContext = createContext<ContextProps>({
  sidebarDrawer: null!
})

const ChatPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const sidebarDrawerProps = useDrawer<{
    type: TChatTypeUnion
  }>(basePopup('Chat details'))

  const context = useMemo(
    () => ({
      sidebarDrawer: sidebarDrawerProps
    }),
    [sidebarDrawerProps]
  )

  return (
    <ChatPopupContext.Provider value={context}>
      {children}
    </ChatPopupContext.Provider>
  )
}

export const useChatPopup = () => useContext(ChatPopupContext)

export default ChatPopupProvider
