import { Modal } from 'antd'
import cn from 'classnames'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import { ModalProps } from './types'
import './popup.default.scss'

const Default = ({
  children,
  className,
  hasArchive,
  wrapClassName,
  footer,
  closable = true,
  ...props
}: ModalProps): JSX.Element => {
  return (
    <Modal
      closeIcon={<Close24 />}
      closable={closable}
      getContainer="#root"
      className={cn('popup-default', className, {
        'popup-default--closable': closable,
        'popup-default--has-archive-btn': hasArchive,
        'popup-default--with-footer': !!footer
      })}
      wrapClassName={cn('popup-default-wrap', wrapClassName)}
      footer={footer}
      destroyOnClose
      centered
      {...props}
      maskTransitionName="modal-mask-auth-pages"
    >
      {children}
    </Modal>
  )
}

export default Default
