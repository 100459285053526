import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { CONTARCT_STATUS_TYPES_TO_CLASSNAME, Status } from 'components/Status'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { CONTRACT_STATUSES_OPTIONS } from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import { Datepicker } from 'components/Datepicker'
import {
  TContractsList,
  TContractsListColumnsFunc,
  TContractsListFilters,
  TContractsListFiltersFunc
} from './types'
import { CONTRACT_STATUS_NAME } from 'features/ContractDetails/constants'
import moment from 'moment'
import { timelineDateFormat } from 'components/Timelines/constants'
import { REBATE_CONDITIONS_TITLE } from '../ContractDetails/RebateConditions/RebateConditionsForm/constants'
import {
  getCommunitiesRequestAsync,
  getVendorsRequestAsync
} from 'features/ContractDetails/api'

export const columns = ({
  applyFilters,
  filters,
  isVendor,
  pathname
}: TContractsListColumnsFunc): ColumnsType<TContractsList> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Contract name
      </Sorter>
    ),
    render: (_, { name, uuid }) => (
      <CellWithSubtitle
        title={<Link to={`${pathname}/${uuid}`}>{name}</Link>}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: isVendor ? 'community_name' : 'vendor_name',
    key: isVendor ? 'community_name' : 'vendor_name',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex={isVendor ? 'community_name' : 'vendor_name'}
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        {isVendor ? 'Community' : 'Vendor'}
      </Sorter>
    ),
    filterDropdown: (props) =>
      isVendor ? (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getCommunitiesRequestAsync}
          field={'communities'}
          asyncSearch
          {...props}
        />
      ) : (
        <Dropdown.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={getVendorsRequestAsync}
          field={'vendors'}
          asyncSearch
          {...props}
        />
      ),
    filterIcon:
      !!filters?.communities?.length || !!filters?.vendors?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_category',
    key: 'contract_category',
    title: 'Contract category',
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TContractsListFilters,
        TContractsListFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_category_name }) => (
      <CellWithSubtitle title={contract_category_name} />
    ),
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TContractsListFilters,
        TContractsListFiltersFunc,
        unknown
      >
        items={CONTRACT_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={CONTARCT_STATUS_TYPES_TO_CLASSNAME[status]}>
        {CONTRACT_STATUS_NAME[status]}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'rebate_type',
    key: 'rebate_type',
    width: 120,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex="rebate_type"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Rebate type
      </Sorter>
    ),
    render: (_, { rebate_type }) => (
      <CellWithSubtitle
        title={rebate_type ? REBATE_CONDITIONS_TITLE[rebate_type] : '-'}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'start_date',
    key: 'start_date',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex="start_date"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Start date
      </Sorter>
    ),
    render: (_, { start_date }) =>
      start_date ? moment(start_date).format(timelineDateFormat) : '-',
    filterDropdown: (props) => {
      return (
        <div className="filter-dropdown-table-range">
          <Datepicker.Filter<
            TContractsListFilters,
            TContractsListFiltersFunc,
            unknown
          >
            applyFilters={applyFilters}
            appliedFilters={filters}
            field="start_date_range"
            {...props}
            prefixCls="range-picker-custom ant-picker"
            open={props.visible}
            rangeClassName="filter-dropdown-table-range-picker"
            placement="topLeft"
          />
        </div>
      )
    },
    filterIcon: !!filters?.start_date_range?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'finish_date',
    key: 'finish_date',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex="finish_date"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Finish date
      </Sorter>
    ),
    render: (_, { finish_date }) =>
      finish_date ? moment(finish_date).format(timelineDateFormat) : '-',
    filterDropdown: (props) => (
      <div className="filter-dropdown-table-range">
        <Datepicker.Filter<
          TContractsListFilters,
          TContractsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="finish_date_range"
          {...props}
          prefixCls="range-picker-custom ant-picker"
          open={props.visible}
          rangeClassName="filter-dropdown-table-range-picker"
          placement="topLeft"
        />
      </div>
    ),
    filterIcon: !!filters?.finish_date_range?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'admin_fee',
    key: 'admin_fee',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractsList>
        dataIndex="admin_fee"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Admin fee, %
      </Sorter>
    ),
    render: (_, { admin_fee }) => `${admin_fee}`,
    sorter: true,
    showSorterTooltip: false
  }
]

export const CONTRACT_TYPE_OPTIONS = [
  {
    label: 'Community Contract',
    value: 'community'
  },
  {
    label: 'Health System Contract',
    value: 'health_system'
  }
]
