import { combineReducers } from '@reduxjs/toolkit'

import { moduleName as templateModuleName } from './template/constants'
import { moduleName as setPasswordModuleName } from './setPassword/constants'
import { moduleName as templateDetailsModuleName } from './templateDetails/constants'
import { moduleName as commonModuleName } from './common/constants'
import { moduleName as loginModuleName } from './login/constants'
import { moduleName as userModuleName } from './user/constants'
import { moduleName as communityInfoModuleName } from './chapterInfo/constants'
import { moduleName as healthSystemsModuleName } from './healthSystemsList/constants'
import { moduleName as vendorInfosModuleName } from './vendorInfo/constants'
import { moduleName as chatsModuleName } from './chats/constants'
import { moduleName as chatDetailsModuleName } from './chatDetails/constants'
import { moduleName as chatHistoryModuleName } from './chatHistory/constants'
import { moduleName as notificationsModuleName } from './notifications/constants'
import { moduleName as checkInsModuleName } from './checkIns/constants'

import templateReducer from './template/slice'
import templateDetailsReducer from './templateDetails/slice'
import commonReducer from './common/slice'
import setPasswordReducer from './setPassword/slice'
import loginReducer from './login/slice'
import userReducer from './user/slice'
import communityInfoReducer from './chapterInfo/slice'
import healthSystemsReducer from './healthSystemsList/slice'
import vendorInfoReducer from './vendorInfo/slice'
import chatsReducer from './chats/slice'
import chatDetailsReducer from './chatDetails/slice'
import chatHistoryReducer from './chatHistory/slice'
import notificationsReducer from './notifications/slice'
import checkInsReducer from './checkIns/slice'

const rootReducer = () =>
  combineReducers({
    [templateModuleName]: templateReducer,
    [templateDetailsModuleName]: templateDetailsReducer,
    [commonModuleName]: commonReducer,
    [setPasswordModuleName]: setPasswordReducer,
    [loginModuleName]: loginReducer,
    [userModuleName]: userReducer,
    [communityInfoModuleName]: communityInfoReducer,
    [healthSystemsModuleName]: healthSystemsReducer,
    [vendorInfosModuleName]: vendorInfoReducer,
    [chatsModuleName]: chatsReducer,
    [chatDetailsModuleName]: chatDetailsReducer,
    [chatHistoryModuleName]: chatHistoryReducer,
    [notificationsModuleName]: notificationsReducer,
    [checkInsModuleName]: checkInsReducer
  })

export default rootReducer
