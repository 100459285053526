import { Menu } from 'antd'
import { itemsActions } from './constants'
import './styles.scss'
import Typography from 'components/Typography/Typography'
import { useCognusRepositoryPopup } from '../../Providers/CognusRepositoryPopupProvider'
import { useCognusRepositoryContext } from '../../Providers/CognusRepositoryActions'

export type TProps = {
  setVisible: (val: boolean) => void
  uuid: string
  contractCategory: string
  led_by: string
  isClinicalReview: boolean
}
export const MenuItems = ({
  setVisible,
  uuid,
  contractCategory,
  led_by,
  isClinicalReview
}: TProps) => {
  const { setSelectedId, setSelectedCategoryName, setSelectedLedBy } =
    useCognusRepositoryContext().actions
  const acceptContractPopup = useCognusRepositoryPopup().acceptContractPopup
  const clinicalReviewPopup = useCognusRepositoryPopup().clinicalReviewPopup
  const terminateContractPopup =
    useCognusRepositoryPopup().terminateContractPopup
  const handleClick = (key: string) => {
    setVisible(false)
    setSelectedId(uuid)
    setSelectedCategoryName(contractCategory)
    setSelectedLedBy(led_by)
    switch (key) {
      case 'accept':
        return acceptContractPopup.actions.open()
      case 'terminate':
        return terminateContractPopup.actions.open()
      case 'review_details':
        return clinicalReviewPopup.actions.open()
      default:
        return
    }
  }

  const itemsForMenu = isClinicalReview
    ? itemsActions
    : itemsActions.filter((i) => i.key !== 'review_details')
  return (
    <Menu className="menu-items-wrapper">
      {itemsForMenu.map((i) => (
        <Menu.Item key={i.key} onClick={() => handleClick(i.key)}>
          <Typography.Body1>{i.label}</Typography.Body1>
        </Menu.Item>
      ))}
    </Menu>
  )
}
