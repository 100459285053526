import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import {
  TOpportunityAnalysisList,
  TOpportunityAnalysisListColumnsFunc,
  TOpportunityAnalysisListFilters,
  TOpportunityAnalysisListFiltersFunc
} from './types'
import { ContactPersons } from './ContactPersons'
import { DescriptionCell } from '../../../components/DescriptionCell/DescriptionCell'
import {
  TAvailableForEvaluationListFilters,
  TAvailableForEvaluationListFiltersFunc
} from '../AvailableForEvaluation/types'
import { formatOptionPortfolio } from '../../../helper/optionsFormatters'
import { ROLES } from '../../Permission'
import { Menu } from './Menu/Menu'
import {
  OPPORTUNITY_ANALYSIS_STATUSES_TO_CLASSNAME,
  Status
} from '../../../components/Status'
import { COGNUS_REPOSITORY_STATUS_NAME } from '../constants'
import ClinicalReviewColumn from './ClinicalReviewColumn'
import { getVendorsRequestAsync } from '../../ContractDetails/api'
import { EditableColumnType } from '../../../components/Table/types'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const columns = ({
  applyFilters,
  filters,
  isVendor,
  user,
  handleClinicalReviewModal
}: TOpportunityAnalysisListColumnsFunc): EditableColumnType<TOpportunityAnalysisList>[] => {
  const _columns: EditableColumnType<TOpportunityAnalysisList>[] = [
    {
      dataIndex: 'led_by',
      key: 'led_by',
      width: 62,
      title: 'Led by',
      render: (_, { contract_category, led_by, uuid }) => (
        <ContactPersons
          uuid={uuid}
          led_by={led_by}
          contract_category={contract_category}
        />
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'contract_category',
      key: 'contract_category',
      width: 240,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TOpportunityAnalysisList>
          dataIndex={'contract_category'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TOpportunityAnalysisListFilters,
          TOpportunityAnalysisListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          field="contract_category"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { contract_category }) => (
        <CellWithSubtitle title={contract_category} />
      ),
      filterIcon: !!filters?.contract_category?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'portfolio',
      key: 'portfolio',
      width: 143,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TOpportunityAnalysisList>
          dataIndex={'portfolio'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Portfolio
        </Sorter>
      ),
      filterDropdown: (props) =>
        isVendor ? (
          <Dropdown.Filter<
            TAvailableForEvaluationListFilters,
            TAvailableForEvaluationListFiltersFunc,
            unknown
          >
            applyFilters={applyFilters}
            appliedFilters={filters}
            searchCallback={fetchContractCategories}
            optionFormatter={formatOptionPortfolio}
            field={'portfolio'}
            asyncSearch
            formatCallback={(data: { value: string; label: string }[]) => {
              const newData = Array.from(
                new Set(
                  data
                    .filter((i) => i.value && i.label)
                    .map((i) => JSON.stringify(i))
                )
              )
              return newData.map((i) => JSON.parse(i))
            }}
            {...props}
          />
        ) : (
          <Dropdown.Filter<
            TOpportunityAnalysisListFilters,
            TOpportunityAnalysisListFiltersFunc,
            unknown
          >
            applyFilters={applyFilters}
            appliedFilters={filters}
            searchCallback={getVendorsRequestAsync}
            field={'vendors'}
            asyncSearch
            {...props}
          />
        ),
      filterIcon:
        !!filters?.portfolio?.length || !!filters?.portfolio?.length ? (
          <FilterApplied16 />
        ) : (
          <Filter16 />
        ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'vendor',
      key: 'vendor',
      title: 'Vendor',
      width: 273,
      render: (_, { contracts }) => {
        const text = contracts.map((i) => i.vendor_name).join(', ')
        return <DescriptionCell content={text} text={text} />
      }
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 90,
      render: (_, { status }) => (
        <Status.Default
          className={OPPORTUNITY_ANALYSIS_STATUSES_TO_CLASSNAME[status]}
        >
          {COGNUS_REPOSITORY_STATUS_NAME[status]}
        </Status.Default>
      ),
      showSorterTooltip: false
    }
  ]
  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD
  ) {
    _columns.push({
      dataIndex: 'clinical_review_percentage',
      key: 'clinical_review_percentage',
      title: 'Clinical review',
      width: 108,
      render: (
        _,
        { clinical_review_percentage, led_by, contract_category, uuid }
      ) => (
        <ClinicalReviewColumn
          led_by={led_by}
          uuid={uuid}
          handleClinicalReviewModal={handleClinicalReviewModal}
          contract_category={contract_category}
          clinical_review_percentage={clinical_review_percentage}
        />
      )
    })
  }
  _columns.push(
    {
      dataIndex: 'current_compliance',
      key: 'current_compliance',
      title: 'Current compliance',
      width: 139,
      editable: true,
      render: (_, row) => (
        <span className="separate-item">
          <CellWithSubtitle
            title={
              row.current_compliance !== null
                ? row.current_compliance + '%'
                : '-'
            }
          />
        </span>
      )
    },
    {
      dataIndex: 'saving_estimates',
      key: 'saving_estimates',
      title: 'Saving estimates',
      width: 139,
      editable: true,
      render: (_, row) => (
        <span className="separate-item">
          <CellWithSubtitle
            title={
              row.saving_estimates
                ? '$' + currencyFormatter(row.saving_estimates)
                : '-'
            }
          />
        </span>
      )
    }
  )

  if (
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.VICE_PRESIDENT
  ) {
    _columns.push({
      dataIndex: 'actions',
      key: 'actions',
      title: '',
      width: 48,
      render: (_, row) => (
        <Menu
          uuid={row.uuid}
          led_by={row.led_by}
          isClinicalReview={row.clinical_review_percentage.some(
            (item) => item.percentage !== 0
          )}
          contractCategory={row.contract_category}
        />
      )
    })
  }

  return _columns
}
