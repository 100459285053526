import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form, Radio, Space } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { setLoading } from 'redux/store/common/slice'
import {
  fetchHealthSystem,
  fetchHealthSystemsList
} from 'features/HealthSystems/api'
import { fetchCommonsHs, fetchContractCategories } from 'pages/CRUDUser/api'
import { getUser } from 'redux/store/user/getters'
import { createRFP, updateRFP } from 'features/RFP/RFPDetails/api'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import { validateMessages } from 'helper/common'
import { TRFPData } from 'features/RFP/RFPDetails/types'
import { TOption } from 'components/Select/types'
import {
  TFormRFPCreationFormProps,
  TFormRFPCreationFormFields,
  TFormRFPCreationForm
} from './forms.rfpCreation.d'
import './forms.rfpCreation.scss'
import { formatOptionNameValue } from '../../helper/optionsFormatters'
import { ROLES } from 'features/Permission'
import { Reassign } from 'features/Reassign/ReassignPopup'
import { routes } from 'router'
import useRouter from 'hooks/useRouter'
import { useShowPopup } from '../../hooks/useShowPopup'
import { Popup } from 'components/Popup'
import { useRFPDetailsContext } from '../../features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import {
  initialRfp,
  initialRfpCommons,
  PARTICIPATION_TYPE_OPTIONS,
  RFP_TYPE_OPTIONS
} from '../../features/RFP/RFPDetails/constants'
import { FieldWithLabel } from '../FieldWithLabel/FieldWithLabel'
import { RFP_STATUSES } from '../../features/RFP/constants'
import { getCPRHealthSystems } from '../../features/ContractsPipelineDetails/api'

export const RFPCreation = ({
  className,
  onValueChange,
  callback,
  data,
  form,
  setShowButtons
}: TFormRFPCreationFormProps): JSX.Element => {
  const [reassignRfpData, setRfpData] = useState({} as TRFPData)
  const [isOpenReassign, setIsOpenReassign] = useState(false)
  const [contractCategories, setContractCategories] = useState<TOption[]>([])
  const { state, actions } = useRFPDetailsContext()
  const [healthSystems, setHealthSystems] = useState<TOption[]>([])
  const [fields, setFields] = useState<TFormRFPCreationFormFields>([])
  const [isChanged, setIsChanged] = useState(false)
  const [healthSystem, setHealthSystem] = useState('')
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { id } = useParams()
  const { push } = useRouter()
  const isCommons = user?.role.includes('commons')
  const creationType = Form.useWatch('creation_type', form)

  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isChanged)

  const cprRelatedValues = useMemo(
    () => ({
      contract_pipeline: state.initQueryParams.cpr_uuid,
      contractCategoryId: state.initQueryParams.contract_category_id,
      contractCategoryName: state.initQueryParams.contract_category_name,
      cprLedBy: state.initQueryParams.led_by
    }),
    [state.initQueryParams]
  )

  useEffect(() => {
    if (!showPopup) actions.setActiveTab('0')
  }, [showPopup])

  const getHealthSystemDetails = useCallback(async () => {
    if (!user?.community || !user?.health_system) return
    await fetchHealthSystem(
      user.community as string,
      user.health_system as string
    ).then((resp) => {
      setHealthSystem(resp.data?.name)
    })
  }, [user])

  useEffect(() => {
    if (id === 'new') {
      getHealthSystemDetails()
    }
  }, [user, id])

  useEffect(() => {
    if (user.community || user.commons) {
      dispatch(setLoading(true))
      getOptions().finally(() => dispatch(setLoading(false)))
    }
  }, [user.community])
  useEffect(() => {
    if (data) {
      const formattedData = {}
      for (const key in data) {
        if (Array.isArray(data[key])) {
          formattedData[key] = data[key].map(formatOptionNameValue)
        } else {
          formattedData[key] = data[key]
        }
      }
      const fields = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fields)
    }
  }, [data])
  const getOptions = async () => {
    await fetchContractCategories().then((resp) => {
      if (!resp.data?.results) {
        return
      }

      setContractCategories(resp.data.results.map(formatOptionNameValue))
    })
    if (!!user.commons) {
      await fetchCommonsHs().then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setHealthSystems(resp.data.results.map(formatOptionNameValue))
      })
    } else {
      await fetchHealthSystemsList(user.community as string).then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setHealthSystems(resp.data.results.map(formatOptionNameValue))
      })
    }
    if (cprRelatedValues.contract_pipeline && id === 'new') {
      const initFieldsValue: Partial<TFormRFPCreationForm> = {}
      if (cprRelatedValues.cprLedBy) {
        if (cprRelatedValues.cprLedBy !== RFP_TYPE_OPTIONS[1].value) {
          // fetch HS list from contract pipeline and parse to init values
          const results = await getCPRHealthSystems(
            cprRelatedValues.contract_pipeline,
            {}
          )

          if (results.data?.results) {
            initFieldsValue.health_systems = results.data.results.map(
              formatOptionNameValue
            )
          }
        }
        const selectedVal = RFP_TYPE_OPTIONS.find(
          (option) => option.value === cprRelatedValues.cprLedBy
        )
        // need to check if led by wasn't from commons side and only then update view
        if (selectedVal) {
          initFieldsValue.creation_type = selectedVal.value
        }
      }

      if (
        cprRelatedValues.contractCategoryId &&
        cprRelatedValues.contractCategoryName
      ) {
        initFieldsValue.contract_categories = [
          {
            value: cprRelatedValues.contractCategoryId,
            label: cprRelatedValues.contractCategoryName
          }
        ]
      }

      // setting initial values for RFP
      if (Object.keys(initFieldsValue).length) {
        form.setFieldsValue(initFieldsValue)
        setIsChanged(true)
        setShowButtons(true)
        if (isCommons) {
          onValueChange({
            ...initialRfpCommons,
            ...initFieldsValue,
            name: ''
          })
        } else {
          onValueChange({
            ...initialRfp,
            ...initFieldsValue,
            name: ''
          })
        }
      }
    }
  }
  const handleSubmitForm = async (formData) => {
    dispatch(setLoading(true))
    const rfpData: TRFPData = {
      name: formData.name,
      contract_categories: formData.contract_categories
        ? typeof formData.contract_categories[0] === 'object'
          ? formData.contract_categories.map((i) => i.value)
          : formData.contract_categories
        : undefined,
      health_systems: formData.health_systems
        ? typeof formData.health_systems[0] === 'object'
          ? formData.health_systems.map((i) => i.value)
          : formData.health_systems
        : [],
      creation_type:
        user?.commons && data.status !== RFP_STATUSES.IN_CS_QUEUE
          ? 'commons'
          : formData.creation_type
    }
    if (!user.commons) {
      rfpData['participation_type'] = formData.participation_type
    }
    if (cprRelatedValues.contract_pipeline) {
      rfpData['contract_pipeline'] = cprRelatedValues.contract_pipeline
    }
    try {
      setIsChanged(false)
      if (id === 'new') {
        if (user.role === ROLES.COMMUNITY_PRESIDENT) {
          setRfpData({ ...rfpData, creator: user.uuid })
          setIsOpenReassign(true)
          return
        }
        await createRFP({ ...rfpData, creator: user.uuid }).then((resp) => {
          push(`${routes.bidding}${routes.rfp}/${resp?.data?.uuid}/`)
          setShowButtons(false)
        })
      } else {
        await updateRFP(
          id as string,
          rfpData,
          'RFP has been successfully saved.'
        ).then(() => {
          if (callback) {
            callback()
          }
        })
      }
    } finally {
      setShowButtons(false)
      dispatch(setLoading(false))
    }
  }
  const canEditRfpType =
    (user.role !== ROLES.COMMUNITY_PRESIDENT &&
      !user.commons &&
      (id === 'new' ||
        data.status === RFP_STATUSES.CREATED ||
        data.status === RFP_STATUSES.WAITING_FOR_ASSIGNMENT)) ||
    (data.status === RFP_STATUSES.IN_CS_QUEUE &&
      user?.commons &&
      data.creation_type !== 'commons')

  return (
    <>
      <Form<TFormRFPCreationForm>
        onFinish={handleSubmitForm}
        fields={fields}
        form={form}
        initialValues={isCommons ? initialRfpCommons : initialRfp}
        onValuesChange={(value, all) => {
          if (!!value?.creation_type && value?.creation_type === 'community') {
            form.setFieldsValue({ health_systems: [] })
          }
          onValueChange(all)
        }}
        onFieldsChange={() => setIsChanged(true)}
        validateMessages={validateMessages}
        className={cn('rfp-creation-form', className)}
        id="rfp-creation"
      >
        <div className="row two-columns">
          <Input
            propsItem={{
              name: 'name',
              label: LABELS.NAME,
              rules: [
                {
                  pattern: /^[-\w\s]+$/,
                  max: 100,
                  required: true
                }
              ]
            }}
            propsInput={{
              showCount: true,
              maxLength: 100
            }}
          />
        </div>
        <div className="row mt-24">
          <Select.Multi
            options={contractCategories}
            propsItem={{
              name: 'contract_categories',
              label: LABELS.CONTRACT_CATEGORIES,
              rules: [{ required: true }]
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              disabled: data.status === RFP_STATUSES.IN_CS_QUEUE
            }}
          />
        </div>
        {canEditRfpType && (
          <div className="mt-24 rfp-type-wrapper row">
            <Form.Item
              label={LABELS.RFP_TYPE}
              name="creation_type"
              colon={false}
            >
              <Radio.Group
                className={cn('radio-group-default', 'mt-16')}
                disabled={data.status === RFP_STATUSES.IN_CS_QUEUE}
              >
                <Space direction="vertical">
                  <>
                    {RFP_TYPE_OPTIONS.map((i) => {
                      return (
                        <>
                          <Radio value={i.value}>
                            <Typography.Body1
                              weight={TYPOGRAPHY_WEIGHT.NORMAL}
                              className={cn('run-new-step-option')}
                            >
                              {i.label}
                            </Typography.Body1>
                          </Radio>
                          {i.value === RFP_TYPE_OPTIONS[0].value ? (
                            <Typography.Caption className="rfp-type-option">
                              RFP and contracts can be shared with other Health
                              Systems inside Community
                            </Typography.Caption>
                          ) : null}
                          {i.value === RFP_TYPE_OPTIONS[1].value ? (
                            <Typography.Caption className="rfp-type-option">
                              RFP and contract can be used by the selected
                              Health System only
                            </Typography.Caption>
                          ) : null}
                        </>
                      )
                    })}
                  </>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        {id !== 'new' && data.status !== RFP_STATUSES.CREATED && !user.commons && (
          <div className="row two-columns mt-24">
            {!canEditRfpType && (
              <FieldWithLabel
                title={LABELS.PARTICIPATION_TYPE}
                className="participation-type-view"
              >
                {
                  PARTICIPATION_TYPE_OPTIONS.find(
                    (i) => i.value === data?.participation_type
                  )?.label
                }
              </FieldWithLabel>
            )}
            <FieldWithLabel title={LABELS.RFP_TYPE} className="rfp-type-view">
              {
                RFP_TYPE_OPTIONS.find((i) => i.value === data?.creation_type)
                  ?.label
              }
            </FieldWithLabel>
          </div>
        )}
        <div className="row mt-24">
          {creationType === 'community' || !creationType ? (
            <Select.Multi
              options={healthSystems}
              propsItem={{
                label: LABELS.HEALTH_SYSTEM,
                name: 'health_systems',
                rules: [{ required: true }]
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT
              }}
            />
          ) : (
            <FieldWithLabel
              className="rfp-creation-type-default"
              title="Health System"
            >
              {id === 'new' ? healthSystem : data.health_systems[0]?.name}
            </FieldWithLabel>
          )}
        </div>
        {(id === 'new' || data.status === RFP_STATUSES.CREATED) &&
          !user.commons && (
            <div className="mt-24 row two-columns rfp-type-wrapper participants">
              <Form.Item
                label={LABELS.PARTICIPATION_TYPE}
                name="participation_type"
                colon={false}
              >
                <Radio.Group className={cn('radio-group-default', 'mt-16')}>
                  <Space direction="vertical">
                    <>
                      {PARTICIPATION_TYPE_OPTIONS.map((i) => {
                        return (
                          <>
                            <Radio value={i.value}>
                              <Typography.Body1
                                weight={TYPOGRAPHY_WEIGHT.NORMAL}
                                className={cn('run-new-step-option')}
                              >
                                {i.label}
                              </Typography.Body1>
                            </Radio>
                          </>
                        )
                      })}
                    </>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          )}
      </Form>
      {id === 'new' && user.role === ROLES.COMMUNITY_PRESIDENT && (
        <Reassign
          isCreatingRFP
          rfpData={reassignRfpData}
          isRFP
          isReassignPopupOpen={isOpenReassign}
          setIsReassignPopupOpen={setIsOpenReassign}
          ownerRole={user.role}
          ownerName={`${user.first_name} ${user.last_name}`}
        />
      )}
      <Popup.Discard
        visible={showPopup}
        onCancel={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </>
  )
}
