export const termsOfServicesText = [
  {
    text:
      'The CognusHealth.com website is a copyrighted work belonging to CognusHealth (the “Company”). Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.<br/><div class="top"/>' +
      'All such additional terms, guidelines, and rules are incorporated by reference into these Terms.<br/><div class="top"/>' +
      'These Terms of Use describe the legally binding terms and conditions that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.<br/><div class="top"/>' +
      'These terms require the use of arbitration on an individual basis to resolve disputes and also limit the remedies available to you in the event of a dispute.<br/><div class="top"/>'
  },
  {
    title: 'Access to the Site',
    text:
      '<div class="top"/><span>Subject to these Terms.</span> Company grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use.<br/><div class="top"/>' +
      '<div class="top"/><span>Certain Restrictions.</span> The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site must be retained on all copies thereof.<br/><div class="top"/>' +
      'Company reserves the right to change, suspend, or cease the Site with or without notice to you. You approved that Company will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part.<br/><div class="top"/>' +
      '<span>No Support or Maintenance.</span> You agree that Company will have no obligation to provide you with any support in connection with the Site.<br/><div class="top"/>' +
      'Excluding any User Content that you may provide, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Company or Company’s suppliers. Note that these Terms and access to the Site do not give you any rights, title or interest in or to any intellectual property rights. Company and its suppliers reserve all rights not granted in these Terms.<div class="top"/>'
  },
  {
    title: 'User Content',
    text:
      '<div class="top"/><span>User Content.</span> "User Content" means any and all information and content that a user submits to the Site. You are exclusively responsible for your User Content. You bear all risks associated with use of your User Content. You hereby certify that your User Content does not violate our Acceptable Use Policy. You may not represent or imply to others that your User Content is in any way<br/><div class="top"/>' +
      'provided, sponsored or endorsed by Company. Because you alone are responsible for your User Content, you may expose yourself to liability. Company is not obliged to backup any User Content that you post; also, your User Content may be deleted at any time without prior notice to you. You are solely responsible for making your own backup copies of your User Content if you desire.\n' +
      '<br/><div class="top"/>You hereby grant to Company an irreversible, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your User Content in the Site. You hereby irreversibly waive any claims and assertions of moral rights or attribution with respect to your User Content.'
  },
  {
    text:
      '<div class="top"/><span>Acceptable Use Policy.</span> The following terms constitute our "Acceptable Use Policy": You agree not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right or any intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false,\n' +
      '<div class="top"/>intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.\n' +
      '<div class="top"/>In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any software intended to damage or alter a computer system or data; (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages; (iii) use the Site to harvest, collect, gather\n' +
      '<div class="top"/>or assemble information or data regarding other users without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Site, whether through password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Site; or (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to the Site.\n' +
      '<div class="top"/>We reserve the right to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person. Such action may include removing or modifying your User Content, terminating your Account in accordance with Section 8, and/or reporting you to law enforcement authorities.\n' +
      '<div class="top"/>If you provide Company with any feedback or suggestions regarding the Site, you hereby assign to Company all rights in such Feedback and agree that Company shall have the right to use and fully exploit such Feedback and related information in any manner it believes appropriate. Company will treat any Feedback you provide to Company as non-confidential and non-proprietary.\n' +
      '<div class="top"/>You agree to indemnify and hold Company and its officers, employees, and agents harmless, including costs and attorneys’ fees, from any claim or demand made by any third-party due to or arising out of (a) your use of the Site, (b) your violation of these Terms, (c) your violation of applicable laws or regulations or (d) your User Content. Company reserves the right to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Company. Company will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.<div class="divider"/>'
  },
  {
    title: 'Third-Party Links & Ads; Other Users',
    text:
      '<div class="top"/><span>Third-Party Links & Ads.</span> The Site may contain links to third-party websites and services, and/or display advertisements for third-parties. Such Third-Party Links & Ads are not under the control of Company, and Company is not responsible for any Third-Party Links & Ads. Company provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links\n' +
      '<div class="top"/>& Ads. You use all Third-Party Links & Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.'
  },
  {
    text:
      '<span>Other Users.</span> Each Site user is solely responsible for any and all of its own User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any\n' +
      '<div class="top"/>User Content, whether provided by you or by others. You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Site user, we are under no obligation to become involved.\n' +
      '<div class="top"/>You hereby release and forever discharge the Company and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site. If you are a California resident, you hereby waive California civil code section\n' +
      '<div class="top"/>1542 in connection with the foregoing, which states: "a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor."<div class="divider"/>'
  },
  {
    title: 'Disclaimers',
    text:
      '<div class="top"/>The site is provided on an "as-is" and "as available" basis, and company and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. We and our suppliers make not guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.\n' +
      '<div class="top"/>Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.<div class="divider"/>'
  },
  {
    title: 'Limitation on Liability',
    text:
      '<div class="top"/>To the maximum extent permitted by law, in no event shall company or our suppliers be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if company has been advised of the possibility of such damages. Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.\n' +
      '<div class="top"/>To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to this agreement, will at all times be\n' +
      '<div class="top"/>limited to a maximum of fifty U.S. dollars ($50). The existence of more than one claim will not enlarge this limit. You agree that our suppliers will have no liability of any kind arising from or relating to this agreement.\n' +
      '<div class="top"/>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.'
  },
  {
    text:
      '<div class="top"/><span>Term and Termination.</span> Subject to this Section, these Terms will remain in full force and effect\n' +
      '<div class="top"/>while you use the Site. We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately. You understand that any termination of your Account may involve deletion\n' +
      '<div class="top"/>of your User Content associated with your Account from our live databases. Company will not have any liability whatsoever to you for any termination of your rights under these Terms. Even after your rights under these Terms are terminated.<div class="divider"/>'
  },
  {
    title: 'Copyright Policy',
    text:
      '<div class="top"/>Company respects the intellectual property of others and asks that users of our Site do the same. In connection with our Site, we have adopted and implemented a policy respecting copyright law\n' +
      '<div class="top"/>that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeated infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright\n' +
      '<div class="top"/>Agent:' +
      '<ul><li>your physical or electronic signature;</li>' +
      '<li>identification of the copyrighted work(s) that you claim to have been infringed;</li>' +
      '<li>identification of the material on our services that you claim is infringing and that you request us to remove;</li>' +
      '<li>sufficient information to permit us to locate such material;</li>' +
      '<li>your address, telephone number, and e-mail address;</li>' +
      '<li>a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</li>' +
      '<li>a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li></ul>' +
      '<div class="top"/>Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.<div class="divider"/>'
  },
  {
    title: 'General',
    text: '<div class="top"/>These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site. You are responsible for providing us with your most current email address. In the event that the last e-mail address that you have provided us is not valid, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Any changes to these Terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an email notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site. These changes will be effective immediately for new users of our Site. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. Dispute Resolution. Please read this Arbitration Agreement carefully. It is part of your contract with Company and affects your rights. It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.'
  },
  {
    text: '<div class="top"/><span>Applicability of Arbitration Agreement.</span> All claims and disputes in connection with the Terms or the use of any product or service provided by the Company that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed to, all arbitration proceedings shall be held in English. This Arbitration Agreement applies to you and the Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.'
  },
  {
    text: '<div class="top"/><span>Notice Requirement and Informal Dispute Resolution.</span> Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute describing the nature and basis of the claim or dispute, and the requested relief. A Notice to the Company should be sent to: Alabama. After the Notice is received, you and the Company may attempt to resolve the claim or dispute informally. If you and the Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award to which either party is entitled.'
  },
  {
    text: '<div class="top"/><span>Arbitration Rules.</span> Arbitration shall be initiated through the American Arbitration Association, an established alternative dispute resolution provider that offers arbitration as set forth in this section. If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider. The rules of the ADR Provider shall govern all aspects of the arbitration except to the extent such rules are in conflict with the Terms. The AAA Consumer Arbitration Rules governing the arbitration are available online at adr.org or by calling the AAA at 1-800-778-7879. The arbitration shall be conducted by a single, neutral arbitrator. Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief. For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules. Any hearing will be held in a location within 50 miles of Company’s principle office, unless the parties agree otherwise. If you reside outside of the U.S., the arbitrator shall give the parties reasonable notice of the date, time and place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. If the arbitrator grants you an award that is greater than the last settlement offer that the Company made to you prior to the initiation of arbitration, the Company will pay you the greater of the award or $2,500.00. Each party shall bear its own costs and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.'
  },
  {
    text: '<div class="top"/><span>Additional Rules for Non-Appearance Based Arbitration.</span> If non-appearance based arbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration. The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.'
  },
  {
    text: '<div class="top"/><span>Time Limits.</span> If you or the Company pursues arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations and within any deadline imposed under the AAA Rules for the pertinent claim.'
  },
  {
    text: '<div class="top"/><span>Authority of Arbitrator.</span> If arbitration is initiated, the arbitrator will decide the rights and liabilities of you and the Company, and the dispute will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms. The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and the Company.'
  },
  {
    text: '<div class="top"/><span>Waiver of Jury Trial.</span> THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement. Arbitration procedures are typically more limited, more efficient and less expensive than rules applicable in a court and are subject to very limited review by a court. In the event any litigation should arise between you and the Company in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.'
  },
  {
    text: '<div class="top"/><span>Waiver of Class or Consolidated Actions.</span> All claims and disputes within the scope of this arbitration agreement must be arbitrated or litigated on an individual basis and not on a class basis, and claims of more than one customer or user cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.'
  },
  {
    text: '<div class="top"/><span>Confidentiality.</span> All aspects of the arbitration proceeding shall be strictly confidential. The parties agree to maintain confidentiality unless otherwise required by law. This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.'
  },
  {
    text: '<div class="top"/><span>Severability.</span> If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.'
  },
  {
    text: '<div class="top"/><span>Right to Waive.</span> Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted. Such waiver shall not waive or affect any other portion of this Arbitration Agreement.'
  },
  {
    text: '<div class="top"/><span>Survival of Agreement.</span> This Arbitration Agreement will survive the termination of your relationship with Company.'
  },
  {
    text:
      '<div class="top"/><span>Emergency Equitable Relief.</span> Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration. A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.\n' +
      '<div class="top"/>In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within Allegheny County, Pennsylvania, for such purposes.\n' +
      '<div class="top"/>The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, re-export, or transfer, directly or indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in violation of the United States export laws or regulations.\n' +
      '<div class="top"/>Company is located in Cranberry Township, Pennsylvania. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.'
  },
  {
    text: '<div class="top"/><span>Electronic Communications.</span> The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal obligation that such communications would satisfy if it were be in a hard copy writing.'
  },
  {
    text:
      '<div class="top"/><span>Entire Terms.</span> These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only\n' +
      '<div class="top"/>and have no legal or contractual effect. The word "including" means "including without limitation". If any provision of these Terms is held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Company may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.'
  },
  {
    text: '<div class="top"/><span>Your Privacy.</span> Please read our Privacy Policy.'
  },
  {
    text: '<div class="top"/><span>Copyright/Trademark Information.</span> Copyright ©. All rights reserved. All trademarks, logos and service marks displayed on the Site are our property or the property of other third-parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.'
  }
]

export const privacyNotice = [
  {
    text:
      '<div class="top"/>This privacy notice for CognusHealth LLC ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:' +
      '<div class="top"/><ul><li>Visit our website at www.cognushealth.com, or any website of ours that links to this privacy notice</li>' +
      '<li>Download and use our mobile application (Cognus Health), or any other application of ours that links to this privacy notice</li>' +
      '<li>Engage with us in other related ways, including any sales, marketing, or events</li></ul>' +
      '<div class="top"/>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>.<div class="divider"/>'
  },
  {
    title: 'Summary of key points',
    text: '<div class="top"/><span>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click here to go directly to our table of contents.</span>'
  },
  {
    text: '<div class="top"/><span>What personal information do we process?</span> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with CognusHealth LLC and the Services, the choices you make, and the products and features you use.'
  },
  {
    text: '<div class="top"/><span>Do we process any sensitive personal information?</span> We do not process sensitive personal information.'
  },
  {
    text: '<div class="top"/><span>Do we receive any information from third parties?</span> We do not receive any information from third parties.'
  },
  {
    text: '<div class="top"/><span>How do we process your information?</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click here to learn more.'
  },
  {
    text: '<div class="top"/><span>In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties.'
  },
  {
    text: '<div class="top"/><span>How do we keep your information safe?</span> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.'
  },
  {
    text: '<div class="top"/><span>What are your rights?</span> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.'
  },
  {
    text: '<div class="top"/><span>How do you exercise your rights?</span> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.'
  },
  {
    text: 'Want to learn more about what CognusHealth LLC does with any information we collect? Review the notice in full.<div class="divider"/>'
  },
  { title: 'Table of contents', text: '<div class="divider"/>' }
]

export const privacyAnchor = [
  {
    text: 'What information do we collect?',
    link: 'collect-information'
  },
  { text: 'How do we process your information?', link: 'information-process' },
  {
    text: 'When and with whom do we share your personal information?',
    link: 'share-personal-information'
  },
  { text: 'How long do we keep your information?', link: 'keep-information' },
  { text: 'How do we keep your information safe? ', link: 'information-safe' },
  {
    text: 'Do we collect information from minors?',
    link: 'information-from-mirrors'
  },
  { text: 'What are your privacy rights?', link: 'privacy-rights' },
  { text: 'Controls for do-not-track features', link: 'do-not-track' },
  {
    text: 'Do California residents have specific privacy rights?',
    link: 'california-specific-rights'
  },
  {
    text: 'Do Virginia residents have specific privacy rights?',
    link: 'virginia-specific-rights'
  },
  { text: 'Do we make updates to this notice?', link: 'notice-updates' },
  { text: 'How can you contact us about this notice?', link: 'contact-us' },
  {
    text: 'How can you review, update, or delete the data we collect from you?',
    link: 'crud-data'
  }
]

export const privacyTextWithLinks = [
  { text: '<div class="divider"/>' },
  {
    title: '1. What information do we collect?',
    id: 'collect-information'
  },
  {
    text: '<div class="top"/><span>Personal information you disclose to us.</span>'
  },
  {
    text: '<div class="top"/><span>In Short:</span> We collect personal information that you provide to us.'
  },
  {
    text: '<div class="top"/>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.'
  },
  {
    text: '<div class="top"/><span>Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use.'
  },
  {
    text: '<div class="top"/>The personal information we collect may include the following:<div class="divider"/>'
  },
  {
    text:
      '<ul><li>names</li>' +
      '<li>phone numbers</li>' +
      '<li>email addresses</li>' +
      '<li>job titles</li>' +
      '<li>usernames</li>' +
      '<li>passwords</li>' +
      '<li>contact preferences</li>' +
      '<li>contact or authentication data</li>'
  },
  {
    text: '<div class="top"/><span>Sensitive Information.</span> We do not process sensitive information.'
  },
  {
    text: '<div class="top"/><span>Payment Data.</span> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/privacy" target="_blank">https://stripe.com/privacy</a>.'
  },
  {
    text: '<div class="top"/><span>Application Data.</span> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:'
  },
  {
    text: '<div class="top"/><ul><li>Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device\'s settings.</li></ul>'
  },
  {
    text: 'This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.'
  },
  {
    text: '<div class="top"/>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.'
  },
  { text: '<div class="divider"/>' },
  {
    title: '2. How do we process your information?',
    id: 'information-process',
    text: '<div class="top"/><span>In Short:</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.'
  },
  {
    text: '<div class="top"/><span>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span>'
  },
  { text: '<div class="divider"/>' },
  { text: '<div class="divider"/>' },
  {
    text:
      '<ul><li><span>To facilitate account creation and authentication and otherwise manage user accounts.</span> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>' +
      '<li><span>To deliver and facilitate delivery of services to the user.</span> We may process your information to provide you with the requested service.</li>' +
      '<li><span>To respond to user inquiries/offer support to users.</span> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>' +
      '<li><span>To send administrative information to you.</span> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>' +
      '<li><span>To enable user-to-user communications.</span> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li></ul>'
  },
  { text: '<div class="divider"/>' },
  {
    title: '3. When and with whom do we share your personal information?',
    id: 'share-personal-information',
    text: '<div class="top"/><span>In Short:</span> We may share information in specific situations described in this section and/or with the following third parties.'
  },
  {
    text: '<div class="top"/>We may need to share your personal information in the following situations:'
  },

  {
    title: '',
    text:
      '<ul><li><span>Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>' +
      '<li><span>Affiliates.</span> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>' +
      '<li><span>Business Partners.</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>' +
      '<li><span>Other Users.</span> When you share personal information (for example, by posting comments, contributions. or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li></ul>'
  },
  { text: '<div class="divider"/>' },
  {
    title: '4. How long do we keep your information?',
    id: 'keep-information',
    text: '<div class="top"/><span>In Short:</span> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.'
  },
  {
    text: '<div class="top"/>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.'
  },
  {
    text: '<div class="top"/>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.'
  },
  { text: '<div class="divider"/>' },
  {
    title: '5. How do we keep your information safe? ',
    id: 'information-safe',
    text: '<div class="top"/><span>In Short:</span> We aim to protect your personal information through a system of organizational and technical security measures.'
  },
  {
    text: 'We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.'
  },
  { text: '<div class="divider"/>' },
  {
    title: '6. Do we collect information from minors?',
    id: 'information-from-mirrors',
    text: '<div class="top"/><span>In Short:</span> We do not knowingly collect data from or market to children under 18 years of age.'
  },
  {
    text: '<div class="top"/>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent\'s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>.'
  },
  { text: '<div class="divider"/>' },
  {
    title: '7. What are your privacy rights?',
    id: 'privacy-rights',
    text: '<div class="top"/><span>In Short:</span> You may review, change, or terminate your account at any time.'
  },
  {
    text: '<div class="top"/>If you are located in the EA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.'
  },
  {
    text: '<div class="top"/>If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank"/>https://www.edoeb.admin.ch/edoeb/en/home.html</a>.'
  },
  {
    text: '<div class="top"/><span class="text-decoration">Withdrawing your consent:</span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.'
  },
  {
    text: '<div class="top"/>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.'
  },
  { title: '', text: '<div class="top"/><span>Account Information</span>' },
  {
    text: '<div class="top"/>If you would at any time like to review or change the information in your account or terminate your account, you can:<div class="divider"/>'
  },
  {
    text:
      '<ul><li>Log in to your account settings and update your user account.</li>' +
      '<li>Contact us using the contact information provided.</li></ul>'
  },
  {
    text: '<div class="top"/>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.'
  },
  {
    text: 'If you have questions or comments about your privacy rights, you may email us at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>'
  },
  { text: '<div class="divider"/>' },
  {
    title: '8. Controls for do-not-track features',
    id: 'do-not-track',
    text: '<div class="top"/>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.'
  },
  { text: '<div class="divider"/>' },
  {
    title: '9. Do California residents have specific privacy rights?',
    id: 'california-specific-rights',
    text: '<div class="top"/><span>In Short:</span> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.'
  },
  {
    text: '<div class="top"/>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.'
  },
  {
    text: '<div class="top"/>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).'
  },
  { text: '<div class="top"/><span>CPA Privacy Notice</span>' },
  {
    text: '<div class="top"/>The California Code of Regulations defines a "resident" as:<div class="divider"/>'
  },
  {
    text:
      '<ul><li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>' +
      '<li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li></ul>'
  },
  {
    text: '<div class="top"/>All other individuals are defined as "non-residents."'
  },
  {
    text: '<div class="top"/>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.'
  },
  {
    text: '<div class="top"/><span>What categories of personal information do we collect?</span>'
  },
  {
    text: '<div class="top"/>We have collected the following categories of personal information in the past twelve (12) months:'
  }
]

export const privacyWithLinksSecond = [
  {
    text: '<div class="top"/>We will use and retain the collected personal information as needed to provide the Services or for:<div class="divider"/>'
  },
  {
    text:
      '<ul><li>Category A - As long as the user has an account with us</li>' +
      '<li>Category B - As long as the user has an account with us</li>' +
      '<li>Category D - As long as the user has an account with us</li></ul>'
  },
  {
    text: '<div class="top"/>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:<div class="divider"/>'
  },
  {
    text:
      '<ul><li>Receiving help through our customer support channels;</li>' +
      '<li>Participation in customer surveys or contests; and</li>' +
      '<li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>'
  },
  {
    text: '<div class="top"/><span>How do we use and share your personal information?</span>'
  },
  {
    text: '<div class="top"/>More information about our data collection and sharing practices can be found in this privacy notice.'
  },
  {
    text: '<div class="top"/>You may contact us by email at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>, or by referring to the contact details at the bottom of this document.'
  },
  {
    text: '<div class="top"/>If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.'
  },
  {
    text: '<div class="top"/><span>Will your information be shared with anyone else?</span>'
  },
  {
    text: '<div class="top"/>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CPA.'
  },
  {
    text: '<div class="top"/>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.'
  },
  {
    text: '<div class="top"/>CognusHealth LLC has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. CognusHealth LLC will not sell or share personal information in the future belonging to website visitors, users, and other consumers.'
  },
  {
    text: '<div class="top"/><span>Your rights with respect to your personal data</span>'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Right to request deletion of the data - Request to delete</div>'
  },
  {
    text: '<div class="top"/>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Right to be informed - Request to know</div>'
  },
  {
    text: '<div class="top"/>Depending on the circumstances, you have a right to know:<div class="divider">'
  },
  {
    text:
      '<ul><li>whether we collect and use your personal information;</li>' +
      '<li>the categories of personal information that we collect;</li>' +
      '<li>the purposes for which the collected personal information is used;</li>' +
      '<li>whether we sell or share personal information to third parties;</li>' +
      '<li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>' +
      '<li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</li>' +
      '<li>the business or commercial purpose for collecting, selling, or sharing personal information; and</li>' +
      '<li>the specific pieces of personal information we collected about you.</li></ul>'
  },
  {
    text: '<div class="top"/>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Right to Non-Discrimination for the Exercise of a Consumer\'s Privacy Rights</div>'
  },
  {
    text: '<div class="top"/>We will not discriminate against you if you exercise your privacy rights.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Right to Limit Use and Disclosure of Sensitive Personal Information</div>'
  },
  {
    text: '<div class="top"/>We do not process consumer\'s sensitive personal information.'
  },
  { text: '<div class="top"/><span>Verification process</span>' },
  {
    text: '<div class="top"/>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.'
  },
  {
    text: '<div class="top"/>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Other privacy rights</div><div class="divider"/>'
  },
  {
    text:
      '<ul><li>You may object to the processing of your personal information.</li>' +
      '<li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>' +
      '<li>You can designate an authorized agent to make a request under the CPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CPA.</li>' +
      '<li>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</li></ul>'
  },
  {
    text: '<div class="top"/>To exercise these rights, you can contact us by email at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.<div class="divider"/>'
  },
  {
    title: '10. Do Virginia residents have specific privacy rights?',
    id: 'virginia-specific-rights',
    text: '<div class="top"/><span>In Short:</span> Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.'
  },
  {
    text: '<div class="top"/><span>Virginia CDPA Privacy Notice</span>'
  },
  {
    text: '<div class="top"/>Under the Virginia Consumer Data Protection Act (CDPA):'
  },
  {
    text: '<div class="top"/>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.'
  },
  {
    text: '<div class="top"/>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person.'
  },
  {
    text: '<div class="top"/>"Personal data" does not include de-identified data or publicly available information.'
  },
  {
    text: '<div class="top"/>"Sale of personal data" means the exchange of personal data for monetary consideration.'
  },
  {
    text: '<div class="top"/>If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.'
  },
  {
    text: '<div class="top"/>The information we collect, use, and disclose about you will vary depending on how you interact with CognusHealth LLC and our Services. To find out more, please visit the following links:<div class="divider"/>'
  },
  {
    text:
      '<ul><li>Personal data we collect</li>' +
      '<li>How we use your personal data</li>' +
      '<li>When and with whom we share your personal data</li></ul>'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Your rights with respect to your personal data</div>'
  },
  {
    text:
      '<div class="top"/><ul><li>Right to be informed whether or not we are processing your personal data</li>' +
      '<li>Right to access your personal data</li>' +
      '<li>Right to correct inaccuracies in your personal data</li>' +
      '<li>Right to request deletion of your personal data</li>' +
      '<li>Right to obtain a copy of the personal data you previously shared with us</li>' +
      '<li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li></ul>'
  },
  {
    text: '<div class="top"/>CognusHealth LLC has not sold any personal data to third parties for business or commercial purposes.'
  },
  {
    text: '<div class="top"/>CognusHealth LLC will not sell personal data in the future belonging to website visitors, users, and other consumers.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Exercise your rights provided under the Virginia CDPA</div>'
  },
  {
    text: '<div class="top"/>More information about our data collection and sharing practices can be found in this privacy notice.\n'
  },
  {
    text: '<div class="top"/>You may contact us by email at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a> or by referring to the contact details at the bottom of this document.'
  },
  {
    text: '<div class="top"/>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Verification process</div>'
  },
  {
    text: '<div class="top"/>We may request that you provide additional information reasonably necessary to verify you and your consumer\'s request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.'
  },
  {
    text: '<div class="top"/>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.'
  },
  {
    text: '<div class="top"/><div class="text-decoration">Right to appeal</div>'
  },
  {
    text: '<div class="top"/>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to submit a complaint.<div class="divider"/>'
  },
  {
    title: '11. Do we make updates to this notice?',
    id: 'notice-updates',
    text: '<div class="top"/><span>In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.'
  },
  {
    text: '<div class="top"/>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.'
  },
  { text: '<div class="divider"/>' },
  {
    title: '12. How can you contact us about this notice?',
    id: 'contact-us',
    text: '<div class="top"/>If you have questions or comments about this notice, you may email us at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a> or by post to:'
  },
  { text: '<div class="top"/>CognusHealth LLC' },
  { text: '63 Burke Road' },
  { text: 'Cranberry Township, PA 16066-3903' },
  { text: 'United States' },
  { text: '<div class="divider"/>' },
  {
    title:
      '13. How can you review, update, or delete the data we collect from you?',
    id: 'crud-data',
    text: '<div class="top"/>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please email us at <a href="mailto:info@cognushealth.com">info@cognushealth.com</a>.'
  }
]

export const privacyTable = [
  {
    category: 'A. Identifiers',
    examples:
      'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name',
    collected: true
  },
  {
    category:
      'B. Personal information categories listed in the California Customer Records statute',
    examples:
      'Name, contact information, education, employment, employment history, and financial information',
    collected: true
  },
  {
    category:
      'C. Protected classification characteristics under California or federal law',
    examples: 'Gender and date of birth',
    collected: false
  },
  {
    category: 'D. Commercial information',
    examples:
      'Transaction information, purchase history, financial details, and payment information',
    collected: true
  },
  {
    category: 'E. Biometric information',
    examples: 'Fingerprints and voiceprints',
    collected: false
  },
  {
    category: 'F. Internet or other similar network activity',
    examples:
      'Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements',
    collected: false
  },
  {
    category: 'G. Geolocation data',
    examples: 'Device location',
    collected: false
  },
  {
    category:
      'H. Audio, electronic, visual, thermal, olfactory, or similar information',
    examples:
      'Images and audio, video or call recordings created in connection with our business activities',
    collected: false
  },
  {
    category: 'I. Professional or employment-related information',
    examples:
      'Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us',
    collected: false
  },
  {
    category: 'J. Education Information',
    examples: 'Student records and directory information',
    collected: false
  },
  {
    category: 'K. Inferences drawn from other personal information',
    examples:
      "Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics",
    collected: false
  },
  {
    category: 'L. Sensitive Personal Information',
    examples: '',
    collected: false
  }
]
