import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TContractsListResponse,
  TContractsListRequest,
  TRequestsForAnalysisCommunityResponse
} from './types'
import {
  TAvailableForEvaluationListRequest,
  TAvailableForEvaluationListResponse
} from '../CognusRepository/AvailableForEvaluation/types'
import {
  TOpportunityAnalysisContactPersonRequest,
  TOpportunityAnalysisContactPersonResponse,
  TOpportunityAnalysisListRequest,
  TOpportunityAnalysisListResponse,
  TRequestsForAnalysisCommonsResponse,
  TSavingAnalysisRequest
} from '../CognusRepository/OpportunityAnalysis/types'
import { ResponseWithPagination, VALIDATION_MESSAGES } from '../../constants'
import { TSavingResponse } from '../RequestForAnalysisDetails/types'
import {
  TContractPipelineList,
  TContractsPipelinesListRequest
} from '../ContractPipeline/Table/types'

const fetcher = new Fetcher({})

export const getContractsList: TContractsListRequest = async (data) =>
  fetcher.request<unknown, TContractsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getContractPipelineList: TContractsPipelinesListRequest = async (
  data
) =>
  fetcher.request<unknown, ResponseWithPagination<TContractPipelineList>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getAvailableForEvaluationList: TAvailableForEvaluationListRequest =
  async (data) =>
    fetcher.request<unknown, TAvailableForEvaluationListResponse>({
      url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.AVALIABLE_OPPORTUNITIES}`,
      method: HTTP_METHODS.GET,
      ...data
    })
export const getOpportunityAnalysisList: TOpportunityAnalysisListRequest =
  async (data) =>
    fetcher.request<unknown, TOpportunityAnalysisListResponse>({
      url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.EVALUATION}`,
      method: HTTP_METHODS.GET,
      ...data
    })
export const getOpportunityAnalysisSavings: TSavingAnalysisRequest = async (
  data
) =>
  fetcher.request<unknown, TSavingResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.SAVINGS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const setOpportunityAnalysisList: any = async (data) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.EVALUATION}`,
    method: HTTP_METHODS.POST,
    successMessage: VALIDATION_MESSAGES.SM00131,
    data
  })
export const getLedByContactPersonsList: TOpportunityAnalysisContactPersonRequest =
  async (uuid) =>
    fetcher.request<unknown, TOpportunityAnalysisContactPersonResponse>({
      url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.CONTRACT_GROUP}${uuid}/${API_DICTIONARY.CONTACT_PERSONS}`,
      method: HTTP_METHODS.GET
    })
export const getOpportunityAnalysisCommons = async () =>
  fetcher.request<unknown, TRequestsForAnalysisCommonsResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.REQUESTS_FOR_ANALYSIS}`,
    method: HTTP_METHODS.GET
  })

export const getOpportunityAnalysisCommunity = async () =>
  fetcher.request<unknown, TRequestsForAnalysisCommunityResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.REQUESTS_FOR_ANALYSIS}`,
    method: HTTP_METHODS.GET
  })
