import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { Container } from 'components/Container/Container'
import { Space, Checkbox } from 'antd'
import { Menu } from 'components/Menu/Menu'
import { Typography } from 'components/Typography'
import { getCommunityInfo } from '../../redux/store/chapterInfo/getters'
import './styles.scss'
import '../../components/Datepicker/datepicker.default.scss'
import moment, { Moment } from 'moment'
import { getTotalSpend } from './api'
import { COMPILANCE_TABS, initialData } from './constants'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ComplianceTable } from './ComplianceTable'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import {
  RangeValue,
  TGroupByMonth,
  TGroupDataListFilters,
  TTotalSpendData
} from './types'
import { ROLES } from '../../features/Permission'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import {
  currencyFormatter,
  getCurrencyValue,
  getHeight,
  sumPercentageTotal,
  totalSpendCounting
} from '../../features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { safeNumber } from '../../helper/common'
import { Datepicker } from 'components/Datepicker'
import {
  convertDateToRequestFormat,
  requestMonthFormat
} from '../../utils/moment'

export const Compliance = () => {
  const communityInfo = useSelector(getCommunityInfo)
  const currenUser = useSelector(getUser)
  const thisYear = moment().year()
  const [period, setPeriod] = useState<RangeValue>([
    moment(`Jan ${thisYear}`, requestMonthFormat),
    moment(`Dec ${thisYear}`, requestMonthFormat)
  ])
  const [activeTab, setActiveTab] = useState(COMPILANCE_TABS[0].key)
  const [myContracts, setMyContracts] = useState<boolean>(false)
  const [totalSpendData, setTotalSpendData] =
    useState<TTotalSpendData>(initialData)
  const [dataPerMonth, setDataPerMonth] = useState<TGroupByMonth[]>([])
  const [dates, setDates] = useState<RangeValue>([null, null])

  const getMonths = useCallback(() => {
    const allMonthsInPeriod: string[] = []
    if (period && period[0]) {
      let startDate = moment(period[0])
      while (startDate.isBefore(moment(period[1]).endOf('month'))) {
        allMonthsInPeriod.push(startDate.format('MMM YY'))
        startDate = startDate.add(1, 'month')
      }
    }
    return allMonthsInPeriod
  }, [period])

  useEffect(() => {
    getTotalSpendData()
  }, [getMonths, myContracts])

  const getTotalSpendData = () => {
    const initMonth = getMonths().map((i) => ({
      month: `${i}`,
      compliant_spend: '0',
      non_compliant_spend: '0'
    }))
    const params: TGroupDataListFilters = {}
    if (period && period[0] && period[1]) {
      params.date_from = convertDateToRequestFormat(period[0]?.startOf('month'))
      params.date_to = convertDateToRequestFormat(period[1]?.endOf('month'))
    }
    if (myContracts) {
      params.my_contracts = myContracts
    }
    getTotalSpend({ params }).then((resp) => {
      if (!resp.data) {
        return
      }
      setTotalSpendData({
        compliant_spend: resp.data.total_spend_data.compliant_spend,
        non_compliant_spend: resp.data.total_spend_data.non_compliant_spend,
        total_spend: resp.data.total_spend_data.total_spend
      })
      setDataPerMonth(
        initMonth.map((item) => {
          const month = resp.data.group_by_month_data.find(
            (j) => j.month === item.month
          )
          return !!month ? month : item
        })
      )
    })
  }

  const maxTotalSpent = dataPerMonth
    .map((i) => +i.non_compliant_spend + +i.compliant_spend)
    .reduce((p, v) => (p > v ? p : v), 0)

  const onChange = (e: CheckboxChangeEvent) => {
    setMyContracts(e.target.checked)
  }
  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false
    }
    const tooLate = dates[0] && current.diff(dates[0], 'month') >= 11
    const tooEarly = dates[1] && dates[1].diff(current, 'year') > 1
    return !!tooEarly || !!tooLate
  }
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null])
      setPeriod([null, null])
    } else {
      setDates(null)
    }
  }

  return (
    <Container title="Compliance" subtitle={communityInfo?.name}>
      <>
        <div className="compliance__header">
          <div className="compliance__datepicker-container">
            <Typography.Label weight="semi-bold">Period</Typography.Label>
            <Space>
              <Datepicker.Range
                isMonthRange
                propsRangeDate={{
                  picker: 'month',
                  format: requestMonthFormat,
                  disabledDate,
                  onChange: (dateStrings) => {
                    setPeriod(dateStrings as RangeValue)
                  },
                  onOpenChange,
                  onCalendarChange: (val) => setDates(val),
                  value: period || dates
                }}
              />
              {(currenUser?.role === ROLES.CONTRACT_STEWARD ||
                currenUser?.role === ROLES.VICE_PRESIDENT) && (
                <Checkbox className="compliance__checkbox" onChange={onChange}>
                  <Typography.Headline6 weight="normal">
                    Only my contracts
                  </Typography.Headline6>
                </Checkbox>
              )}
            </Space>
          </div>
          <div className="compliance__legend">
            <div className="legend__description">
              <div className="legend__rectangle legend__rectangle_color_compliant" />
              <Typography.Label>Compliant</Typography.Label>
            </div>
            <div className="legend__description">
              <div className="legend__rectangle legend__rectangle_color_non-compliant" />
              <Typography.Label>Non-compliant</Typography.Label>
            </div>
          </div>
        </div>
        <div className="charts_ongoing-contracts_container spent__widget">
          <div className="chart__description">
            <div className="description__summary">
              <Typography.Headline5 weight="semi-bold" className="total__title">
                Total spend
              </Typography.Headline5>
              <Typography.Headline1 weight="medium" className="total__subtitle">
                $
                {currencyFormatter(
                  safeNumber(totalSpendData?.total_spend).toFixed(2)
                )}
              </Typography.Headline1>
            </div>
            <div className="description__summary-division">
              <Typography.Label className="summary-division__part">
                <span>Compliant:</span> $
                {currencyFormatter(
                  safeNumber(totalSpendData?.compliant_spend).toFixed(2)
                )}
              </Typography.Label>
              <Typography.Label className="summary-division__part">
                <span>Non-compliant:</span> $
                {currencyFormatter(
                  safeNumber(totalSpendData?.non_compliant_spend).toFixed(2)
                )}
              </Typography.Label>
            </div>
          </div>
          <div>
            <div className="chart__container">
              {dataPerMonth.map((dataPart, ind) => (
                <div className="compliant__chart-column" key={ind}>
                  <div>
                    <Tooltip
                      title={
                        <div>
                          Non-compliant <br />
                          Spend: $
                          {currencyFormatter(
                            safeNumber(dataPart?.non_compliant_spend).toFixed(2)
                          )}
                          &nbsp;(
                          {sumPercentageTotal(
                            dataPart?.non_compliant_spend,
                            +dataPart?.non_compliant_spend +
                              +dataPart?.compliant_spend
                          )}
                          %) <br /> <br />
                          Total spend: $
                          {currencyFormatter(
                            safeNumber(
                              +dataPart?.compliant_spend +
                                +dataPart?.non_compliant_spend
                            ).toFixed(2)
                          )}
                        </div>
                      }
                      placement="topLeft"
                    >
                      <div
                        className="compliant__chart-part"
                        style={{
                          backgroundColor: '#FCE3A6',
                          height: `${
                            getHeight(
                              dataPart.non_compliant_spend,
                              maxTotalSpent
                            ) || 0
                          }px`
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        <div>
                          Compliant <br />
                          Spend: $
                          {currencyFormatter(
                            safeNumber(dataPart?.compliant_spend).toFixed(2)
                          )}
                          &nbsp;(
                          {sumPercentageTotal(
                            dataPart.compliant_spend,
                            +dataPart?.non_compliant_spend +
                              +dataPart?.compliant_spend
                          )}
                          %) <br /> <br />
                          Total spend: $
                          {currencyFormatter(
                            safeNumber(
                              +dataPart?.compliant_spend +
                                +dataPart?.non_compliant_spend
                            ).toFixed(2)
                          )}
                        </div>
                      }
                      placement="topLeft"
                    >
                      <div
                        className="compliant__chart-part"
                        style={{
                          backgroundColor: '#73A8D3',
                          height: `${
                            getHeight(
                              dataPart.compliant_spend,
                              maxTotalSpent
                            ) || 0
                          }px`
                        }}
                      />
                    </Tooltip>
                  </div>
                  <Typography.Label className="chart-part__description">
                    {dataPart.month}
                  </Typography.Label>
                </div>
              ))}
            </div>
            <div className="chart__scale">
              {Object.values(totalSpendCounting(maxTotalSpent)).map(
                (part, ind) => {
                  return (
                    <div className="scale__part" key={ind}>
                      <Typography.Label key={ind}>
                        {getCurrencyValue(part).toLowerCase()}
                      </Typography.Label>
                      <span className="scale__line"> </span>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      </>
      <Menu
        mode="horizontal"
        onSelect={(e) => {
          setActiveTab(e.key)
        }}
        defaultSelectedKeys={[activeTab]}
        items={COMPILANCE_TABS as ItemType[]}
      />
      <ComplianceTable
        activeTab={activeTab}
        period={period}
        myContracts={myContracts}
      />
    </Container>
  )
}
