import { AppThunk } from '../../type'

import {
  error,
  finish,
  loading,
  set,
  bulk,
  updateUnreadMessages
} from './slice'

import {
  GetChatListRequestArgs,
  getChatListRequestAsync,
  readChatMessages
} from 'features/ChatList/api'

export const getChatListActionAsync =
  (args: GetChatListRequestArgs): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())

      const { data } = await getChatListRequestAsync(args)

      if (data) {
        if (args?.offset && Number(args.offset) !== 0) {
          dispatch(bulk(data.results))
          return
        }

        dispatch(
          set({
            ...data,
            type: args.type || null,
            search: args.search || null
          })
        )
      }

      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }

export const updateUnreadMessageCount =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())

      await readChatMessages(id)

      dispatch(updateUnreadMessages({ uuid: id }))
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }
