import { deleteToken } from 'firebase/messaging'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from '../Link'
import { routes } from '../../router'
import NavLink from '../NavLink/NavLink'
import { Notifications } from '../../features/Notifications/Notifications'
import { PORTAL_TABS_BOTTOM, PORTAL_TABS_TOP } from '../../constants'
import { ACTIONS, Can } from '../../features/Permission'
import { MouseEvent, FC } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import { logout } from '../../features/Auth/api'
import { LogOutAction } from '../../redux/store/user/slice'
import { deleteDeviceRegistration } from '../../features/Notifications/api'
import { getLocalRefreshToken } from '../../helper/common'
import { getToken } from '../../redux/store/notifications/getters'
import { messaging } from '../../firebase'
import useRouter from 'hooks/useRouter'
import { ReactComponent as LogoPortalNewIcon } from 'assets/svg/LogoPortalNew.svg'
import { ReactComponent as LogOutIcon } from 'assets/svg/LogOut.svg'
import { AsideProps } from './types'

const Aside: FC<AsideProps> = ({ isMobile }) => {
  const token = useSelector(getToken)
  const dispatch = useDispatch()
  const { push } = useRouter()
  const refresh = getLocalRefreshToken() as string
  const renderTopTabs = () =>
    PORTAL_TABS_TOP.map((tab) => (
      <Can key={tab.label} I={ACTIONS.VIEW} a={tab.label}>
        <NavLink label={tab.label} hrefTo={tab.hrefTo} Icon={tab.Icon} />
      </Can>
    ))
  const renderBottomTabs = () =>
    PORTAL_TABS_BOTTOM.map((tab) => (
      <Can key={tab.label} I={ACTIONS.VIEW} a={tab.label}>
        <NavLink label={tab.label} hrefTo={tab.hrefTo} Icon={tab.Icon} />
      </Can>
    ))

  const handleLogout = async (e: MouseEvent) => {
    try {
      e.preventDefault()
      dispatch(setLoading(true))
      if (token) {
        await deleteDeviceRegistration(token)
        await deleteToken(messaging)
      }
      await logout({ refresh })
      dispatch(LogOutAction())
      push(routes.login)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <aside className="container-portal__tabs">
      <div className="container-portal__tabs_top">
        <Link to={routes.portal} children={<LogoPortalNewIcon />} />
        {renderTopTabs()}
      </div>
      <div className="container-portal__tabs_bottom">
        {!isMobile && <Notifications />}
        {renderBottomTabs()}
        <NavLink
          label="Log out"
          hrefTo={routes.login}
          Icon={LogOutIcon}
          onClick={handleLogout}
        />
      </div>
    </aside>
  )
}

export default Aside
