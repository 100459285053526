import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { ITimeline } from 'components/Timelines/types'
import { TDocumentTypeUnion } from 'components/FileUpload/types'
import { setLoading } from 'redux/store/common/slice'
import { getUser } from 'redux/store/user/getters'
import {
  ContractStatusUnion,
  IContractDetails,
  ManualContractCreationRequestData
} from 'features/ContractDetails/types'
import {
  CONTRACT_DETAILS_TABS,
  CONTRACT_STATUS,
  INITIAL_CONTRACT_DETAILS
} from 'features/ContractDetails/constants'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import {
  createContractRequestAsync,
  extendContractDetailsRequestAsync,
  getContractDetailsRequestAsync,
  setupContractDetailsTimeRequestAsync,
  updateContractDetailsRequestAsync,
  updateContractRebatesRequestAsync,
  uploadContractAttachmentsRequestAsync,
  uploadContractDocumentsRequestAsync
} from 'features/ContractDetails/api'
import { useContractDetailsAbility } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { IRenewFinishDatePayload } from 'features/ContractDetails/ExtendContract/types'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { timelinesRequestDataConverter } from 'features/ContractDetails/ContractDetailsTimelines/utils'
import {
  HSAmendmentRequestData,
  IAddHSAmendmentPayload,
  THSAmendmentFile
} from 'features/ContractDetails/HSList/types'
import {
  addHSAmendmentRequestAsync,
  uploadHSAmendmentDocumentRequestAsync
} from 'features/ContractDetails/HSList/api'
import { IRebateConditionsForm } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import { contractDetailsRoute } from 'features/ContractDetails/ContractDetailsRouter/routes'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import { convertDateToRequestFormat } from 'utils/moment'
import { ROLES } from 'features/Permission'
import { CONTRACT_STATUSES } from '../../../constants'
import useRouter from '../../../hooks/useRouter'

type ContextProps = {
  state: {
    details: IContractDetails
    canEditContract: boolean
    canEditRebateConditions: boolean
    canUploadDocuments: boolean
    isVendor: boolean
    canDeclineContract: boolean
    isCreation: boolean
    expiredAt?: string
    isVCAOrVFO?: boolean
    isVCS?: boolean
    isAllResponsibleUsersAdded: boolean
    isCommunity?: boolean
    isCommons?: boolean
  } & TabsReturnState
  actions: {
    handleUploadAttachmentsAsync: (
      files: File[],
      documentType: TDocumentTypeUnion
    ) => Promise<void>
    handleDeleteAttachmentsAsync: (uuid: string) => Promise<void>
    handleUploadContractDocumentsAsync: (
      files: File[],
      documentType: TDocumentTypeUnion
    ) => Promise<void>
    getContractDetailsAsync: () => Promise<void>
    updateContractDetailsStatusAsync: (
      status: ContractStatusUnion
    ) => Promise<void>
    updateContractDetailsAsync: (
      details: Partial<IContractDetails>
    ) => Promise<void>
    createContractAsync: (
      details: Partial<ManualContractCreationRequestData>
    ) => Promise<string | undefined>
    setupContractDetailsTimeAsync: (
      timelines: ITimeline<typeof CONTRACT_STATUS>[]
    ) => Promise<void>
    extendContractDetailsAsync: (
      details: IRenewFinishDatePayload
    ) => Promise<void>
    updateContractRebatesAsync: (
      details: IRebateConditionsForm,
      callback?: Function
    ) => Promise<void>
    uploadHSAmendmentDocumentAsync: (
      file: File
    ) => Promise<THSAmendmentFile | undefined>
    addHealthSystemAsync: (
      hsAmendmentPayload: IAddHSAmendmentPayload,
      successCallback?: Function
    ) => Promise<void>
  } & TabsReturnActions
}

const ContractDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const createRoute = 'create'

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === createRoute ? null : routeId) : null

const ContractDetailsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()
  const { id: routeId, tab } = useParams()
  const user = useSelector(getUser)
  const ability = useContractDetailsAbility()

  const { query } = useRouter()

  const { extendContractPopup, renewFinishDatePopup, contractStepsTimePopup } =
    useContractDetailsPopup()

  const [id, setId] = useState<string | null>(prepareRouteId(routeId))
  const [details, _setDetails] = useState<IContractDetails>(
    INITIAL_CONTRACT_DETAILS
  )

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: CONTRACT_DETAILS_TABS,
    activeTab: tab || CONTRACT_DETAILS_TABS[0].key
  })

  const isCreation = useMemo(
    () =>
      routeId === createRoute &&
      (user.role === ROLES.COMMUNITY_PRESIDENT ||
        user.role === ROLES.VICE_PRESIDENT ||
        user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
        user.role === ROLES.COMMONS_VICE_PRESIDENT ||
        user.role === ROLES.CONTRACT_STEWARD),
    [routeId, user.role]
  )

  const canEditContract = useMemo(
    () =>
      ability.can(ACTIONS.EDIT, contractSubject(SUBJECTS.CONTRACT, details)),
    [ability, details]
  )

  const canEditRebateConditions = useMemo(
    () =>
      ability.can(
        ACTIONS.EDIT,
        contractSubject(SUBJECTS.REBATE_CONDITIONS, details)
      ),
    [ability, details]
  )

  const canUploadDocuments = useMemo(
    () =>
      ability.can(ACTIONS.UPLOAD, contractSubject(SUBJECTS.DOCUMENTS, details)),
    [ability, details]
  )

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])
  const canDeclineContract = useMemo(
    () =>
      isVendor &&
      user.role !== ROLES.VENDOR_ANALYST &&
      user.role !== ROLES.VENDOR_LEGAL,
    [user.vendor]
  )
  const isCommunity = useMemo(() => Boolean(user.community), [user.community])
  const isCommons = useMemo(() => Boolean(user.commons), [user.commons])
  const isVCAOrVFO = useMemo(
    () =>
      Boolean(
        user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
          user.role === ROLES.VENDOR_FINANCE_OFFICER
      ),
    [user.role]
  )
  const isVCS = useMemo(
    () => Boolean(user.role === ROLES.VENDOR_CONTRACT_STEWARD),
    [user.role]
  )

  const expiredAt = useMemo(() => {
    if (
      ability.cannot(ACTIONS.VIEW, contractSubject(SUBJECTS.TIMELINES, details))
    ) {
      return
    }

    const contractTimeline = details.timelines?.find(
      (timeline) => timeline.status === details.status
    )

    return contractTimeline?.expire_at || moment().toISOString()
  }, [ability, details])

  const getContractDetailsAsync = useCallback(async () => {
    if (!id) {
      throw new Error('Contract ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getContractDetailsRequestAsync(id)

      if (response?.data) {
        _setDetails(response?.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const handleDeleteAttachmentsAsync = useCallback(
    async (uuid: string) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateContractDetailsRequestAsync(details.uuid, {
          contract_attachments_ids: details.contract_attachments
            .filter((i) => i.uuid !== uuid)
            ?.map((i) => i.uuid)
        })

        await getContractDetailsAsync()
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      details.uuid,
      dispatch,
      getContractDetailsAsync,
      details.contract_attachments
    ]
  )

  const handleUploadAttachmentsAsync = useCallback(
    async (files: File[], documentType: TDocumentTypeUnion) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()
        formData.append(documentType, files[0], files[0].name)

        await uploadContractAttachmentsRequestAsync({
          contract: details.uuid,
          file: formData.get(documentType)
        })

        await getContractDetailsAsync()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [details.uuid, dispatch, getContractDetailsAsync]
  )

  const handleUploadContractDocumentsAsync = useCallback(
    async (files: File[], documentType: TDocumentTypeUnion) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()
        formData.append(documentType, files[0], files[0].name)

        await uploadContractDocumentsRequestAsync({
          contract: details.uuid,
          file: formData.get(documentType)
        })

        await getContractDetailsAsync()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [details.uuid, dispatch, getContractDetailsAsync]
  )

  const updateContractDetailsStatusAsync = useCallback(
    async (status: ContractStatusUnion) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }
      try {
        dispatch(setLoading(true))

        await updateContractDetailsRequestAsync(id, {
          status
        })
        if (status === CONTRACT_STATUS.OUT_FOR_SIGNATURE) {
          notification.success({
            message: VALIDATION_MESSAGES.SM00901
          })
        } else if (
          status === CONTRACT_STATUS.ACTIVE ||
          status === CONTRACT_STATUS.EXECUTED ||
          status === CONTRACT_STATUS.EXPIRED
        ) {
          notification.success({
            message: VALIDATION_MESSAGES.SM00902
          })
        } else {
          notification.success({
            message: `Contract ${VALIDATION_MESSAGES.SM0009} ${
              CONTRACT_STATUSES[status?.toUpperCase()]?.toLowerCase() || status
            }`
          })
        }

        await getContractDetailsAsync()
      } catch (e: any) {
        console.error(e)
        notification.error({
          message: VALIDATION_MESSAGES.SM0067
        })
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync]
  )

  const extendContractDetailsAsync = useCallback(
    async (data: IRenewFinishDatePayload) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()

        formData.append('contract', id)
        formData.append(
          'file',
          data.extensionDocument.file,
          data.extensionDocument.file.name
        )
        formData.append(
          'finish_date',
          convertDateToRequestFormat(data.newFinishDate)
        )

        await extendContractDetailsRequestAsync(formData)

        await getContractDetailsAsync()

        if (renewFinishDatePopup.state.visible) {
          renewFinishDatePopup.actions.close()
        }

        extendContractPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      id,
      dispatch,
      getContractDetailsAsync,
      renewFinishDatePopup.state.visible,
      renewFinishDatePopup.actions,
      extendContractPopup.actions
    ]
  )

  const createContractAsync = useCallback(
    async (details: Partial<ManualContractCreationRequestData>) => {
      try {
        dispatch(setLoading(true))

        const response = await createContractRequestAsync({
          ...details,
          creator: user.uuid
        })

        return response?.data?.uuid
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, user.uuid]
  )

  const updateContractDetailsAsync = useCallback(
    async (details: Partial<IContractDetails>) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateContractDetailsRequestAsync(id, details)
        notification.success({
          message: VALIDATION_MESSAGES.CONTRACT_HAS_BEEN_SAVED
        })

        await getContractDetailsAsync()
      } catch (e: any) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync]
  )

  const setupContractDetailsTimeAsync = useCallback(
    async (timelines: ITimeline<typeof CONTRACT_STATUS>[]) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      const requestData = timelinesRequestDataConverter(timelines)

      try {
        dispatch(setLoading(true))

        await setupContractDetailsTimeRequestAsync(id, requestData)

        await getContractDetailsAsync()

        contractStepsTimePopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync, contractStepsTimePopup.actions]
  )

  const updateContractRebatesAsync = useCallback(
    async (rebatesForm: IRebateConditionsForm, successCallback?: Function) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateContractRebatesRequestAsync(id, rebatesForm)

        await getContractDetailsAsync()

        notification.success({ message: VALIDATION_MESSAGES.SM0047 })
        if (successCallback) {
          successCallback()
        }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch, getContractDetailsAsync]
  )

  const uploadHSAmendmentDocumentAsync = useCallback(
    async (file: File) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const formData = new FormData()

        formData.append('file', file, file.name)

        const response = await uploadHSAmendmentDocumentRequestAsync(formData)

        return response.data
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch]
  )

  const addHealthSystemAsync = useCallback(
    async (
      hsAmendmentPayload: IAddHSAmendmentPayload,
      successCallback?: Function
    ) => {
      if (!id) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        const requestData: HSAmendmentRequestData = {
          file: hsAmendmentPayload.file?.uuid,
          pricing_effective_date: hsAmendmentPayload.priceEffectiveDate,
          health_systems: hsAmendmentPayload.healthSystemIds
        }

        await addHSAmendmentRequestAsync(id, requestData)

        if (successCallback) {
          successCallback()
        }

        notification.success({
          message:
            hsAmendmentPayload.healthSystemIds.length > 1
              ? VALIDATION_MESSAGES.SM0031_PLURAL
              : VALIDATION_MESSAGES.SM0031
        })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [id, dispatch]
  )

  const _adjustTabsAccessibility = useCallback(() => {
    tabsActions.setTabs(
      CONTRACT_DETAILS_TABS.map((i) => {
        const inCreationDisabled =
          i.key !== contractDetailsRoute.general && isCreation
        const isHSListDanger =
          i.key === contractDetailsRoute.hsList &&
          (query.type === 'health_system' ||
            details.creation_type === 'health_system')

        return {
          ...i,
          disabled: inCreationDisabled,
          danger: isHSListDanger
        }
      })
    )
  }, [isCreation, tabsActions, query, details])

  const isAllResponsibleUsersAdded = useMemo(
    () =>
      isVendor
        ? Boolean(
            details.vendor_responsibles.find(
              (u) => u.role === ROLES.VENDOR_ANALYST
            ) &&
              details.vendor_responsibles.find(
                (u) => u.role === ROLES.VENDOR_LEGAL
              )
          )
        : isCommons
        ? Boolean(
            details.commons_responsibles.find(
              (u) => u.role === ROLES.COMMONS_ANALYST
            ) &&
              details.commons_responsibles.find(
                (u) => u.role === ROLES.COMMONS_LEGAL
              )
          )
        : Boolean(
            details.community_responsibles.find(
              (u) => u.role === ROLES.ANALYST
            ) &&
              details.community_responsibles.find((u) => u.role === ROLES.LEGAL)
          ),
    [
      isVendor,
      isCommons,
      details.community_responsibles,
      details.vendor_responsibles,
      details.commons_responsibles
    ]
  )

  useLayoutEffect(() => {
    _adjustTabsAccessibility()
  }, [_adjustTabsAccessibility])

  useEffect(() => {
    if (!isCreation) {
      getContractDetailsAsync()
    }
  }, [getContractDetailsAsync, isCreation])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  const context = useMemo(
    () => ({
      state: {
        details,
        canEditContract,
        canEditRebateConditions,
        canUploadDocuments,
        isVendor,
        isCreation,
        expiredAt,
        isVCAOrVFO,
        isVCS,
        ...tabsState,
        isAllResponsibleUsersAdded,
        isCommunity,
        isCommons,
        canDeclineContract
      },
      actions: {
        ...tabsActions,
        isVCAOrVFO,
        handleDeleteAttachmentsAsync,
        handleUploadAttachmentsAsync,
        handleUploadContractDocumentsAsync,
        getContractDetailsAsync,
        updateContractDetailsStatusAsync,
        createContractAsync,
        updateContractDetailsAsync,
        setupContractDetailsTimeAsync,
        extendContractDetailsAsync,
        updateContractRebatesAsync,
        uploadHSAmendmentDocumentAsync,
        addHealthSystemAsync
      }
    }),
    [
      details,
      isCommunity,
      canEditContract,
      canEditRebateConditions,
      canUploadDocuments,
      isVendor,
      isCreation,
      expiredAt,
      tabsState,
      tabsActions,
      handleDeleteAttachmentsAsync,
      handleUploadAttachmentsAsync,
      handleUploadContractDocumentsAsync,
      getContractDetailsAsync,
      updateContractDetailsStatusAsync,
      createContractAsync,
      updateContractDetailsAsync,
      setupContractDetailsTimeAsync,
      extendContractDetailsAsync,
      updateContractRebatesAsync,
      uploadHSAmendmentDocumentAsync,
      addHealthSystemAsync,
      isAllResponsibleUsersAdded,
      isVCS,
      canDeclineContract,
      isCommons
    ]
  )

  return (
    <ContractDetailsContext.Provider value={context}>
      {children}
    </ContractDetailsContext.Provider>
  )
}

export const useContractDetailsContext = () =>
  useContext(ContractDetailsContext)

export default ContractDetailsContextProvider
