import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { TRFPDocument } from '../RFP/RFPDetails/types'
import { getPurchaseOrderTemplate } from './api'
import { useDispatch, useSelector } from 'react-redux'
import { TPurchaseOrderResult } from './types'
import { ROLES } from '../Permission'
import { getUser } from '../../redux/store/user/getters'

type ContextProps = {
  state: {
    files: TRFPDocument[]
  }
  actions: {}
}
const PurchaseOrdersContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const PurchaseOrdersContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [files, _setFiles] = useState<TPurchaseOrderResult[]>([])

  useEffect(() => {
    if (user.role !== ROLES.COMMUNITY_PRESIDENT)
      getPurchaseOrderTemplate().then((res) =>
        _setFiles(
          res.data.results.map((i) => ({
            ...i,
            uuid: 'm9bf9db7-c928-4a87-bc07-db4e9cec9176'
          }))
        )
      )
  }, [dispatch])

  const context = useMemo(
    () => ({
      state: {
        files
      },
      actions: {}
    }),
    [files]
  )
  return (
    <PurchaseOrdersContext.Provider value={context}>
      {children}
    </PurchaseOrdersContext.Provider>
  )
}
export const usePurchaseOrdersContext = () => useContext(PurchaseOrdersContext)

export default PurchaseOrdersContextProvider
