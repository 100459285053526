import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  GetVendorContractStewardsArgs,
  IContractDetails,
  ManualContractCreationRequestData,
  SetupContractDetailsTimeRequestData,
  TAvailableOwnersParams,
  TContractUploadFile,
  TEvaluationDocuments
} from 'features/ContractDetails/types'
import { TResponsibleUser, TRFPDocument } from 'features/RFP/RFPDetails/types'
import { IRebateConditionsForm } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import { TUploadPurchaseOrders } from '../PurchaseOrders/types'
import { THSAmendmentFile } from './HSList/types'
import { TUploadedPoDocumentsResponse } from '../CognusRepository/OpportunityAnalysis/SupportInformation/types'
import {
  TClinicalReview,
  TClinicalReviewStakeholderItem
} from '../CognusRepository/OpportunityAnalysis/types'
import { ResponseWithPagination } from '../../constants'

const fetcher = new Fetcher({})

export const getContractDetailsRequestAsync = async (id: string) =>
  fetcher.request<unknown, IContractDetails>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/`,
    method: HTTP_METHODS.GET
  })

export const createContractRequestAsync = async (
  data: Partial<ManualContractCreationRequestData>
) =>
  fetcher.request<Partial<ManualContractCreationRequestData>, IContractDetails>(
    {
      url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.MANUAL_CREATION}`,
      method: HTTP_METHODS.POST,
      data
    }
  )

export const updateContractDetailsRequestAsync = async (
  id: string,
  data: Partial<IContractDetails>
) =>
  fetcher.request<Partial<IContractDetails>, IContractDetails>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/`,
    method: HTTP_METHODS.PATCH,
    data
  })

export const setupContractDetailsTimeRequestAsync = async (
  id: string,
  timelines: SetupContractDetailsTimeRequestData
) =>
  fetcher.request<
    { timelines: SetupContractDetailsTimeRequestData },
    undefined
  >({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.TIMELINES}`,
    method: HTTP_METHODS.POST,
    data: { timelines }
  })

export const uploadContractDocumentsRequestAsync = async (
  data: TContractUploadFile
) =>
  fetcher.request<TContractUploadFile, TRFPDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CONTRACT_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const uploadContractEvaluationDocumentsRequestAsync = async (
  data: TUploadPurchaseOrders
) =>
  fetcher.request<TUploadPurchaseOrders, THSAmendmentFile>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CONTRACT_EVALUATION_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const uploadContractAttachmentsRequestAsync = async (
  data: TContractUploadFile
) =>
  fetcher.request<TContractUploadFile, TRFPDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CONTRACT_ATTACHMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const extendContractDetailsRequestAsync = async (data: FormData) =>
  fetcher.request<FormData, unknown>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CONTRACT_EXTENSION_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    data
  })

export const updateContractRebatesRequestAsync = async (
  id: string,
  data: IRebateConditionsForm
) =>
  fetcher.request<IRebateConditionsForm, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.REBATES}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getVendorsRequestAsync = async () =>
  fetcher.request<undefined, any>({
    url: `${API_DICTIONARY.VENDORS_SHORT}`,
    method: HTTP_METHODS.GET
  })
export const getCommunitiesRequestAsync = async () =>
  fetcher.request<undefined, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.COMMUNITIES}`,
    method: HTTP_METHODS.GET
  })

export const getVendorContractStewardsRequestAsync = async ({
  vendors,
  contract_categories
}: GetVendorContractStewardsArgs) =>
  fetcher.request<undefined, any>({
    url: `${API_DICTIONARY.USERS}${
      API_DICTIONARY.VENDOR_CONTRACT_STEWARDS
    }?limit=0&offset=0&vendors=${vendors.join(
      ','
    )}&contract_categories=${contract_categories.join(',')}`,
    method: HTTP_METHODS.GET
  })

export const getAvailableOwnersRequestAsync = async (
  params?: TAvailableOwnersParams
) =>
  fetcher.request<undefined, any>({
    url: `${API_DICTIONARY.USERS}${API_DICTIONARY.AVAILABLE_OWNERS}`,
    method: HTTP_METHODS.GET,
    params
  })

export const assignOwnerToContractRequestAsync = async (
  id: string,
  owner: string
) =>
  fetcher.request<{ owner: string }, any>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.OWNERS_REASSIGNMENT}`,
    method: HTTP_METHODS.POST,
    data: {
      owner
    }
  })

export const getAvailableResponsibleUsersRequestAsync = async (id: string) =>
  fetcher.request<unknown, TResponsibleUser[]>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.RESPONSIBLE_USERS}`,
    method: HTTP_METHODS.GET
  })

export const acceptContract = async (id: string, data: {}) =>
  fetcher.request<Partial<any>>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.CONTRACT_GROUP}${id}/${API_DICTIONARY.ACCEPT}`,
    method: HTTP_METHODS.PATCH,
    data
  })

export const getClinicalReviewResults = async (id: string) =>
  fetcher.request<unknown, TClinicalReview>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.CONTRACT_GROUP}${id}/${API_DICTIONARY.CLINICAL_REVIEW_STATISTICS}`,
    method: HTTP_METHODS.GET
  })
export const getClinicalReviewStakeholdersResults = async (id: string) =>
  fetcher.request<
    unknown,
    Partial<ResponseWithPagination<TClinicalReviewStakeholderItem>>
  >({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.CONTRACT_GROUP}${id}/${API_DICTIONARY.CLINICAL_REVIEW_STAKEHOLDERS}`,
    method: HTTP_METHODS.GET
  })

export const terminateContract = async (id: string, data: {}) =>
  fetcher.request<Partial<any>>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.CONTRACT_GROUP}${id}/${API_DICTIONARY.TERMINATE}`,
    method: HTTP_METHODS.PATCH,
    data
  })

export const uploadEvaluationDocuments = async (
  uuid: string,
  data: TEvaluationDocuments
) =>
  fetcher.request<TEvaluationDocuments, any>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.EVALUATION_PO_DOCUMENTS}${uuid}/`,
    method: HTTP_METHODS.PATCH,
    data
  })
export const getUploadedEvaluationDocuments = async () =>
  fetcher.request<unknown, TUploadedPoDocumentsResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.EVALUATION_PO_DOCUMENTS}`,
    method: HTTP_METHODS.GET
  })

export const getUploadedEvaluationDocumentsForHealthSystem = async (
  id: string
) =>
  fetcher.request<unknown, TUploadedPoDocumentsResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.EVALUATION_PO_DOCUMENTS}${API_DICTIONARY.HEALTH_SYSTEM}${id}/`,
    method: HTTP_METHODS.GET
  })
