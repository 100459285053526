import { Container } from 'components/Container/Container'
import { VendorsList } from 'features/MyCommunity/Vendors/VendorsList/VendorsList'

export const Vendors = () => {
  return (
    <Container title="Vendors">
      <VendorsList />
    </Container>
  )
}
