import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../constants'
import Fetcher from 'services/Fetcher'
import { CancelToken } from 'axios'
import {
  BadgeCountData,
  DeviceRegistrationData,
  MarkAsReadData,
  NotificationItem
} from './types'

const fetcher = new Fetcher({})

export const getNotificationsList = async (unreadOnly?: boolean) =>
  fetcher.request<unknown, ResponseWithPagination<NotificationItem>>({
    url: `${API_DICTIONARY.NOTIFICATIONS}?platform=web${
      unreadOnly ? '&is_read=false' : ''
    }`,
    method: HTTP_METHODS.GET
  })

export const markAllNotificationsAsRead = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.NOTIFICATIONS}${API_DICTIONARY.MARK_ALL_AS_READ}`,
    method: HTTP_METHODS.PUT
  })

export const markNotificationAsRead = async (
  id: string,
  data: MarkAsReadData
) =>
  fetcher.request<MarkAsReadData, MarkAsReadData>({
    url: `${API_DICTIONARY.NOTIFICATIONS}${API_DICTIONARY.MARK_AS_READ}${id}/`,
    method: HTTP_METHODS.PATCH,
    data
  })

export const initDevice = async (token: string) =>
  fetcher.request<DeviceRegistrationData, DeviceRegistrationData>({
    url: `${API_DICTIONARY.NOTIFICATIONS}${API_DICTIONARY.DEVICES}`,
    method: HTTP_METHODS.POST,
    data: {
      registration_id: token,
      type: 'web'
    }
  })

export const getBadgeCount = async (cancelToken?: CancelToken) =>
  fetcher.request<unknown, BadgeCountData>({
    url: `${API_DICTIONARY.NOTIFICATIONS}${API_DICTIONARY.BADGE_COUNT}?platform=web`,
    method: HTTP_METHODS.GET,
    cancelToken
  })

export const getDevice = async (token: string) =>
  fetcher.request<unknown, BadgeCountData>({
    url: `${API_DICTIONARY.NOTIFICATIONS}${API_DICTIONARY.DEVICES}${token}/`,
    method: HTTP_METHODS.GET,
    data: {
      type: 'web'
    }
  })

export const deleteDeviceRegistration = async (token: string) =>
  fetcher.request<DeviceRegistrationData, DeviceRegistrationData>({
    url: `${API_DICTIONARY.NOTIFICATIONS}${API_DICTIONARY.DEVICES}${token}/`,
    method: HTTP_METHODS.DELETE
  })
