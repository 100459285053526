import './styles.scss'

import { Avatar } from 'components/Avatar/Avatar'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { useChatPopup } from 'features/Chat/Providers/ChatPopupProvider'
import { CHAT_TYPE, ROLES_NAMES } from 'constants/common'
import { getNameInitials, safeString } from 'helper/common'
import {
  IChannelChatDetails,
  IGroupChatDetails,
  IPrivateChatDetails
} from 'redux/store/chatDetails/types'
import { ReactComponent as Search16Icon } from 'assets/svg/Search16.svg'
import { ReactComponent as Back24Icon } from 'assets/svg/ArrowBack16.svg'
import { routes } from '../../../router'
import useRouter from '../../../hooks/useRouter'

const Header = () => {
  const chatContext = useChatContext()
  const { sidebarDrawer } = useChatPopup()
  const { chat } = chatContext.state
  const { navigate } = useRouter()
  const openChatDetails = () => {
    if (chat?.type) {
      sidebarDrawer.actions.open({ type: chat.type })
    }
  }

  const handleClickItem = () => {
    navigate(`${routes.chat}`, { replace: true })
  }

  const handleSearchBar = () => {
    chatContext?.actions.handleIsSearch(!chatContext?.state.isSearch)
  }
  const avatarProps = () => {
    switch (chat?.type) {
      case CHAT_TYPE.PRIVATE:
        const privateChatDetails = chat as IPrivateChatDetails | null

        return {
          name: privateChatDetails?.full_name
            ? getNameInitials(privateChatDetails.full_name)
            : 'PC',
          src: privateChatDetails?.avatar?.file_preview
        }
      case CHAT_TYPE.CHANNEL:
        return { name: 'Ch', isChannel: true }
      case CHAT_TYPE.GROUP:
        return { name: 'GC' }
      default:
        return { name: '' }
    }
  }

  const captionText = () => {
    switch (chat?.type) {
      case CHAT_TYPE.PRIVATE:
        const privateChatDetails = chat as IPrivateChatDetails | null

        return privateChatDetails?.role
          ? ROLES_NAMES[privateChatDetails.role]
          : ''
      case CHAT_TYPE.CHANNEL:
        const channelChatDetails = chat as IChannelChatDetails | null

        return channelChatDetails?.rfp_number
      case CHAT_TYPE.GROUP:
        return `${chat?.members_count} member${
          chat?.members_count !== 1 ? 's' : ''
        }`
    }
  }

  const chatName = () => {
    switch (chat?.type) {
      case CHAT_TYPE.PRIVATE:
        const privateChatDetails = chat as IPrivateChatDetails | null

        return privateChatDetails?.full_name
      case CHAT_TYPE.CHANNEL:
        const channelChatDetails = chat as IChannelChatDetails | null

        return channelChatDetails?.rfp_name
      case CHAT_TYPE.GROUP:
        const groupChatDetails = chat as IGroupChatDetails | null

        return groupChatDetails?.name
    }
  }

  return (
    <div className="chat__header chat-header">
      <div className="row space-between full-width align-center">
        <div className="column">
          <div className="row align-center">
            <div className="chat-header-arrow-back-wrapper mr-10">
              <Back24Icon onClick={handleClickItem} />
            </div>
            <div
              className="mr-12 chat-header-avatar"
              onClick={openChatDetails}
              role="presentation"
            >
              <Avatar {...avatarProps()} />
            </div>
            <div className="column space-between">
              <div className="row">
                <div className="column">
                  <Typography.Headline6
                    className="chat-list-feature-item__name"
                    weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                    onClick={openChatDetails}
                  >
                    {safeString(chatName())}
                  </Typography.Headline6>
                  <Typography.Caption className="chat-list-feature-item__caption">
                    {safeString(captionText())}
                  </Typography.Caption>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="column chat-header-search-icon"
          onClick={handleSearchBar}
        >
          <Search16Icon />
        </div>
      </div>
    </div>
  )
}

export default Header
