import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Status, USER_STATUS_TYPES_TO_CLASSNAME } from 'components/Status'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import { formatUserNameOption } from 'helper/optionsFormatters'
import { fullFormatPhoneNumber } from 'helper/common'
import { getVendorUsersList } from './api'
import {
  USER_STATUSES_OPTIONS,
  VENDOR_ROLES_OPTIONS,
  ROLES_NAMES
} from 'constants/common'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { routes } from 'router'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TVendorUser,
  TVendorUsersColumnsFunc,
  TVendorUsersFilters,
  TVendorUsersFiltersFunc
} from './types'

export const columns = ({
  applyFilters,
  filters,
  vendorId
}: TVendorUsersColumnsFunc): ColumnsType<TVendorUser> => [
  {
    dataIndex: 'first_name',
    key: 'first_name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorUser>
        dataIndex="first_name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        User name
      </Sorter>
    ),
    render: (_, { first_name, last_name, uuid }) => (
      <CellWithSubtitle
        title={
          <Link
            to={`${routes.createUserProfile}/${uuid}`}
            state={{ redirectTab: SETTINGS_TABS[6].key }}
          >
            {first_name} {last_name}
          </Link>
        }
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'role',
    key: 'role',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorUser>
        dataIndex="role"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Role
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TVendorUsersFilters, TVendorUsersFiltersFunc, unknown>
        items={VENDOR_ROLES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="roles"
        {...props}
      />
    ),
    filterIcon: !!filters?.roles?.length ? <FilterApplied16 /> : <Filter16 />,
    render: (text) => ROLES_NAMES[text],
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorUser>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TVendorUsersFilters, TVendorUsersFiltersFunc, unknown>
        items={USER_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
        {status}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorUser>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorUser>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone number
      </Sorter>
    ),
    render: (_, { phone_number }) => fullFormatPhoneNumber(phone_number),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_steward',
    key: 'contract_steward',
    title: 'Contract Steward',
    filterDropdown: (props) => (
      <Dropdown.Filter<TVendorUsersFilters, TVendorUsersFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() =>
          getVendorUsersList(vendorId, {
            params: { roles: ['vendor_contract_steward'] }
          })
        }
        optionFormatter={formatUserNameOption}
        field="contract_stewards"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_steward }) => {
      const text = contract_steward
        .map((i) => `${i.first_name} ${i.last_name}`)
        .join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.contract_stewards?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_categories',
    key: 'contract_categories',
    title: 'Contract Category',
    filterDropdown: (props) => (
      <Dropdown.Filter<TVendorUsersFilters, TVendorUsersFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_categories }) => {
      const text = contract_categories.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  }
]
