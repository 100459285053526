import './style.scss'
import RadioGroup from '../../components/RadioGroup/RadioGroup'
import {
  COGNUS_REPOSITORY_OPTIONS,
  COGNUS_REPOSITORY_OPTIONS_EXTRA
} from './constants'
import { useState } from 'react'
import { AvailableForEvaluation } from './AvailableForEvaluation/AvailableForEvaluation'
import { OpportunityAnalysis } from './OpportunityAnalysis/OpportunityAnalysis'
import CognusRepositoryPopupProvider from './Providers/CognusRepositoryPopupProvider'
import CognusRepositoryActions from './Providers/CognusRepositoryActions'
import { RequestForAnalysis } from './RequestForAnalysis/RequestForAnalysis'
import { InitialClinicalReviewAction } from './InitiateClinicalReview/Action'
export const CognusRepository = () => {
  const [activeSubTab, setActiveSubTab] = useState(
    COGNUS_REPOSITORY_OPTIONS[0].value
  )
  const handleTabs = (e) => {
    setActiveSubTab(e)
  }
  return (
    <CognusRepositoryActions>
      <CognusRepositoryPopupProvider>
        <div className="cognus-repository-container">
          <div className="cognus-repository-container__header row space-between">
            <span className="cognus-repository-container__header__btn row">
              <RadioGroup.Buttons
                options={COGNUS_REPOSITORY_OPTIONS}
                defaultValue={activeSubTab}
                onChange={handleTabs}
              />
              <RadioGroup.Buttons
                className="requests-for-analysis-btn"
                options={COGNUS_REPOSITORY_OPTIONS_EXTRA}
                defaultValue={activeSubTab}
                onChange={handleTabs}
              />
            </span>
            {activeSubTab === COGNUS_REPOSITORY_OPTIONS[1].value && (
              <InitialClinicalReviewAction />
            )}
          </div>
          {activeSubTab === COGNUS_REPOSITORY_OPTIONS[0].value && (
            <AvailableForEvaluation />
          )}
          {activeSubTab === COGNUS_REPOSITORY_OPTIONS[1].value && (
            <OpportunityAnalysis />
          )}
          {activeSubTab === COGNUS_REPOSITORY_OPTIONS_EXTRA[0].value && (
            <RequestForAnalysis />
          )}
        </div>
      </CognusRepositoryPopupProvider>
    </CognusRepositoryActions>
  )
}
