import { useMemo } from 'react'

import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import HSTable from 'features/ContractDetails/HSList/HSTable/HSTable'
import useHSTable from 'features/ContractDetails/HSList/hooks/useHSTable'
import HSTableProvider from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import AddHealthSystemPopup from 'features/ContractDetails/HSList/AddHealthSystem/Popup'
import AddAmendmentPopup from 'features/ContractDetails/HSList/AddAmendment/Popup'
import { getContractHealthSystems } from 'features/ContractDetails/HSList/api'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { columnsShortView as columns } from './HSTable/constants'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'

const HSList = () => {
  const contractDetailsContext = useContractDetailsContext()

  const hsTableProps = useHSTable({ getContractHealthSystems })

  const AddSystem = useMemo(
    () => (
      <Can
        I={ACTIONS.ADD}
        a={contractSubject(
          SUBJECTS.HEALTH_SYSTEM,
          contractDetailsContext?.state?.details
        )}
      >
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={hsTableProps.handleClickAddSystem}
          icon={<Plus16 />}
          upperCase
        >
          {BTN_TXT.ADD_SYSTEM}
        </Button>
      </Can>
    ),
    [contractDetailsContext.state.details, hsTableProps.handleClickAddSystem]
  )

  return (
    <HSTableProvider {...hsTableProps}>
      <div className="contract-hs-list">
        <HSTable Actions={AddSystem} columns={columns} {...hsTableProps} />
      </div>
      <AddHealthSystemPopup />
      <AddAmendmentPopup />
    </HSTableProvider>
  )
}

export default HSList
