import { Form, Input } from 'antd'
import { TMessage } from './types'
import { ReactComponent as AttachmentIcon } from 'assets/svg/Attachment16.svg'
import { FileUpload } from '../../../components/FileUpload/FileUpload'
import {
  ATTACHMENTS_FILE_TYPES,
  DOCUMENT_TYPE
} from '../../../components/FileUpload/constants'
import { validateFileTypes } from '../../../helper/common'
import { useChatContext } from '../Providers/ChatContextProvider'

const Message = ({ propsTextArea }: TMessage) => {
  const { handleUploadFile } = useChatContext().actions
  const { uploadedAttachments } = useChatContext().state
  return (
    <div className="chat-input__message-wrapper">
      <Form.Item name="message" className="chat-input__message">
        <Input.TextArea
          autoSize
          {...propsTextArea}
          placeholder="Type a message"
        />
      </Form.Item>
      {(uploadedAttachments?.length || 0) < 10 && (
        <FileUpload
          documentType={DOCUMENT_TYPE.ATTACHMENTS}
          className="chat-input__message__attachment"
          uploadBtnText={<AttachmentIcon />}
          isChat
          handleUploadFile={handleUploadFile}
          uploadFilesProps={{
            multiple: false,
            maxSize: 5242880,
            onDropAccepted: (file) =>
              handleUploadFile(file, DOCUMENT_TYPE.ATTACHMENTS),
            accept: {
              ...ATTACHMENTS_FILE_TYPES
            },
            validator: (file) => validateFileTypes(ATTACHMENTS_FILE_TYPES, file)
          }}
        />
      )}
    </div>
  )
}

export default Message
