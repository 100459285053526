import cn from 'classnames'
import { Button } from 'antd'
import { TButtonDefault as Props } from './types'
import './button.icon.scss'

const Icon = ({ className, icon, small, ...props }: Props): JSX.Element => (
  <Button
    className={cn('button-icon', 'custom-button', className, { small })}
    {...props}
  >
    {icon}
  </Button>
)

export default Icon
