import { useCallback, useState, FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Status from '../../../../components/Status/Status'
import Typography from '../../../../components/Typography/Typography'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
// import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { setLoading } from '../../../../redux/store/common/slice'
import { fetchHealthSystem } from '../../../HealthSystems/api'
import { getUser } from '../../../../redux/store/user/getters'
import { formatAddress, fullFormatPhoneNumber } from '../../../../helper/common'
import { HEALTH_SYSTEM_STATUSES } from '../../../../constants'
import { HEALTH_SYSTEM_STATUSES_CLASSNAME } from '../../../../components/Status'
import { initialHealthSystemDetails } from '../../../HealthSystemDetails/constants'
import { HealthSystemDetailsProps } from './types'
import { THealthSystemDetails } from '../../../HealthSystemDetails/types'
import './styles.scss'

export const HealthSystemDetails: FC<HealthSystemDetailsProps> = ({ hsId }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [data, setData] = useState<THealthSystemDetails>(
    initialHealthSystemDetails
  )
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const status = data?.status || HEALTH_SYSTEM_STATUSES.ACTIVE

  useEffect(() => {
    getHealthSystemDetails()
  }, [])

  const getHealthSystemDetails = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await fetchHealthSystem(user.community as string, hsId)
      setData(data)
    } finally {
      dispatch(setLoading(false))
    }
  }, [hsId, user.community])

  const onChangeCollapse = () => setIsCollapsed((prev) => !prev)

  return (
    <div className="hs-info">
      <div className="hs-info__general">
        <div className="hs-info__header row align-center">
          <Typography.Headline6>General</Typography.Headline6>
          <Status.Default className={HEALTH_SYSTEM_STATUSES_CLASSNAME[status]}>
            {status}
          </Status.Default>
        </div>
        {!isCollapsed && (
          <div className="hs-info__general-content column">
            <div className="hs-info__grid">
              <FieldWithLabel title="Tax ID">{data?.tax_id}</FieldWithLabel>
              <FieldWithLabel title="Departments">
                {data?.tax_id}
              </FieldWithLabel>
              <FieldWithLabel title="Email">{data?.email}</FieldWithLabel>
              <FieldWithLabel title="Phone number">
                {fullFormatPhoneNumber(data?.phone_number)}
              </FieldWithLabel>
              <FieldWithLabel title="Address">
                {formatAddress(data?.address)}
              </FieldWithLabel>
            </div>
            {/*<IconFullName*/}
            {/*  className="community-info__contact-person column"*/}
            {/*  firstName={communityInfo?.contact_person?.first_name}*/}
            {/*  lastName={communityInfo?.contact_person?.last_name}*/}
            {/*  roleName={ROLES_NAMES[communityInfo?.contact_person?.role]}*/}
            {/*  src={communityInfo?.contact_person?.avatar?.file_preview}*/}
            {/*  uuid={communityInfo?.contact_person?.uuid}*/}
            {/*/>*/}
          </div>
        )}
        <Button
          className="hs-info__show-btn"
          type={BUTTON_TYPES.LINK}
          onClick={onChangeCollapse}
          inline
        >
          {`${isCollapsed ? 'Show more' : 'Hide'} information`}
        </Button>
      </div>
    </div>
  )
}
