import { useState } from 'react'
import { Popover } from 'antd'
import cn from 'classnames'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import { TDescriptionCellProps } from './types'
import './styles.scss'

export const DescriptionCell = ({
  content,
  text,
  className
}: TDescriptionCellProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleSetIsOpen = (open) => setIsOpen(open)
  return content ? (
    <Popover
      className={cn('description-cell', className)}
      trigger="click"
      placement="topLeft"
      content={content}
      visible={isOpen}
      onVisibleChange={handleSetIsOpen}
      title={
        <div className="description-cell__content-header">
          <div
            className="description-cell__close"
            onClick={() => setIsOpen(false)}
          >
            <Close24 />
          </div>
        </div>
      }
    >
      <div className="description-cell__text" onClick={() => setIsOpen(true)}>
        {text}
      </div>
    </Popover>
  ) : (
    <>–</>
  )
}
