import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { IUser } from 'redux/store/user/types'
import {
  Conditions,
  ChatItemAbility,
  ChatItemAbilityBuilder,
  PossibleAbilities
} from 'features/Chat/Abilities/types'
import { ACTIONS, SUBJECTS } from 'features/Chat/Abilities/constants'
import { isInType, isOwnerCondition } from './utils'
import { CHAT_TYPE } from 'constants/common'

const ChatItemAbilityClass = Ability as AbilityClass<ChatItemAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ChatItemAbilityClass)

  defineAnonymousAbilities(builder, user)

  return new Ability(builder.rules) as ChatItemAbility
}

const defineAnonymousAbilities = (builder: ChatItemAbilityBuilder, user) => {
  builder.can([ACTIONS.VIEW], SUBJECTS.CHAT_MEMBERS, {
    ...isInType([CHAT_TYPE.GROUP, CHAT_TYPE.CHANNEL])
  })

  builder.can([ACTIONS.EDIT], SUBJECTS.GROUP_CHAT, isOwnerCondition(user))

  builder.can([ACTIONS.EDIT], SUBJECTS.CHAT_MEMBERS, {
    ...isOwnerCondition(user),
    ...isInType([CHAT_TYPE.GROUP])
  })
}
