import { Button, BUTTON_TYPES } from '../../../components/Button'
import { ReactComponent as Delete16 } from '../../../assets/svg/Delete16.svg'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../../constants'
import Popup from '../../../components/Popup/Popup'
import { Typography } from '../../../components/Typography'
import { useState } from 'react'
import { deletePurchaseOrder } from '../api'
import { notification } from '../../../components/Notification'

type TProps = {
  onFinish: () => void
  selectedRowKeys: string[]
}

const DeleteAction = ({ onFinish, selectedRowKeys }: TProps) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const handleOpenPopUp = () => setIsDeleteOpen(true)
  const handleClosePopUp = () => setIsDeleteOpen(false)

  const deletePurchaseOrdersPopupAction = () => {
    deletePurchaseOrder({ purchase_orders: selectedRowKeys })
      .then(() => {
        notification.success({ message: VALIDATION_MESSAGES.SM0069 })
        onFinish()
      })
      .catch((e: any) => {
        notification.error({ message: e?.data.errors })
        console.error(e)
      })
      .finally(() => handleClosePopUp())
  }
  return (
    <>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        icon={<Delete16 />}
        upperCase
        danger
        onClick={handleOpenPopUp}
      >
        {BTN_TXT.DELETE}
      </Button>
      <Popup
        visible={isDeleteOpen}
        onCancel={handleClosePopUp}
        afterClose={handleClosePopUp}
        title={`Delete purchase order${selectedRowKeys.length > 1 ? 's' : ''}?`}
        width={442}
        footer={
          <>
            <Button
              onClick={handleClosePopUp}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={deletePurchaseOrdersPopupAction}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        {' '}
        <Typography.Body1>
          The removed data will influence the future reports and calculations of
          the rebate and admin fee. <br />
          Delete purchase order?
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default DeleteAction
