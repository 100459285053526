import { useState, useEffect } from 'react'
import cn from 'classnames'
import { TDropdownProps, TDropdownItem } from './types'
import { Input } from 'components/Input'
import { Checkbox } from 'components/Checkbox'
import { Button, BUTTON_TYPES } from 'components/Button'
import { PLACEHOLDERS } from 'constants/txt'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import './dropdown.filter.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { getIsLoading } from '../../redux/store/common/getters'

export const Filter = <FiltersType, ApplyFiltersFunc, SearchFuncType>({
  field,
  className,
  items = [],
  asyncSearch,
  applyFilters,
  appliedFilters,
  searchCallback,
  optionFormatter,
  confirm,
  formatCallback
}: TDropdownProps<FiltersType, ApplyFiltersFunc, SearchFuncType>) => {
  const [searchValue, setSearchValue] = useState('')
  const [itemsList, setItemsList] = useState<TDropdownItem[] | undefined>(items)
  const [options, setOptions] = useState<TDropdownItem[] | undefined>()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [appliedOptions, setAppliedOptions] = useState<string[]>([])

  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    if (asyncSearch) {
      handleFetchOptions()
    }
  }, [])

  useEffect(() => {
    if (searchValue) {
      setOptions(
        itemsList?.filter(
          (i) => i.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        )
      )
    } else {
      setOptions(itemsList)
    }
  }, [searchValue])

  useEffect(() => {
    if (!!appliedFilters && !!appliedFilters[field] && itemsList) {
      setSelectedOptions(appliedFilters[field])
      setAppliedOptions(appliedFilters[field])
      const selectedFilters = itemsList.filter(
        (i) => !!appliedFilters[field].find((j) => j === i.value)
      )
      const optionsList = selectedFilters.concat(
        itemsList.filter(
          (i) => !appliedFilters[field].find((j) => j === i.value)
        )
      )
      setOptions(optionsList)
    }
  }, [appliedFilters, itemsList])

  const handleSelectOption = (option) => {
    const newSelectedOptions = [...selectedOptions]
    if (selectedOptions.find((i) => i === option)) {
      const idx = selectedOptions.findIndex((i) => i === option)
      newSelectedOptions.splice(idx, 1)
    } else {
      newSelectedOptions.push(option)
    }
    setSelectedOptions(newSelectedOptions)
  }

  const handleFetchOptions = () => {
    if (typeof searchCallback === 'function') {
      dispatch(setLoading(true))
      searchCallback()
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }

          const formatter = optionFormatter
            ? optionFormatter
            : (i) => ({
                ...i,
                value: i.uuid,
                label: i.name
              })
          const newItemsList = resp.data.results.map(formatter)
          const formattedList = formatCallback
            ? formatCallback(newItemsList)
            : newItemsList
          setOptions(formattedList)
          setItemsList(formattedList)
        })
        .finally(() => dispatch(setLoading(false)))
    }
  }

  const handleApplyFilters = () => {
    if (typeof applyFilters === 'function') {
      applyFilters(field, selectedOptions)
      confirm({ closeDropdown: true })
      setSearchValue('')
    }
  }

  return !isLoading ? (
    <div className={cn('dropdown-filter', className)}>
      <Input
        propsInput={{
          placeholder: PLACEHOLDERS.SEARCH,
          prefix: <Search16 />,
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value)
        }}
      />
      <div className="dropdown-filter__options">
        {options?.map((i) => (
          <div
            className={cn('dropdown-filter__option', {
              'dropdown-filter__option--applied': !!appliedOptions.find(
                (j) => j === i.value
              )
            })}
            key={i.value}
          >
            <Checkbox
              propsCheckbox={{
                checked: !!selectedOptions.find((j) => j === i.value),
                onChange: () => handleSelectOption(i.value)
              }}
              propsItem={{
                colon: false
              }}
            >
              {i.label}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="dropdown-filter__footer">
        <Button
          type={BUTTON_TYPES.DEFAULT}
          upperCase
          disabled={!selectedOptions.length}
          onClick={() => setSelectedOptions([])}
        >
          reset
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          upperCase
          onClick={handleApplyFilters}
        >
          save
        </Button>
      </div>
    </div>
  ) : null
}
