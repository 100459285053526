import { Container } from 'components/Container/Container'
import './styles.scss'
import { useSelector } from 'react-redux'
import { getCommunityInfo } from '../../redux/store/chapterInfo/getters'
import { PurchaseOrdersTable } from '../../features/PurchaseOrders/PurchaseOrdersTable'
import { AttachmentsList } from '../../components/AttachmentsList/AttachmentsList'
import { usePurchaseOrdersContext } from '../../features/PurchaseOrders/PurchaseOrdersContextProvider'
import { ACTIONS, SUBJECTS, Can } from '../../features/Permission'
import { subject } from '@casl/ability'
import { getUser } from '../../redux/store/user/getters'

export const PurchaseOrdersContainer = () => {
  const communityInfo = useSelector(getCommunityInfo)
  const user = useSelector(getUser)
  const { state } = usePurchaseOrdersContext()
  const { files } = state
  return (
    <Container
      className="purchase-order-container"
      title="Purchase Orders"
      subtitle={communityInfo?.name}
    >
      <Can
        I={ACTIONS.UPLOAD}
        a={subject(SUBJECTS.PURCHASE_ORDERS, { ...user })}
      >
        <AttachmentsList label="Template" files={files || []} disableActions />
      </Can>
      <PurchaseOrdersTable />
    </Container>
  )
}
