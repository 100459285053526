import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { VALIDATION_MESSAGES } from '../../constants'
import { TTimezonesItem } from './types'

const fetcher = new Fetcher({})

export const fetchUserData = async (uuid: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.USERS}${uuid}/`,
    method: HTTP_METHODS.GET
  })

export const updateUser = async (uuid: string, data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.USERS}${uuid}/`,
    method: HTTP_METHODS.PATCH,
    successMessage: `User ${VALIDATION_MESSAGES.SM0009} saved.`,
    data
  })

export const createUser = async (data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.USERS}`,
    method: HTTP_METHODS.POST,
    successMessage: `User ${VALIDATION_MESSAGES.SM0009} created.`,
    data
  })

export const archiveUsers = async (data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.USERS}${API_DICTIONARY.BULK_ARCHIVE}`,
    method: HTTP_METHODS.POST,
    successMessage: `User ${VALIDATION_MESSAGES.SM0009} archived.`,
    data
  })

export const fetchHospitals = async (communityUUID: string, HSUUID: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.HOSPITALS}`,
    method: HTTP_METHODS.GET
  })
export const fetchCommunitiesWithHs = async () =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.COMMONS}${API_DICTIONARY.COMMUNITY_HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET
  })
export const fetchTimeZonesList = async () =>
  fetcher.request<any, TTimezonesItem[]>({
    url: `${API_DICTIONARY.USERS}${API_DICTIONARY.TIMEZONES}`,
    method: HTTP_METHODS.GET
  })
export const fetchCommonsHs = async () =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.COMMONS}${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET
  })

export const fetchDepartments = async (
  communityUUID: string,
  HSUUID: string,
  hospitals?: string
) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.DEPARTMENTS}`,
    method: HTTP_METHODS.GET,
    params: { hospitals }
  })

export const fetchContractCategories = async () =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })

export const getHealthSystem = async (communityUUID: string, HSUUID: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/`,
    method: HTTP_METHODS.GET
  })
