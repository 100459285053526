export const ACTIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT'
}

export const SUBJECTS = {
  CHANNEL_CHAT: 'CHANNEL_CHAT',
  GROUP_CHAT: 'GROUP_CHAT',
  PRIVATE_CHAT: 'PRIVATE_CHAT',
  CHAT_MEMBERS: 'CHAT_MEMBERS'
}
