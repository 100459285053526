import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { TSupportInformationProps, TUploadedPoDocuments } from './types'
import './style.scss'
import { ReactComponent as DownloadIcon } from 'assets/svg/Download.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/Plus16.svg'
import { ReactComponent as Docs16Icon } from 'assets/svg/Docs16.svg'
import { FileUpload } from '../../../../components/FileUpload/FileUpload'
import { BTN_TXT } from '../../../../constants'
import { PRICE_FILE_TYPES } from '../../../../components/FileUpload/constants'
import { validateFileTypes } from '../../../../helper/common'
import { setLoading } from '../../../../redux/store/common/slice'
import { notification } from '../../../../components/Notification'
import { useDispatch } from 'react-redux'
import { BUTTON_TYPES } from '../../../../components/Button'
import { SupportInformationModal } from './SupportInformationModal'
import { useCognusRepositoryPopup } from '../../Providers/CognusRepositoryPopupProvider'
import {
  getUploadedEvaluationDocuments,
  uploadContractEvaluationDocumentsRequestAsync
} from '../../../ContractDetails/api'
import { useEffect, useState } from 'react'
import { THSAmendmentFile } from '../../../ContractDetails/HSList/types'
import { VersionHistory } from '../../VersionHistory/VersionHistory'
import cn from 'classnames'
import classNames from 'classnames'

export const SupportInformation = ({ files }: TSupportInformationProps) => {
  const dispatch = useDispatch()
  const { uploadDocumentsPopup } = useCognusRepositoryPopup()
  const [documents, setDocuments] = useState<THSAmendmentFile>()
  const [uploadedDocuments, setUploadedDocuments] = useState<
    TUploadedPoDocuments[]
  >([])
  const handleUpload = async (files: File[], documentType: string) => {
    try {
      dispatch(setLoading(true))

      const formData = new FormData()
      formData.append(documentType, files[0], files[0].name)

      const result = await uploadContractEvaluationDocumentsRequestAsync({
        file: formData.get(documentType)
      })
      setDocuments(result?.data)
      uploadDocumentsPopup.actions.open()
    } catch (e: any) {
      notification.error({
        message: e?.data.errors ? e?.data.errors : e?.data.file.file
      })
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const getUploaded = async () => {
    try {
      dispatch(setLoading(true))

      const result = await getUploadedEvaluationDocuments()
      setUploadedDocuments(result.data.results)
    } catch (e: any) {
      notification.error({
        message: e?.data.errors ? e?.data.errors : e?.data.file.file
      })
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    getUploaded()
  }, [])

  return (
    <div className="support-information-container">
      <div
        className={cn('support-information-container__download row', {
          withHistory: uploadedDocuments?.length
        })}
      >
        <AttachmentsList
          label="Purchase order"
          files={files || []}
          names={['PO template']}
          icon={<DownloadIcon className="purchase-orders-download" />}
          disableActions
        />
        <div className="support-information-container__upload">
          <div
            className={classNames(
              'support-information-container__upload__history',
              { withMargin: !!uploadedDocuments?.length }
            )}
          >
            <AttachmentsList.WithHistory
              label=""
              icon={<Docs16Icon />}
              versionHistoryButtonText="Show all uploaded PO"
              files={uploadedDocuments}
              hideSeparator={false}
              title="Uploaded purchase orders"
              versionHistory={<VersionHistory documents={uploadedDocuments} />}
            />
          </div>
          <FileUpload
            uploadButtonText={
              uploadedDocuments?.length > 1
                ? BTN_TXT.UPLOAD
                : BTN_TXT.UPLOAD_PURCHASE_ORDER
            }
            uploadBtnText={''}
            btnType={BUTTON_TYPES.GHOST}
            icon={<PlusIcon className="purchase-orders-upload" />}
            documentType="price_file"
            uploadFilesProps={{
              multiple: false,
              maxSize: 5242880,
              onDropAccepted: (file) => handleUpload(file, 'price_file'),
              accept: {
                ...PRICE_FILE_TYPES
              },
              validator: (file) => validateFileTypes(PRICE_FILE_TYPES, file)
            }}
            handleUploadFile={handleUpload}
            tooltipText={''}
          />
        </div>
      </div>
      <SupportInformationModal
        getUploaded={getUploaded}
        documents={documents}
      />
    </div>
  )
}
