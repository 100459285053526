import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { ResponseWithPagination, TChatTypeUnion } from 'constants/types'
import { IChat } from 'redux/store/chats/types'
import { NOTIFICATIONS } from '../../constants'

const fetcher = new Fetcher({})

export type GetChatListRequestArgs = {
  limit?: number
  offset?: number
  search?: string
  type?: TChatTypeUnion
  query?: string
}

export const getChatListRequestAsync = async (args: GetChatListRequestArgs) =>
  fetcher.request<unknown, ResponseWithPagination<IChat>>({
    url: `${API_DICTIONARY.CHATS}?${new URLSearchParams(
      args as Record<string, string>
    ).toString()}`,
    method: HTTP_METHODS.GET
  })

export const getPvpAvailableUsers = async (params) =>
  fetcher.request<unknown, ResponseWithPagination<any>>({
    url: `${API_DICTIONARY.CHATS}${API_DICTIONARY.PVP_AVAILABLE_USERS}`,
    method: HTTP_METHODS.GET,
    params
  })

export const getGroupAvailableUsers = async (params) =>
  fetcher.request<unknown, ResponseWithPagination<any>>({
    url: `${API_DICTIONARY.CHATS}${API_DICTIONARY.GROUP_AVAILABLE_USERS}`,
    method: HTTP_METHODS.GET,
    params
  })

type CreateChatRequestData = {
  name: string
  description?: string
  members: string[]
  type?: string
}

type CreatePvPChatRequestData = {
  description?: string
  members: string[]
  type?: string
}

export const createChatRequest = async (
  data: CreateChatRequestData | CreatePvPChatRequestData
) =>
  fetcher.request<unknown, IChat>({
    url: `${API_DICTIONARY.CHATS}`,
    method: HTTP_METHODS.POST,
    successMessage:
      data.type === 'private'
        ? NOTIFICATIONS.PRIVATE_CHAT_CREATED
        : NOTIFICATIONS.GROUP_CHAT_CREATED,
    data
  })

export const readChatMessages = async (id: string) =>
  fetcher.request<unknown, ResponseWithPagination<any>>({
    url: `${API_DICTIONARY.CHATS}${id}/${API_DICTIONARY.MARK_AS_READ}`,
    method: HTTP_METHODS.POST
  })
