import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { FieldWithLabel } from '../../../components/FieldWithLabel/FieldWithLabel'
import { AttachmentsList } from '../../../components/AttachmentsList/AttachmentsList'
import { ReactComponent as Download16 } from '../../../assets/svg/Download16.svg'
import { BTN_TXT } from '../../../constants'
import {
  ATTACHMENTS_FILE_TYPES,
  DOCUMENT_TYPE,
  QUESTIONS_FILE_TYPES,
  RFP_FILE_TYPES
} from '../../../components/FileUpload/constants'
import { ReactComponent as File16 } from '../../../assets/svg/File16.svg'
import { FileUpload } from '../../../components/FileUpload/FileUpload'
import { validateFileTypes } from '../../../helper/common'
import { useRFPDetailsContext } from './Providers/RFPDetailsContextProvider'
import { TRFPDetailsResponse, TRFPDocument } from './types'
import { useMemo } from 'react'
import cn from 'classnames'

export const FilesUpload = ({
  isViewMode,
  data,
  isVendor,
  communityAttachments
}: {
  isViewMode: boolean
  data: Pick<
    TRFPDetailsResponse,
    'autogenerated_default_documents' | 'default_documents'
  >
  isVendor: boolean
  communityAttachments?: TRFPDocument[]
}) => {
  const { actions } = useRFPDetailsContext()
  const {
    handleUploadFile,
    handleDeleteAttachment,
    handleUploadVendorFiles,
    handleDeleteVendorAttachment
  } = actions

  const handleUpload = isVendor ? handleUploadVendorFiles : handleUploadFile

  const handleDelete = isVendor
    ? handleDeleteVendorAttachment
    : handleDeleteAttachment

  const hasPriceFileDocs = useMemo(
    () =>
      data.default_documents.filter((i) => i.document_type === 'price_file')
        .length > 0,
    [data.default_documents]
  )

  const templatesNumber = useMemo(
    () =>
      data.default_documents.filter((i) => i.document_type === 'document')
        .length,
    [data.default_documents]
  )

  const questionsDocsNumber = useMemo(
    () =>
      data.default_documents.filter((i) => i.document_type === 'questions')
        .length,
    [data.default_documents]
  )

  const showRFPWBSection = useMemo(
    () => !isViewMode || hasPriceFileDocs,
    [isViewMode, hasPriceFileDocs]
  )

  const showIntentToBidLetterSection = useMemo(
    () => !isViewMode || !!templatesNumber,
    [isViewMode, templatesNumber]
  )

  const showGeneralQuestionnaireSection = useMemo(
    () => !isViewMode || !!questionsDocsNumber,
    [isViewMode, questionsDocsNumber]
  )

  const hasAttachments = useMemo(
    () =>
      data.default_documents.filter((i) => i.document_type === 'attachments')
        .length > 0,
    [data.default_documents]
  )

  return (
    <div className="row gap-16">
      <div
        className={cn('rfp-details-general__files column', {
          'mt-32': !isVendor
        })}
      >
        <Typography.Label
          className="rfp-details-general__files__label"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          Required Documentation
        </Typography.Label>
        {showRFPWBSection && (
          <FieldWithLabel
            title="RFP Workbook"
            className="rfp-details-general__files__title mt-12"
          >
            <div className="row rfp-details-general__files__wrapper">
              {!isViewMode && (
                <AttachmentsList
                  label=""
                  className="rfp-details-general__files__wrapper__template"
                  icon={<Download16 className="attachment__icon" />}
                  names={[BTN_TXT.TEMPLATE]}
                  files={data.autogenerated_default_documents.filter(
                    (i) => i.document_type === 'price_file'
                  )}
                  onDelete={handleDelete}
                  disableActions={isViewMode}
                  hideSeparator
                />
              )}
              {isViewMode && hasPriceFileDocs && (
                <AttachmentsList
                  label=""
                  className="rfp-details-general__files__title__file"
                  files={data.default_documents.filter(
                    (i) => i.document_type === 'price_file'
                  )}
                  onDelete={handleDelete}
                  disableActions={true}
                  hideSeparator
                />
              )}
              {!isViewMode && hasPriceFileDocs && (
                <AttachmentsList
                  label={''}
                  className="rfp-details-general__files__title__file"
                  icon={<File16 className="attachment__icon" />}
                  files={data.default_documents.filter(
                    (i) => i.document_type === 'price_file'
                  )}
                  onDelete={handleDelete}
                  disableActions={isViewMode}
                  hideSeparator
                />
              )}
              {!isViewMode && !hasPriceFileDocs && (
                <FileUpload
                  documentType="price_file"
                  className="rfp-details-general__files__wrapper__upload"
                  uploadBtnText={BTN_TXT.UPLOAD_RFP_WORKBOOK}
                  handleUploadFile={handleUpload}
                  uploadFilesProps={{
                    multiple: false,
                    maxSize: 5242880,
                    onDropAccepted: (file) => handleUpload(file, 'price_file'),
                    accept: {
                      ...RFP_FILE_TYPES
                    },
                    validator: (file) => validateFileTypes(RFP_FILE_TYPES, file)
                  }}
                />
              )}
            </div>
          </FieldWithLabel>
        )}

        {showIntentToBidLetterSection && (
          <FieldWithLabel
            title="Intent to Bid Letter"
            className="rfp-details-general__files__title  mt-12"
          >
            <div className="row rfp-details-general__files__wrapper">
              {!isViewMode && (
                <AttachmentsList
                  label=""
                  className="rfp-details-general__files__wrapper__template"
                  icon={<Download16 className="attachment__icon" />}
                  names={[BTN_TXT.TEMPLATE]}
                  files={
                    data?.autogenerated_default_documents.filter(
                      (i) =>
                        i.document_type !== 'price_file' &&
                        i.document_type !== DOCUMENT_TYPE.EXECUTIVE_SUMMARY &&
                        i.document_type !== DOCUMENT_TYPE.QUESTIONS
                    ) || []
                  }
                  disableActions
                />
              )}
              {isViewMode && !templatesNumber && (
                <AttachmentsList
                  label=""
                  className="rfp-details-general__files__wrapper__file"
                  files={
                    data?.autogenerated_default_documents.filter(
                      (i) =>
                        i.document_type !== 'price_file' &&
                        i.document_type !== DOCUMENT_TYPE.EXECUTIVE_SUMMARY &&
                        i.document_type !== DOCUMENT_TYPE.DOCUMENT &&
                        i.document_type !== DOCUMENT_TYPE.QUESTIONS
                    ) || []
                  }
                  disableActions
                />
              )}
              {templatesNumber > 0 && (
                <AttachmentsList
                  label={''}
                  className="rfp-details-general__files__title__file"
                  icon={<File16 className="attachment__icon" />}
                  files={data.default_documents.filter(
                    (i) => i.document_type === 'document'
                  )}
                  onDelete={handleDelete}
                  disableActions={isViewMode}
                  hideSeparator
                />
              )}
              {!isViewMode &&
                (templatesNumber >= 1 || (
                  <FileUpload
                    documentType="document"
                    className="rfp-details-general__files__wrapper__upload"
                    uploadBtnText={BTN_TXT.UPLOAD_INTENT_TO_BID_LETTER}
                    handleUploadFile={handleUpload}
                    uploadFilesProps={{
                      multiple: false,
                      maxSize: 5242880,
                      onDropAccepted: (file) => handleUpload(file, 'document'),
                      accept: {
                        ...RFP_FILE_TYPES
                      },
                      validator: (file) =>
                        validateFileTypes(RFP_FILE_TYPES, file)
                    }}
                  />
                ))}
            </div>
          </FieldWithLabel>
        )}
        {showGeneralQuestionnaireSection && (
          <FieldWithLabel
            title="General Questionnaire"
            className="rfp-details-general__files__title without-border  mt-12"
          >
            <div className="row rfp-details-general__files__wrapper">
              {!isViewMode &&
                data?.autogenerated_default_documents.filter(
                  (i) => i.document_type !== 'questions'
                ).length > 0 && (
                  <AttachmentsList
                    label=""
                    names={[BTN_TXT.TEMPLATE]}
                    className="rfp-details-general__files__wrapper__template"
                    icon={<Download16 className="attachment__icon" />}
                    files={
                      data?.autogenerated_default_documents.filter(
                        (i) => i.document_type === DOCUMENT_TYPE.QUESTIONS
                      ) || []
                    }
                    disableActions
                  />
                )}
              {!isViewMode && questionsDocsNumber > 0 && (
                <AttachmentsList
                  label={''}
                  className="rfp-details-general__files__title__file"
                  icon={<File16 className="attachment__icon" />}
                  files={data.default_documents.filter(
                    (i) => i.document_type === 'questions'
                  )}
                  onDelete={handleDelete}
                  disableActions={isViewMode}
                  hideSeparator
                />
              )}
              {isViewMode && questionsDocsNumber > 0 && (
                <AttachmentsList
                  label={''}
                  className="rfp-details-general__files__title__file"
                  files={data.default_documents.filter(
                    (i) => i.document_type === 'questions'
                  )}
                  onDelete={handleDelete}
                  disableActions
                  hideSeparator
                />
              )}
              {!isViewMode && (
                <>
                  {questionsDocsNumber >= 1 || (
                    <FileUpload
                      documentType="questions"
                      className="rfp-details-general__files__wrapper__upload"
                      uploadBtnText={BTN_TXT.UPLOAD_GENERAL_QUESTIONNAIRE}
                      handleUploadFile={handleUpload}
                      uploadFilesProps={{
                        multiple: false,
                        maxSize: 5242880,
                        onDropAccepted: (file) =>
                          handleUpload(file, 'questions'),
                        accept: {
                          ...QUESTIONS_FILE_TYPES
                        },
                        validator: (file) =>
                          validateFileTypes(QUESTIONS_FILE_TYPES, file)
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </FieldWithLabel>
        )}
        <div className="row gap-24">
          {!!communityAttachments?.length && isVendor && (
            <AttachmentsList
              label="Community Attachments"
              className="mt-16 rfp-details-general__files__title__attachments"
              files={communityAttachments}
              onDelete={handleDelete}
              disableActions
            />
          )}
          <div>
            {(!isViewMode || (isViewMode && isVendor && hasAttachments)) && (
              <AttachmentsList
                label="Attachments (Optional)"
                className="mt-16 rfp-details-general__files__title__attachments"
                icon={!isViewMode ? <File16 className="" /> : undefined}
                files={data.default_documents.filter(
                  (i) => i.document_type === 'attachments'
                )}
                onDelete={handleDelete}
                disableActions={isViewMode}
              />
            )}
            {isViewMode ||
              data.default_documents.filter(
                (i) => i.document_type === 'attachments'
              ).length >= 10 || (
                <FileUpload
                  documentType="attachments"
                  uploadBtnText={BTN_TXT.UPLOAD}
                  handleUploadFile={handleUpload}
                  uploadFilesProps={{
                    multiple: false,
                    maxSize: 5242880,
                    onDropAccepted: (file) => handleUpload(file, 'attachments'),
                    accept: {
                      ...ATTACHMENTS_FILE_TYPES
                    },
                    validator: (file) =>
                      validateFileTypes(ATTACHMENTS_FILE_TYPES, file)
                  }}
                />
              )}
          </div>
        </div>
      </div>
      {isViewMode && !isVendor && hasAttachments && (
        <AttachmentsList
          label="Attachments"
          className="mt-32 rfp-details-general__files__title__attachments"
          files={data.default_documents.filter(
            (i) => i.document_type === 'attachments'
          )}
          onDelete={handleDelete}
          disableActions={isViewMode}
        />
      )}
    </div>
  )
}
