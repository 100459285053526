import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TCommonsUsersResponse, TCommonsUsersRequest } from './types'

const fetcher = new Fetcher({})

export const getCommonsUsersList: TCommonsUsersRequest = async (data) =>
  fetcher.request<unknown, TCommonsUsersResponse>({
    url: `${API_DICTIONARY.COMMONS}${API_DICTIONARY.USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
