import { useCallback, useMemo } from 'react'
import { Form } from 'antd'

import './styles.scss'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useHSTableContext } from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  getContractAvailableHealthSystems,
  getContractAvailableHealthSystemsForCommons
} from 'features/ContractDetails/HSList/api'
import HSTable from 'features/ContractDetails/HSList/HSTable/HSTable'
import useHSTable from 'features/ContractDetails/HSList/hooks/useHSTable'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'
import { columnsFullView as columns } from '../HSTable/constants'

const AddHealthSystem = () => {
  const contractDetailsContext = useContractDetailsContext()
  const { addHealthSystemPopup, addAmendmentPopup } = useContractDetailsPopup()

  const { addHealthSystemAsync } = contractDetailsContext.actions

  const { refreshTableData } = useHSTableContext()
  const user = useSelector(getUser)
  const hsTableProps = useHSTable({
    getContractHealthSystems: user.role.includes('commons')
      ? getContractAvailableHealthSystemsForCommons
      : getContractAvailableHealthSystems
  })

  const disableAddHealthSystemButton = useMemo(
    () => !hsTableProps.selectedRowKeys.length,
    [hsTableProps.selectedRowKeys.length]
  )

  const handleFinishForm = useCallback(async () => {
    if (!hsTableProps.selectedRowKeys.length) {
      return
    }

    const successCallback = async () => {
      await refreshTableData()
      addHealthSystemPopup.actions.close()
    }

    if (hsTableProps.isContractSigned) {
      addAmendmentPopup.actions.open({
        hsTableSelectedRowKeys: hsTableProps.selectedRowKeys
      })
      return
    }

    await addHealthSystemAsync(
      {
        healthSystemIds: hsTableProps.selectedRowKeys.map((item) =>
          item.toString()
        )
      },
      successCallback
    )
  }, [
    addAmendmentPopup.actions,
    addHealthSystemAsync,
    addHealthSystemPopup.actions,
    hsTableProps.isContractSigned,
    hsTableProps.selectedRowKeys,
    refreshTableData
  ])

  const FormActions = useMemo(
    () => (
      <div className="add-health-system-form__actions">
        <Button onClick={addHealthSystemPopup.actions.close} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          form="add-health-system-form-id"
          htmlType="submit"
          upperCase
          disabled={disableAddHealthSystemButton}
        >
          {hsTableProps.isContractSigned ? BTN_TXT.CONTINUE : BTN_TXT.ADD}
        </Button>
      </div>
    ),
    [disableAddHealthSystemButton, addHealthSystemPopup.actions.close]
  )

  return (
    <Form onFinish={handleFinishForm} id="add-health-system-form-id">
      <div className="add-health-system-form__hs-list add-health-system-form__popup ">
        <HSTable
          canSelectRows
          height={360}
          columns={columns}
          {...hsTableProps}
        />
      </div>
      {FormActions}
    </Form>
  )
}

export default AddHealthSystem
