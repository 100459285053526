import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TContractItemsListRequest, TContractItemsListResponse } from './types'
import { VALIDATION_MESSAGES } from 'constants/txt'

const fetcher = new Fetcher({})

export const getContractItems: TContractItemsListRequest = async (data, uuid) =>
  fetcher.request<unknown, TContractItemsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACTING_ITEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const uploadContractItems: TContractItemsListRequest = async (
  data,
  uuid
) =>
  fetcher.request<unknown, TContractItemsListResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${uuid}/${API_DICTIONARY.CONTRACTING_ITEMS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    successMessage: VALIDATION_MESSAGES.SM0044,
    data
  })
