import { useEffect, useRef, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Popup } from 'components/Popup'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { columns } from '../constants'
import { getUser } from 'redux/store/user/getters'
import { useDebounce } from 'hooks/hooks'
import { setLoading } from 'redux/store/common/slice'
import { getAvailableUsers } from './api'
import { updateRFP } from 'features/RFP/RFPDetails/api'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import {
  TRFPHealthSystemUsersData,
  TRFPHealthSystemUsersFilters,
  TRFPHealthSystemUsersParams,
  TRFPHealthSystemUsersRequestData,
  TRFPHealthSystemUsersFiltersFunc
} from '../types'
import { TAddPopup } from './types'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Close16 } from '../../../../assets/svg/Clos16.svg'

export const AddPopup = ({
  isAddPopupOpen,
  setIsAddPopupOpen,
  updateAssignedValues,
  selectedUsers
}: TAddPopup) => {
  const [tableData, setTableData] = useState<TRFPHealthSystemUsersData[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] =
    useState<TRFPHealthSystemUsersRequestData>()
  const [filters, setFilters] = useState<TRFPHealthSystemUsersFilters>({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const [totalNumber, setTotalNumber] = useState(0)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { id } = useParams()
  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (id && isAddPopupOpen) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
    if (!isAddPopupOpen) {
      setFirstLoad(true)
    }
  }, [debouncedSearch, id, isAddPopupOpen])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRFPHealthSystemUsersRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TRFPHealthSystemUsersParams = {
      ...filters
    }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getAvailableUsers(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)
  const handleCloseAddPopup = () => {
    setIsAddPopupOpen(false)
    setSelectedRowKeys([])
  }
  const addUsers = () => {
    dispatch(setLoading(true))
    updateRFP(id as string, {
      health_system_users: [
        ...selectedRowKeys,
        ...selectedUsers.map((i) => i.uuid)
      ]
    })
      .then(() => {
        updateAssignedValues()
        handleCloseAddPopup()
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: TRFPHealthSystemUsersFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  useEffect(() => {
    if (!isAddPopupOpen) setSearchValue('')
  }, [isAddPopupOpen])
  return (
    <Popup
      visible={isAddPopupOpen}
      onCancel={() => setIsAddPopupOpen(false)}
      title="Add users"
      width={1376}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={handleCloseAddPopup}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={addUsers}
            disabled={!selectedRowKeys.length}
            upperCase
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <div className="table-popup">
        <div className="table-wrapper__header">
          {!!selectedRowKeys.length ? (
            <Typography.Body1
              className="table-wrapper__select-title"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
          ) : (
            <Input
              className="allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH,
                prefix: <Search16 />,
                value: searchValue,
                onChange: (e) => setSearchValue(e.target.value)
              }}
            />
          )}
        </div>
        <Table<TRFPHealthSystemUsersData>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            community: user.community as string
          })}
          className="add-hs-users-table"
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          onChangePage={handleChangePageSize}
          onChange={handleTableChange}
          hasSearchOrFilters={true}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectRow
          }}
        />
      </div>
    </Popup>
  )
}
