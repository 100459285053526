import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse as CollapseAntd } from 'antd'
import { Collapse } from '../../../components/Collapse'
import { Input } from '../../../components/Input'
import { CommunityInfo } from '../CommunityInfo/CommunityInfo'
import { HealthSystemDetails } from './HealthSystemDetails/HealthSystemDetails'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { getUser } from '../../../redux/store/user/getters'
import { fetchHealthSystemsList } from 'features/HealthSystems/api'
import { setLoading } from '../../../redux/store/common/slice'
import { useDebounce } from '../../../hooks/hooks'
import { PLACEHOLDERS } from '../../../constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import {
  THealthSystem,
  THealthSystemSearchParams
} from '../../HealthSystems/types'
import './styles.scss'

const { Panel } = CollapseAntd

export const HealthSystems = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [activeKey, setActiveKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [healthSystems, setHealthSystems] = useState<THealthSystem[]>([])
  const currentUser = useSelector(getUser)
  const dispatch = useDispatch()

  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    if (currentUser.community) {
      getHealthSystems({ search: debouncedSearch })
    }
  }, [currentUser.community, debouncedSearch])

  const getHealthSystems = async (params?: THealthSystemSearchParams) => {
    try {
      dispatch(setLoading(true))
      const healthSystems = await fetchHealthSystemsList(
        currentUser.community as string,
        params
      )
      setHealthSystems(healthSystems.data.results)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChange = (id) => setActiveKey(id)

  const onChangeCollapse = () => setIsCollapsed((prev) => !prev)

  return (
    <div className="health-systems-list">
      {!isCollapsed && <CommunityInfo />}
      <Button
        className="health-systems-list__show-btn"
        type={BUTTON_TYPES.LINK}
        onClick={onChangeCollapse}
        inline
      >
        {`${isCollapsed ? 'Show more' : 'Hide'} information`}
      </Button>
      <Input
        className="health-systems-list__search"
        propsInput={{
          placeholder: PLACEHOLDERS.SEARCH_BY_HEALTH_SYSTEM,
          prefix: <Search16 />,
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value)
        }}
      />
      <Collapse activeKey={activeKey} onChange={onChange}>
        {healthSystems.map((i) => (
          <Panel key={i.uuid} header={i.name}>
            <HealthSystemDetails hsId={activeKey} />
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}
