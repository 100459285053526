import './style.scss'
import { useEffect, useState } from 'react'
import { Collapse as CollapseAntd } from 'antd'
import { Collapse } from 'components/Collapse'
import Typography from '../../components/Typography/Typography'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { error } from '../../redux/store/chats/slice'
import { getOpportunityAnalysisCommons } from '../Contracts/api'
import { TRequestsForAnalysisCommonsList } from '../CognusRepository/OpportunityAnalysis/types'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'
const { Panel } = CollapseAntd

export const OpportunityAnalysisCommons = () => {
  const { push } = useRouter()
  const [activeKey, setActiveKey] = useState('')
  const [data, setData] = useState<TRequestsForAnalysisCommonsList[]>([])

  const dispatch = useDispatch()

  const getData = async () => {
    dispatch(setLoading(true))
    try {
      const res = await getOpportunityAnalysisCommons()
      setData(res.data.results)
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    getData()
  }, [])
  const onChange = (uuid) => setActiveKey(uuid)

  const handleRedirect = (uuid: string) => {
    push(routes.contracts + routes.opportunityAnalysis + '/' + uuid)
  }
  return (
    <div className="opportunity-analysis-commons-container">
      <Collapse activeKey={activeKey} onChange={onChange} className="mt-16">
        {data.map((i) => (
          <Panel key={i.community} header={i.community}>
            {i.health_systems.map((hs) => (
              <div
                className="opportunity-analysis-commons-container__community-wrapper row space-between"
                key={hs.health_system}
                onClick={() => handleRedirect(hs.health_system)}
              >
                <Typography.Body1 className="health-system-name">
                  {hs.health_system_name}
                </Typography.Body1>
                <div className="contract-category-wrapper">
                  <Typography.Label>
                    {hs.groups_count + ' '} contract category
                  </Typography.Label>
                </div>
              </div>
            ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}
