import { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { validateMessages } from 'helper/common'
import {
  TCreateGroupChatProps,
  TCreateGroupChatForm,
  TParams
} from './forms.createGroupChat.d'
import './forms.createGroupChat.scss'
import { Chips } from 'components/Chips/Chips'
import { useDebounce } from 'hooks/hooks'
import { useDispatch } from 'react-redux'
import { setLoading } from 'redux/store/common/slice'
import IconNameItem from 'components/IconNameItem/IconNameItem'
import { CHAT_TYPE, ROLES_NAMES } from 'constants/common'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import 'components/Checkbox/checkbox.default.scss'
import { Checkbox } from '../Checkbox'

export const CreateGroupChat = ({
  className,
  onSubmit,
  onCancel,
  getAvailableUsers
}: TCreateGroupChatProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null as any)
  const [searchValue, setSearchValue] = useState('')
  const [users, setUsers] = useState([] as any)
  const [selectedUsers, setSelectedUsers] = useState([] as any)
  const [form] = Form.useForm()
  const debouncedSearch = useDebounce(searchValue, 500)
  const dispatch = useDispatch()

  const getUsers = () => {
    dispatch(setLoading(true))
    const params: TParams = { search: searchValue }
    if (searchValue) {
      params.search = searchValue
    }
    getAvailableUsers(params)
      .then((resp) => setUsers(resp?.data?.results))
      .finally(() => dispatch(setLoading(false)))
  }

  const onSubmitForm = (values) => {
    onSubmit({
      ...values,
      ...{ members: selectedUsers, type: CHAT_TYPE.GROUP }
    })
  }

  useEffect(() => {
    getUsers()
  }, [debouncedSearch])

  const onDeleteUser = (uuid: string) => {
    setSelectedUsers(selectedUsers.filter((itemUuid) => itemUuid !== uuid))
  }

  const onFinishFailed = () => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Form<TCreateGroupChatForm>
      form={form}
      className={cn('create-group-chat_form', className)}
      layout="vertical"
      onFinish={onSubmitForm}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      <div ref={ref}>
        <Input
          propsItem={{
            label: LABELS.CHAT_NAME,
            name: 'name',
            rules: [
              {
                max: 24,
                required: true
              }
            ]
          }}
          propsInput={{
            showCount: true,
            maxLength: 24
          }}
        />
      </div>
      <Input.TextArea
        propsItem={{
          label: LABELS.DESCRIPTION,
          name: 'description',
          rules: [
            {
              max: 250
            }
          ]
        }}
        propsTextArea={{
          showCount: true,
          maxLength: 250
        }}
      />
      <div className="create-group-chat_separator" />
      {!!selectedUsers?.length && (
        <div className="create-group-chat_selected-users">
          {users.map((user) => {
            if (
              selectedUsers.find(
                (selectedUserUUID) => selectedUserUUID === user.uuid
              )
            ) {
              return (
                <Chips
                  className="create-group-chat_chips"
                  closeIcon={<Close16 />}
                  closable
                  children={`${user?.first_name} ${user?.last_name}`}
                  uuid={user?.uuid}
                  key={user?.uuid}
                  onDelete={onDeleteUser}
                />
              )
            }
          })}
        </div>
      )}
      <Input
        propsInput={{
          placeholder: PLACEHOLDERS.SEARCH_USERS1,
          prefix: <Search16 />,
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value)
        }}
      />
      <div className="create-group-chat_users-list_container">
        <Checkbox.Group
          className="create-group-chat_users-list"
          propsGroupCheckbox={{
            value: selectedUsers,
            onChange: setSelectedUsers,
            options: users.map((user) => ({
              value: user.uuid,
              label: (
                <IconNameItem
                  firstName={user.first_name}
                  lastName={user.last_name}
                  src={user.avatar?.file_preview}
                  roleName={ROLES_NAMES[user.role]}
                />
              )
            }))
          }}
        />
      </div>
      <div className="create-group-chat_buttons">
        <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          htmlType="submit"
          disabled={!selectedUsers.length}
          upperCase
        >
          {BTN_TXT.CREATE_CHAT}
        </Button>
      </div>
    </Form>
  )
}
