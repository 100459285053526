import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { setLoading } from 'redux/store/common/slice'
import {
  confirmSmsCode,
  confirmSmsCodeSignUp,
  resendCode
} from 'features/SignPopup/api'
import { BTN_TXT } from 'constants/txt'
import { TFACode, TFormFaCodeForm } from './forms.2FACode.d'
import {
  getLocalConfirmToken,
  getLocalEmail,
  getLocalPass,
  getLocalPhoneNumber,
  safeString,
  setLocalAccessToken,
  setLocalRefreshToken
} from 'helper/common'
import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import AuthCode from 'react-auth-code-input'
import { useEffect, useMemo, useState } from 'react'
import './forms.2FACode.scss'
import Countdown from 'antd/lib/statistic/Countdown'
import { notification } from '../Notification'
import { setUserToken } from 'redux/store/user/slice'
import { faCodeInitialValues } from './constants'

export const FACode: TFACode = ({ className }): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [result, setResult] = useState<string>('')
  const [isResend, setIsResend] = useState(false)
  const [isError, setIsError] = useState(false)

  const countValue = useMemo(() => {
    if (!isResend) {
      return Date.now() + 61 * 1000
    }
  }, [isResend])

  const handleOnChange = (res: string) => {
    setResult(res)
  }

  const phoneNumber = useMemo(() => {
    return getLocalPhoneNumber()
  }, [])
  const handleLogin = async () => {
    dispatch(setLoading(true))
    const token = getLocalConfirmToken()
    if (!!token?.length) {
      await confirmSmsCodeSignUp({
        sms_code: result,
        token: token
      })
        .then((resp) => {
          setLocalRefreshToken(resp?.data?.refresh as string)
          setLocalAccessToken(resp?.data?.access as string)
          dispatch(setUserToken({ token: resp?.data?.access }))
          // push(routes.portal)
          form.resetFields()
        })
        .catch((err) => {
          setIsError(true)
          dispatch(setLoading(false))
          notification.error({ message: err?.data.detail })
        })
    } else {
      const email = getLocalEmail()
      const password = getLocalPass() || ''
      await confirmSmsCode({
        sms_code: result,
        password: safeString(password),
        email: safeString(email)
      })
        .then((resp) => {
          setLocalRefreshToken(resp?.data?.refresh as string)
          setLocalAccessToken(resp?.data?.access as string)
          localStorage.removeItem('password')
          dispatch(setUserToken({ token: resp?.data?.access }))
          form.resetFields()
        })
        .catch(() => {
          dispatch(setLoading(false))
          setIsError(true)
        })
    }
  }

  const handleResendSmsCode = () => {
    const email = getLocalEmail()
    if (email) {
      dispatch(setLoading(true))
      resendCode({ email: email }).finally(() => {
        dispatch(setLoading(false))
        setIsResend(false)
      })
    }
  }

  const handleFinishCountDown = () => {
    setIsResend(true)
  }
  useEffect(() => {
    if (isError) setIsError(false)
    if (result.length === 6) {
      handleLogin()
    }
  }, [result])

  return (
    <Form<TFormFaCodeForm>
      className={cn('forms-sign-in fa-form', className, { error: isError })}
      layout="vertical"
      initialValues={faCodeInitialValues}
      form={form}
    >
      <div className="row mt-32">
        <Typography.HeadlineD4 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Two-factor authentication
        </Typography.HeadlineD4>
      </div>
      <div className="row mt-12">
        <Typography.Body1>
          Enter the 6-digit code from the sms that was just sent to{' '}
          {phoneNumber}:
        </Typography.Body1>
      </div>
      <div className="row mt-24 forms-sign-in__auth-code">
        <AuthCode
          allowedCharacters="numeric"
          inputClassName="fa-code-input"
          onChange={handleOnChange}
        />
      </div>
      <div className="forms-sign-in__resend">
        <Form.Item noStyle shouldUpdate>
          {() => {
            return (
              <div className=" fa-code-form-footer row align-center">
                <Button
                  type={BUTTON_TYPES.GHOST}
                  disabled={!isResend}
                  htmlType="submit"
                  upperCase
                  onClick={handleResendSmsCode}
                >
                  {BTN_TXT.RESEND_CODE}
                </Button>
                {!isResend && (
                  <div className="col fa-count-down">
                    <Countdown
                      format="ss"
                      value={countValue}
                      prefix="in"
                      suffix="seconds"
                      onFinish={handleFinishCountDown}
                    />
                  </div>
                )}
              </div>
            )
          }}
        </Form.Item>
      </div>
    </Form>
  )
}
