import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

import { routes } from 'router'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { ROLES } from 'features/Permission'
import {
  convertDetailsToForm,
  convertFormToDetails,
  convertFormToManualCreation,
  disabledDateSameOrBefore
} from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { IContractDetailsForm } from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/types'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { getUser } from 'redux/store/user/getters'
import useRouter from 'hooks/useRouter'
import { canSeeMyContracts } from 'helper/common'

const useContractDetailsForm = () => {
  const router = useRouter()
  const user = useSelector(getUser)

  const contractDetailsContext = useContractDetailsContext()
  const { assignResponsiblePopup } = useContractDetailsPopup()

  const { details, isCreation } = contractDetailsContext.state
  const { createContractAsync, updateContractDetailsAsync } =
    contractDetailsContext.actions

  const [isChanged, setIsChanged] = useState(false)

  const [form] = Form.useForm<IContractDetailsForm>()

  const _startDate = Form.useWatch('start_date', form)
  const _finishDate = Form.useWatch('finish_date', form)

  const [hasFormChanges, _setHasFormChanges] = useState(false)

  const isFormRequired = useMemo(
    () => !isCreation && details.status !== CONTRACT_STATUS.CONTRACTING,
    [details.status, isCreation]
  )

  const disabledDateSameOrBeforeStartDate = useMemo(
    () => disabledDateSameOrBefore(_startDate),
    [_startDate]
  )

  const finishDateFieldStatus = disabledDateSameOrBeforeStartDate(_finishDate)
    ? ('error' as const)
    : ('' as const)

  const triggerHasFormChanges = useCallback(() => {
    if (!hasFormChanges) {
      _setHasFormChanges(true)
    }
  }, [hasFormChanges])

  const handleFinishForm = useCallback(
    async (values: IContractDetailsForm) => {
      setIsChanged(false)
      if (finishDateFieldStatus === 'error') {
        return
      }
      if (isCreation) {
        const creationDetails = convertFormToManualCreation({
          ...values,
          creation_type: router?.query?.typeCreation
        })

        if (user.role === ROLES.COMMUNITY_PRESIDENT) {
          assignResponsiblePopup.actions.open({ creationDetails })

          return
        }

        const contractUuid = await createContractAsync(creationDetails)

        if (contractUuid) {
          notification.success({
            message: `Contract ${VALIDATION_MESSAGES.SM0009} created`
          })

          router.push(
            `${routes.contracts}${
              canSeeMyContracts(user.role) ? '/my_contracts' : ''
            }/${contractUuid}`
          )
        }

        return
      }

      await updateContractDetailsAsync(convertFormToDetails(values))

      _setHasFormChanges(false)
    },
    [
      finishDateFieldStatus,
      isCreation,
      updateContractDetailsAsync,
      user.role,
      createContractAsync,
      assignResponsiblePopup.actions,
      router
    ]
  )

  const handleCancelForm = useCallback(() => {
    form.setFieldsValue(convertDetailsToForm(details))
    _setHasFormChanges(false)
    history.back()
  }, [details, form])

  const handleChangeForm = useCallback(() => {
    triggerHasFormChanges()
  }, [triggerHasFormChanges])

  useEffect(() => {
    form.setFieldsValue(convertDetailsToForm(details))
  }, [details, form])

  useEffect(() => {
    if (details.status === CONTRACT_STATUS.OUT_FOR_SIGNATURE) {
      form.validateFields()
    }
  }, [details.status, form])

  return {
    form,
    isCreation,
    isFormRequired,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    finishDateFieldStatus,
    disabledDateSameOrBeforeStartDate,
    setIsChanged,
    isChanged
  }
}

export default useContractDetailsForm
