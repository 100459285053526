import './styles.scss'

import { Popup } from 'components/Popup'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import AddHealthSystem from 'features/ContractDetails/HSList/AddHealthSystem/AddHealthSystem'

export default () => {
  const { addHealthSystemPopup } = useContractDetailsPopup()

  return (
    <Popup
      className="add-health-system"
      {...addHealthSystemPopup.state}
      width={1376}
      footer={null}
    >
      <AddHealthSystem />
    </Popup>
  )
}
