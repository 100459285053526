export const RFP_FILE_TYPES = {
  'application/*': [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  'text/*': ['text/csv']
}

export const QUESTIONS_FILE_TYPES = {
  'application/*': [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  'text/*': ['text/csv']
}

export const GENERAL_QUESTIONS_FILE_TYPES = {
  'application/*': [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
  ]
}

export const PRICE_FILE_TYPES = {
  'application/*': [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]
}

export const ATTACHMENTS_FILE_TYPES = {
  'application/*': [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf'
  ],
  'image/*': ['image/png', 'image/jpeg', 'image/jpg'],
  'text/*': ['text/csv']
}
export const IMAGES_FILE_TYPES = {
  'image/*': ['image/png', 'image/jpeg', 'image/jpg']
}

export const CONTRACT_ITEM_LIST_TYPES = {
  'text/*': ['text/csv']
}

export const DOCUMENT_TYPE = {
  TEMPLATE: 'template',
  QUESTIONS: 'questions',
  PRICE_FILE: 'price_file',
  ATTACHMENTS: 'attachments',
  SUMMARY_PRICE_FILE: 'summary_price_file',
  DOCUMENT: 'document',
  EXECUTIVE_SUMMARY: 'executive_summary'
} as const
