import { useEffect, useState } from 'react'
import { getLocalAccessToken } from 'helper/common'
import { fetchUser, LogOutAction } from 'redux/store/user/slice'
import { useDispatch, useSelector } from 'react-redux'
import { ability, updateAbility } from 'features/Permission'
import { getToken, getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { routes } from 'router/Config/config.routes'
import useRouter from 'hooks/useRouter'
import { DEFAULT_PUBLIC_URLS, PUBLIC_URLS } from './constants'
import { fetchCommunityInfo } from 'redux/store/chapterInfo/slice'
import { fetchVendorInfo } from 'redux/store/vendorInfo/slice'
import { TAuthProps } from './types'
import { getVendorInfo } from '../../redux/store/vendorInfo/getters'
export const AuthCheck = ({ children }: TAuthProps): JSX.Element | null => {
  const dispatch = useDispatch()
  const { push, pathname } = useRouter()
  const accessToken = getLocalAccessToken()
  const user = useSelector(getUser)
  const token = useSelector(getToken)
  const [_isUserInitialized, _setIsUserInitialized] = useState(false)

  useEffect(() => {
    dispatch(setLoading(true))
  }, [])
  useEffect(() => {
    if (token || accessToken) {
      dispatch(setLoading(true))
      dispatch(
        fetchUser(
          (data) => {
            if (data?.role.includes('community')) {
              dispatch(fetchCommunityInfo(data?.community))
            }
            if (data?.role.includes('vendor')) {
              dispatch(fetchVendorInfo(data?.vendor))
            }

            if (token) push(routes.portal)
            _setIsUserInitialized(true)
          },
          () => _setIsUserInitialized(true)
        )
      )
    } else {
      _setIsUserInitialized(true)
    }
  }, [accessToken, dispatch, token])

  const vendorInfo = useSelector(getVendorInfo)

  useEffect(() => {
    if (!accessToken && pathname === '/') {
      dispatch(setLoading(false))
      push(routes.landing)
      return
    }
    if (!PUBLIC_URLS.find((i) => i === pathname)) {
      if (!accessToken) {
        dispatch(LogOutAction())
        if (!pathname.includes('login')) push(routes.login)
        dispatch(setLoading(false))
        return
      }
      updateAbility(ability, user, vendorInfo)
    }
    if (DEFAULT_PUBLIC_URLS.find((i) => i === pathname)) {
      dispatch(setLoading(false))
    }
  }, [accessToken, dispatch, pathname, push, user, user.uuid, vendorInfo])

  return _isUserInitialized ? (children as JSX.Element) : null
}
