import { Sorter } from '../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from '../../components/Dropdown'
import { CellWithSubtitle } from '../../components/CellWithSubtitle/CellWithSubtitle'
import { ReactComponent as Filter16 } from '../../assets/svg/Filter16.svg'
import { ColumnsType } from 'antd/es/table'
import {
  TPurchaseOrdersColumnsFunc,
  TPurchaseOrdersRequestData,
  TPurchaseOrdersFiltersFunc,
  TPurchaseOrdersList
} from './types'
import { fetchAllHospitals, fetchHealthSystemsList } from '../HealthSystems/api'
import { ReactComponent as FilterApplied16 } from '../../assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from '../../pages/CRUDUser/api'
import { getVendorsRequestAsync } from '../ContractDetails/api'

export const columns = ({
  applyFilters,
  filters,
  community,
  health_system
}: TPurchaseOrdersColumnsFunc): ColumnsType<TPurchaseOrdersList> => [
  {
    dataIndex: 'health_system',
    key: 'health_system',
    width: '240px',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="health_system"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TPurchaseOrdersRequestData,
        TPurchaseOrdersFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchHealthSystemsList(community)}
        field="health_systems"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { health_system, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={health_system.name} />
    ),
    filterIcon: !!filters?.health_systems?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'hospital',
    key: 'hospital',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="hospital"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Hospital
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TPurchaseOrdersRequestData,
        TPurchaseOrdersFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchAllHospitals(community, health_system)}
        field="hospitals"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { hospital, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={hospital?.name} />
    ),
    filterIcon: !!filters?.hospitals?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_number',
    key: 'contract_number',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="contract_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Contract number
      </Sorter>
    ),
    render: (_, { contract_number, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={contract_number} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'vendor_item_number',
    key: 'vendor_item_number',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="vendor_item_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor item number
      </Sorter>
    ),
    render: (_, { vendor_item_number, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={vendor_item_number} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'description',
    key: 'description',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="description"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Description
      </Sorter>
    ),
    render: (_, { description, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={description} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unspsc_code',
    key: 'unspsc_code',
    width: 220,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="unspsc_code"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        UNSPSC
      </Sorter>
    ),
    render: (_, { unspsc_code, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={unspsc_code} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_category',
    key: 'contract_category',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="contract_category"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Category
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TPurchaseOrdersRequestData,
        TPurchaseOrdersFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_category, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={contract_category.name} />
    ),
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'vendor',
    key: 'vendor',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="vendor"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TPurchaseOrdersRequestData,
        TPurchaseOrdersFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={getVendorsRequestAsync}
        field="vendors"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { vendor, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={vendor.name} />
    ),
    filterIcon: !!filters?.vendors?.length ? <FilterApplied16 /> : <Filter16 />,
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'units',
    key: 'units',
    width: 100,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="units"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        UoM
      </Sorter>
    ),
    render: (_, { units, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={units} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'quantity',
    key: 'quantity',
    width: 100,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="quantity"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Usage
      </Sorter>
    ),
    render: (_, { quantity, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={quantity} />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'price',
    key: 'price',
    width: 140,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="price"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Price
      </Sorter>
    ),
    render: (_, { price, is_disabled }) => (
      <CellWithSubtitle
        className="purchase-orders-table-price-row"
        disabled={is_disabled}
        title={`$${price}`}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'total_cost',
    key: 'total_cost',
    width: 140,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="total_cost"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Total cost
      </Sorter>
    ),
    render: (_, { total_cost, is_disabled }) => (
      <CellWithSubtitle
        className="purchase-orders-table-price-row"
        disabled={is_disabled}
        title={`$${total_cost}`}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'order_date',
    key: 'order_date',
    width: 220,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter
        dataIndex="order_date"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Purchase order date
      </Sorter>
    ),
    render: (_, { order_date, is_disabled }) => (
      <CellWithSubtitle disabled={is_disabled} title={order_date} />
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
