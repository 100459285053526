import { TBidResend } from './types'
import { ReactComponent as WarningIcon } from '../../../assets/svg/WarningIcon.svg'
import Typography from '../../../components/Typography/Typography'
import { capitalizeFirstLetter } from '../../../utils/common'

export const BidResendReason = ({ bidResend }: { bidResend?: TBidResend }) => {
  if (!bidResend) return null

  const { first_name, last_name } = bidResend.creator

  return (
    <div className={'rfp-resend-bid-reason row mt-24 space-between info'}>
      <WarningIcon />
      <div className="rfp-resend-bid-reason__content column">
        <Typography.Body2 className="rfp-resend-bid-reason__content__header">
          The bid has been resent
        </Typography.Body2>
        <Typography.Caption className="rfp-resend-bid-reason__content__text mt-8">
          {capitalizeFirstLetter(first_name)} {capitalizeFirstLetter(last_name)}{' '}
          has resent the bid for the following reason: {bidResend.reason.value}.
        </Typography.Caption>
      </div>
    </div>
  )
}
