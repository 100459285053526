import Default from './select.default'
import Multi from './select.multi'
import DebounceMulti from './select.debounce'
import SelectAutocomplete from './select.autocomplete'
import { DebounceSelectProps, Props } from './types'
import './styles.scss'

const Select = (props: Props) => <Default {...props} />

Select.Multi = Multi
Select.Default = Default
Select.DebounceMulti = <T extends Object>(props: DebounceSelectProps<T>) => (
  <DebounceMulti<T> {...props} />
)
Select.Autocomplete = SelectAutocomplete

export default Select
