import { ReactComponent as ThreeDots } from 'assets/svg/Dots24.svg'
import { Dropdown } from 'antd'
import { MenuItems } from './MenuItems'
import 'components/ThreeDotsMenu/styles.scss'
import './styles.scss'
import cn from 'classnames'
import { useState } from 'react'

export type TProps = {
  uuid: string
  contractCategory: string
  led_by: string
  isClinicalReview: boolean
}

export const Menu = ({
  uuid,
  contractCategory,
  led_by,
  isClinicalReview
}: TProps) => {
  const [visible, setVisible] = useState(false)
  return (
    <Dropdown
      onVisibleChange={() => setVisible(!visible)}
      visible={visible}
      className="menu-items"
      overlay={
        <MenuItems
          setVisible={setVisible}
          uuid={uuid}
          isClinicalReview={isClinicalReview}
          led_by={led_by}
          contractCategory={contractCategory}
        />
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <div className={cn('dots-items')} onClick={(e) => e.stopPropagation()}>
        <ThreeDots />
      </div>
    </Dropdown>
  )
}
