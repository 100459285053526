import { useMemo } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'

export default () => {
  const contractDetailsContext = useContractDetailsContext()
  const { extendContractPopup } = useContractDetailsPopup()

  const { details } = contractDetailsContext.state

  const extendContractButtonContent = useMemo(() => {
    if (details.status === CONTRACT_STATUS.EXPIRED) {
      return BTN_TXT.RENEW_FINISH_DATE
    }

    if (details.contract_extension_documents.length > 0) {
      return BTN_TXT.UPDATE_EXTENSION
    }

    return BTN_TXT.EXTEND_CONTRACT
  }, [details.contract_extension_documents.length, details.status])

  return (
    <Can I={ACTIONS.EXTEND} a={contractSubject(SUBJECTS.CONTRACT, details)}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        onClick={extendContractPopup.actions.open}
        upperCase
      >
        {extendContractButtonContent}
      </Button>
    </Can>
  )
}
