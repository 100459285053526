import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import {
  ACTIONS,
  ROLES,
  SUBJECTS,
  SubjectsUnion,
  Actions,
  AppAbility,
  Conditions
} from 'features/Permission/constants'
import { IUser } from 'redux/store/user/types'

import defineRFPAbilities from 'features/Permission/abilities/rfpAbilities'
import { IVendorDetails } from '../VendorDetails/types'

export const ability = new Ability<[Actions, SubjectsUnion], Conditions>()
export const AppAbilityClass = Ability as AbilityClass<AppAbility>

const defineAbilityFor = (role: string, user: IUser, profileId?: string) => {
  const { can, rules } = new AbilityBuilder(AppAbilityClass)
  can(ACTIONS.VIEW, SUBJECTS.MY_COMMUNITY)

  if (user.uuid === profileId) {
    can(ACTIONS.UPLOAD, SUBJECTS.AVATAR)
    can(ACTIONS.EDIT, SUBJECTS.FORM_SAVINGS_GOAL)
  }

  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD
  ) {
    can(ACTIONS.DISABLED, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
  }
  if (user.uuid !== profileId) {
    if (
      user.role === ROLES.COMMUNITY_PRESIDENT &&
      role === ROLES.COMMUNITY_PRESIDENT
    ) {
      can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
    }

    if (
      user.role === ROLES.VICE_PRESIDENT &&
      (role === ROLES.COMMUNITY_PRESIDENT || role === ROLES.VICE_PRESIDENT)
    ) {
      can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
    }

    if (
      user.role === ROLES.CONTRACT_STEWARD &&
      (role === ROLES.COMMUNITY_PRESIDENT ||
        role === ROLES.VICE_PRESIDENT ||
        role === ROLES.CONTRACT_STEWARD)
    ) {
      can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
    }

    if (
      user.role === ROLES.VENDOR_CONTRACT_STEWARD &&
      (role === ROLES.COMMUNITY_PRESIDENT ||
        role === ROLES.VICE_PRESIDENT ||
        role === ROLES.CONTRACT_STEWARD ||
        role === ROLES.VENDOR_CONTRACT_ADMIN ||
        role === ROLES.VENDOR_CONTRACT_STEWARD ||
        role === ROLES.VENDOR_FINANCE_OFFICER)
    ) {
      can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
    }
  }

  if (
    user.uuid !== profileId &&
    (user.role === ROLES.VENDOR_FINANCE_OFFICER ||
      user.role === ROLES.STAKEHOLDER ||
      user.role === ROLES.INFLUENCER ||
      user.role === ROLES.HOSPITAL_ADMIN ||
      user.role === ROLES.ANALYST ||
      user.role === ROLES.LEGAL ||
      user.role === ROLES.COMMONS_ANALYST ||
      user.role === ROLES.COMMONS_LEGAL ||
      user.role === ROLES.COMMONS_STAKEHOLDER ||
      user.role === ROLES.SALES_REPRESENTATIVE ||
      user.role === ROLES.COMMUNITY_RCA ||
      user.role === ROLES.VENDOR_ANALYST ||
      user.role === ROLES.VENDOR_LEGAL)
  ) {
    can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
  } else if (
    user.role === ROLES.COMMONS_CONTRACT_STEWARD &&
    user.uuid !== profileId &&
    (role === ROLES.COMMONS_VICE_PRESIDENT ||
      role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
      role === ROLES.COMMONS_CONTRACT_STEWARD)
  ) {
    can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
  }

  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.COMMUNITY_RCA ||
    user.role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_LEGAL ||
    user.role === ROLES.COMMONS_ANALYST ||
    user.role === ROLES.COMMONS_STAKEHOLDER
  ) {
    can(ACTIONS.VIEW, SUBJECTS.CONTACT_PERSON)
  }
  if (user.role !== ROLES.VENDOR_FINANCE_OFFICER) {
    can(ACTIONS.ARCHIVE, SUBJECTS.USER)
  }
  if (role === ROLES.VICE_PRESIDENT) {
    // can(ACTIONS.VIEW, SUBJECTS.FORM_COMMUNITY_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
    can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM)
    can(ACTIONS.CRUD, SUBJECTS.HOSPITAL)
    can(ACTIONS.VIEW, SUBJECTS.FORM_SAVINGS_GOAL)
  }
  if (role === ROLES.COMMUNITY_RCA) {
    can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_USERS)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
  }
  if (role === ROLES.CONTRACT_STEWARD) {
    can(ACTIONS.VIEW, SUBJECTS.COMPLIANCE)
    can(ACTIONS.VIEW, SUBJECTS.FORM_COMMUNITY_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_SAVINGS_GOAL)
  }
  if (user.role === ROLES.STAKEHOLDER || user.role === ROLES.INFLUENCER) {
    can(ACTIONS.CRUD, SUBJECTS.ADDITIONAL_INFO_PROFILE)
  }
  if (role === ROLES.STAKEHOLDER || role === ROLES.INFLUENCER) {
    can(ACTIONS.VIEW, SUBJECTS.ADDITIONAL_INFO_PROFILE)
  }
  if (role === ROLES.STAKEHOLDER) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_HOSPITAL_LIST)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
  }
  if (role === ROLES.COMMONS_CLINICAL_COORDINATOR) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST)
  }
  if (
    user.role !== ROLES.STAKEHOLDER &&
    user.role !== ROLES.COMMUNITY_RCA &&
    user.role !== ROLES.LEGAL &&
    user.role !== ROLES.HOSPITAL_ADMIN &&
    user.role !== ROLES.ANALYST &&
    user.role !== ROLES.INFLUENCER
  ) {
    can(ACTIONS.EDIT, SUBJECTS.FORM_HOSPITAL_LIST)
  }
  if (user.role === ROLES.COMMONS_VICE_PRESIDENT) {
    can(ACTIONS.EDIT, SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST)
  }
  if (role === ROLES.HOSPITAL_ADMIN) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HOSPITAL_INPUT)
  }
  if (role === ROLES.ANALYST || role === ROLES.LEGAL) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT)
  }
  if (role === ROLES.INFLUENCER) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HOSPITAL_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_DEPARTMENTS_INPUT)
  }

  if (
    role === ROLES.SALES_REPRESENTATIVE ||
    role === ROLES.VENDOR_LEGAL ||
    role === ROLES.VENDOR_ANALYST
  ) {
    can(ACTIONS.VIEW, SUBJECTS.CONTRACT_STEWARDS_LIST)
  }

  if (
    user.uuid !== profileId &&
    user.role === ROLES.VENDOR_FINANCE_OFFICER &&
    (role === ROLES.SALES_REPRESENTATIVE ||
      role === ROLES.VENDOR_LEGAL ||
      role === ROLES.VENDOR_ANALYST)
  ) {
    can(ACTIONS.VIEW, SUBJECTS.CONTRACT_STEWARDS_LIST_BUTTON)
  }

  if (
    user.role === ROLES.SALES_REPRESENTATIVE ||
    user.role === ROLES.VENDOR_LEGAL ||
    user.role === ROLES.VENDOR_ANALYST
  ) {
    can(ACTIONS.VIEW, SUBJECTS.CONTRACT_STEWARDS_LIST_BUTTON)
  }

  if (
    role === ROLES.VENDOR_CONTRACT_STEWARD ||
    role === ROLES.VENDOR_FINANCE_OFFICER
  ) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT)
  }
  if (
    role === ROLES.COMMONS_STAKEHOLDER ||
    role === ROLES.COMMONS_LEGAL ||
    role === ROLES.COMMONS_CONTRACT_STEWARD ||
    role === ROLES.COMMONS_ANALYST
  ) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT)
  }

  return new Ability(rules)
}

export const updateAbility = (
  passAbility: Ability<[Actions, SubjectsUnion], Conditions>,
  user: IUser,
  vendor?: IVendorDetails | null
) => {
  const ability = new AbilityBuilder(AppAbilityClass)

  const isOwnerCondition = {
    'creator.uuid': user.uuid
  }

  const isNotOwnerCondition = {
    'creator.uuid': {
      $ne: user.uuid
    }
  }

  const isEventParticipant = {
    participants: {
      $elemMatch: { uuid: user.uuid }
    }
  }

  const hospitalFamily =
    user.role === ROLES.HOSPITAL_ADMIN ||
    user.role === ROLES.INFLUENCER ||
    user.role === ROLES.STAKEHOLDER

  const { can, rules } = ability
  if (
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.CRUD, SUBJECTS.RFP)
    can(ACTIONS.VIEW, SUBJECTS.CONTRACT_PIPELINE)
    can(ACTIONS.CREATE, SUBJECTS.CONTRACT_PIPELINE)
  }

  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD
  ) {
    can(ACTIONS.CREATE, SUBJECTS.CONTRACT_PIPELINE)
    can(ACTIONS.EDIT, SUBJECTS.CONTRACT_PIPELINE_LED_BY)
  }

  if (user.role === ROLES.COMMUNITY_PRESIDENT) {
    can(ACTIONS.VIEW, SUBJECTS.COMPLIANCE)
    can(ACTIONS.DOWNLOAD, SUBJECTS.QR_CODE)
    can(ACTIONS.CRUD, ROLES.VICE_PRESIDENT)
    can(ACTIONS.CRUD, ROLES.CONTRACT_STEWARD)
    can(ACTIONS.CRUD, ROLES.ANALYST)
    can(ACTIONS.CRUD, ROLES.LEGAL)
    can(ACTIONS.CRUD, ROLES.STAKEHOLDER)
    can(ACTIONS.CRUD, ROLES.HOSPITAL_ADMIN)
    can(ACTIONS.CRUD, ROLES.INFLUENCER)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEMS)
    can(ACTIONS.VIEW, SUBJECTS.COMMUNITY)
    can(ACTIONS.VIEW, SUBJECTS.COMMUNITY_USERS)
    can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM)
    can(ACTIONS.CRUD, SUBJECTS.HOSPITAL)
    can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM_USERS)
    can(ACTIONS.CRUD, SUBJECTS.HOSPITAL_USERS)
    can(ACTIONS.CRUD, SUBJECTS.RFP)
    can(ACTIONS.CRUD, SUBJECTS.DEPARTMENTS)
    can(ACTIONS.VIEW, SUBJECTS.PURCHASE_ORDERS)
    can(ACTIONS.VIEW, SUBJECTS.FINANCIALS)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (user.role === ROLES.CONTRACT_STEWARD) {
    can(ACTIONS.DOWNLOAD, SUBJECTS.QR_CODE)
  }
  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.HOSPITAL_ADMIN
  ) {
    can(ACTIONS.ADD, SUBJECTS.BANK_ACCOUNT)
  }
  if (user.role === ROLES.VICE_PRESIDENT) {
    can(ACTIONS.VIEW, SUBJECTS.COMPLIANCE)
    can(ACTIONS.DOWNLOAD, SUBJECTS.QR_CODE)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_TAB)
    can(ACTIONS.CRUD, SUBJECTS.HEALTH_SYSTEM)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_USERS)
    can(ACTIONS.CRUD, ROLES.CONTRACT_STEWARD)
    can(ACTIONS.CRUD, ROLES.ANALYST)
    can(ACTIONS.CRUD, ROLES.LEGAL)
    can(ACTIONS.CRUD, SUBJECTS.HOSPITAL)
    can(ACTIONS.CRUD, SUBJECTS.HOSPITAL_USERS)
    can(ACTIONS.CRUD, SUBJECTS.RFP)
    can(ACTIONS.CRUD, SUBJECTS.DEPARTMENTS)
    can(ACTIONS.UPLOAD, SUBJECTS.PURCHASE_ORDERS)
    can(ACTIONS.VIEW, SUBJECTS.PURCHASE_ORDERS)
    can(ACTIONS.VIEW, SUBJECTS.FINANCIALS)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
    can(ACTIONS.VIEW, SUBJECTS.CONTRACT_PIPELINE)
  }
  if (user.role === ROLES.STAKEHOLDER || user.role === ROLES.VENDOR_ANALYST) {
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (user.role === ROLES.STAKEHOLDER) {
    can(ACTIONS.VIEW, SUBJECTS.CLINICAL_REVIEW)
  }

  if (
    user.role == ROLES.VENDOR_CONTRACT_STEWARD ||
    user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.EDIT, SUBJECTS.USER)
  }
  if (user.role === ROLES.CONTRACT_STEWARD) {
    can(ACTIONS.CRUD, ROLES.ANALYST)
    can(ACTIONS.CRUD, ROLES.LEGAL)
    can(ACTIONS.CRUD, SUBJECTS.HOSPITAL_USERS)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_USERS)
    can(ACTIONS.CRUD, SUBJECTS.RFP)
    can(ACTIONS.UPLOAD, SUBJECTS.PURCHASE_ORDERS)
    can(ACTIONS.VIEW, SUBJECTS.PURCHASE_ORDERS)
    can(ACTIONS.VIEW, SUBJECTS.FINANCIALS)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
    can(ACTIONS.VIEW, SUBJECTS.CONTRACT_PIPELINE)
  }
  if (user.role === ROLES.COMMUNITY_RCA) {
    can(ACTIONS.VIEW, SUBJECTS.CHAT)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_USERS)
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_TAB)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (user.role === ROLES.ANALYST || user.role === ROLES.LEGAL) {
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_TAB)
    can(ACTIONS.CRUD, ROLES.LEGAL)
    can(ACTIONS.VIEW, SUBJECTS.FINANCIALS)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (user.role === ROLES.ANALYST) {
    can(ACTIONS.UPLOAD, SUBJECTS.PURCHASE_ORDERS)
    can(ACTIONS.VIEW, SUBJECTS.PURCHASE_ORDERS)
  }
  if (user.role === ROLES.INFLUENCER) {
    can(ACTIONS.VIEW, SUBJECTS.FORM_HEALTH_SYSTEM_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_HOSPITAL_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.FORM_DEPARTMENTS_INPUT)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (user.role === ROLES.HOSPITAL_ADMIN) {
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (
    user.role === ROLES.INFLUENCER ||
    user.role === ROLES.HOSPITAL_ADMIN ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.STAKEHOLDER
  ) {
    can(ACTIONS.VIEW, SUBJECTS.HEALTH_SYSTEM_TAB)
  }
  if (user.role === ROLES.VENDOR_CONTRACT_ADMIN) {
    can(ACTIONS.CRUD, SUBJECTS.VENDOR)
  }
  if (
    user.role.includes('vendor') ||
    user.role === ROLES.SALES_REPRESENTATIVE
  ) {
    can(ACTIONS.VIEW, SUBJECTS.VENDOR)
    if (user.role === ROLES.VENDOR_CONTRACT_STEWARD) {
      if (vendor?.vendor_permission === 'full_access') {
        can(ACTIONS.VIEW, SUBJECTS.VENDOR_USERS)
      }
    } else {
      can(ACTIONS.VIEW, SUBJECTS.VENDOR_USERS)
    }
  }
  if (user.role === ROLES.VENDOR_CONTRACT_ADMIN) {
    can(ACTIONS.CRUD, SUBJECTS.VENDOR_USERS)
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (user.role === ROLES.VENDOR_CONTRACT_STEWARD) {
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)
  }
  if (
    user.role === ROLES.VENDOR_CONTRACT_STEWARD &&
    vendor?.vendor_permission === 'full_access'
  ) {
    can(ACTIONS.CRUD, SUBJECTS.VENDOR_USERS)
  }
  if (
    user.role.includes('vendor') &&
    user.role !== ROLES.VENDOR_CONTRACT_ADMIN
  ) {
    can(ACTIONS.VIEW, SUBJECTS.VENDOR_CONTRACT_ADMIN)
  }

  if (!hospitalFamily) {
    can(ACTIONS.VIEW, SUBJECTS.RESPONSIBLE_USER)
  }

  if (user.role.includes('community') && user.role !== ROLES.COMMUNITY_RCA) {
    if (
      !hospitalFamily &&
      user.role !== ROLES.LEGAL &&
      user.role !== ROLES.ANALYST
    ) {
      can(ACTIONS.VIEW, SUBJECTS.DASHBOARD)
    }

    if (
      user.role !== ROLES.HOSPITAL_ADMIN &&
      user.role !== ROLES.INFLUENCER &&
      user.role !== ROLES.LEGAL
    ) {
      can(ACTIONS.VIEW, SUBJECTS.VENDORS)
    }
    if (!hospitalFamily && user.role !== ROLES.LEGAL) {
      can(ACTIONS.VIEW, SUBJECTS.COMPLIANCE)
    }
  }

  if (
    user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
    user.role === ROLES.VENDOR_LEGAL ||
    user.role === ROLES.VENDOR_ANALYST ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.HOSPITAL_ADMIN ||
    user.role === ROLES.VENDOR_FINANCE_OFFICER
  ) {
    can(ACTIONS.VIEW, SUBJECTS.FINANCIALS)
  }
  if (
    user.role === ROLES.VENDOR_CONTRACT_STEWARD &&
    vendor?.vendor_permission === 'full_access'
  ) {
    can(ACTIONS.VIEW, SUBJECTS.FINANCIALS)
  }
  if (
    user.role === ROLES.COMMONS_LEGAL ||
    user.role === ROLES.COMMONS_ANALYST ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_STAKEHOLDER
  ) {
    can(ACTIONS.VIEW, SUBJECTS.BIDDING)
  }
  if (user.role !== ROLES.SALES_REPRESENTATIVE) {
    if (
      user.role === ROLES.VENDOR_CONTRACT_STEWARD &&
      vendor?.vendor_permission === 'full_access'
    ) {
      can(ACTIONS.VIEW, SUBJECTS.BIDDING)
    } else if (
      user.role !== ROLES.VENDOR_FINANCE_OFFICER &&
      user.role !== ROLES.COMMUNITY_RCA &&
      user.role !== ROLES.VENDOR_CONTRACT_STEWARD &&
      user.role !== ROLES.COMMONS_LEGAL &&
      user.role !== ROLES.COMMONS_STAKEHOLDER &&
      user.role !== ROLES.COMMONS_VICE_PRESIDENT &&
      user.role !== ROLES.COMMONS_CLINICAL_COORDINATOR &&
      user.role !== ROLES.COMMONS_CONTRACT_STEWARD &&
      user.role !== ROLES.COMMONS_ANALYST
    )
      can(ACTIONS.VIEW, SUBJECTS.BIDDING)

    if (!hospitalFamily && user.role !== ROLES.COMMUNITY_RCA) {
      if (user.role === ROLES.VENDOR_CONTRACT_STEWARD) {
        if (vendor?.vendor_permission === 'full_access') {
          can(ACTIONS.VIEW, SUBJECTS.CONTRACTS)
        }
      } else {
        if (user.role !== ROLES.COMMONS_STAKEHOLDER)
          can(ACTIONS.VIEW, SUBJECTS.CONTRACTS)
      }
    }
  }

  can(ACTIONS.VIEW, SUBJECTS.PROFILE)
  can(ACTIONS.VIEW, SUBJECTS.NOTIFICATIONS)
  if (
    user.role !== ROLES.COMMONS_LEGAL &&
    user.role !== ROLES.COMMONS_VICE_PRESIDENT &&
    user.role !== ROLES.COMMONS_STAKEHOLDER &&
    user.role !== ROLES.COMMONS_CLINICAL_COORDINATOR &&
    user.role !== ROLES.COMMONS_CONTRACT_STEWARD &&
    user.role !== ROLES.COMMONS_ANALYST
  ) {
    can(ACTIONS.VIEW, SUBJECTS.CHAT)
  }
  can(ACTIONS.VIEW, SUBJECTS.LOG_OUT)
  can(ACTIONS.VIEW, SUBJECTS.SETTINGS)
  can(ACTIONS.VIEW, SUBJECTS.MY_COMMUNITY)

  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
    user.role === ROLES.VENDOR_CONTRACT_STEWARD ||
    user.role === ROLES.COMMUNITY_RCA ||
    user.role === ROLES.VENDOR_ANALYST ||
    user.role === ROLES.VENDOR_LEGAL ||
    user.role === ROLES.STAKEHOLDER
  )
    can(ACTIONS.VIEW, SUBJECTS.PRODUCT_REQUESTS)

  defineRFPAbilities(ability, user)
  can(ACTIONS.CRUD, SUBJECTS.CALENDAR_EVENT, isOwnerCondition)
  can(ACTIONS.CRUD, SUBJECTS.CALENDAR_EVENT_RESPONSE, {
    ...isEventParticipant,
    ...isNotOwnerCondition
  })

  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.CREATE, SUBJECTS.CONTRACTS)
  }
  if (
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.CRUD, SUBJECTS.COMMONS_USERS)
  }
  if (
    user.role === ROLES.COMMONS_ANALYST ||
    user.role === ROLES.COMMONS_LEGAL ||
    user.role === ROLES.COMMONS_STAKEHOLDER ||
    user.role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    can(ACTIONS.VIEW, SUBJECTS.COMMONS_USERS)
  }

  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.STAKEHOLDER ||
    user.role === ROLES.ANALYST
  ) {
    can(ACTIONS.CREATE, SUBJECTS.VENDORS)
  }

  if (
    user.role === ROLES.COMMUNITY_PRESIDENT ||
    user.role === ROLES.VICE_PRESIDENT
  ) {
    can(ACTIONS.VIEW, SUBJECTS.ACTUAL_SAVINGS_MENU)
  }

  passAbility.update(rules)
}

export default defineAbilityFor
