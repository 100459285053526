import { Store } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import 'antd/dist/antd.min.css'

import { Loader } from './components/Loader'
import ErrorBoundary from 'features/ErrorBoundary/ErrorBoundary'
import AppSocketProvider from 'features/AppSocket/AppSocketProvider'
import { AbilityContext, ability } from 'features/Permission'
import { AuthCheck } from 'features/Auth'
import Logger from 'services/Logger'
import AppRoutes from 'router'
import { ReactElement, useEffect } from 'react'

export type AppProps = {
  store: Store
  logger: Logger
}

const App = ({ store, logger }: AppProps): ReactElement => {
  useEffect(() => {
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--inner-height',
        `${window.innerHeight}px`
      )
    })
  }, [])
  return (
    <AbilityContext.Provider value={ability}>
      <Router>
        <ErrorBoundary logger={logger}>
          <Provider store={store}>
            <AppSocketProvider>
              <AuthCheck>
                <AppRoutes />
              </AuthCheck>
            </AppSocketProvider>
            <Loader />
          </Provider>
        </ErrorBoundary>
      </Router>
    </AbilityContext.Provider>
  )
}

export default App
