import { TAddress } from 'router/types'
import { WEB_STORAGE } from 'constants/common'
import { notification } from 'components/Notification'
import { TOption } from 'components/Select/types'
import { ROLES } from 'features/Permission'
import { VALIDATION_MESSAGES } from 'constants/txt'

export const toCapitalizeChart = (str: string) => {
  const strArr = str.replace(/_/g, ' ').split('')
  strArr[0] = strArr[0].toUpperCase()
  return strArr.join('')
}

export const getCapitalCharName = (first_name: string, last_name: string) => {
  return `${first_name?.charAt(0) || ''}${
    last_name?.charAt(0) || ''
  }`.toUpperCase()
}

export const cleanUpPrivateStorage = () => {
  Object.values(WEB_STORAGE).forEach((item) => {
    if (item === WEB_STORAGE.LAST_PAGE) return
    localStorage.removeItem(item)
    sessionStorage.removeItem(item)
  })
}

export const isTableHasParams = <T>(searchValue: string, filters: T): boolean =>
  !!searchValue || !!Object.values(filters).length

export const getLocalAccessToken = () =>
  localStorage.getItem(WEB_STORAGE.ACCESS_TOKEN)
export const setLocalAccessToken = (accessToken: string) =>
  localStorage.setItem(WEB_STORAGE.ACCESS_TOKEN, accessToken)
export const getLocalRefreshToken = () =>
  localStorage.getItem(WEB_STORAGE.REFRESH_TOKEN)
export const setLocalRefreshToken = (refreshToken: string) =>
  localStorage.setItem(WEB_STORAGE.REFRESH_TOKEN, refreshToken)
export const setLocalEmail = (email: string) =>
  localStorage.setItem(WEB_STORAGE.EMAIL, email)
export const getLocalEmail = () => localStorage.getItem(WEB_STORAGE.EMAIL)
export const setLocalPass = (pass: string) =>
  localStorage.setItem(WEB_STORAGE.PASSWORD, pass)
export const getLocalPass = () => localStorage.getItem(WEB_STORAGE.PASSWORD)
export const setLocalPhoneNumber = (phone: string) =>
  localStorage.setItem(WEB_STORAGE.PHONE_NUMBER, phone)
export const getLocalPhoneNumber = () =>
  localStorage.getItem(WEB_STORAGE.PHONE_NUMBER)
export const setLocalConfirmToken = (token: string) =>
  localStorage.setItem(WEB_STORAGE.LOCAL_TOKEN, token)
export const getLocalConfirmToken = () =>
  localStorage.getItem(WEB_STORAGE.LOCAL_TOKEN)
export const setLocalLastPage = (page: string) =>
  localStorage.setItem(WEB_STORAGE.LAST_PAGE, page)
export const getLocalLastPage = () =>
  localStorage.getItem(WEB_STORAGE.LAST_PAGE)
export const setLocalCalendarView = (page: string) =>
  localStorage.setItem(WEB_STORAGE.CALENDAR_VIEW, page)
export const getLocalCalendarView = () =>
  localStorage.getItem(WEB_STORAGE.CALENDAR_VIEW)

export const intersections = (o1: object, o2: object) =>
  Object.keys(o1).filter({}.hasOwnProperty.bind(o2))

export const formatPhoneNumber = (phoneNumber?: any) =>
  phoneNumber && phoneNumber?.length >= 0
    ? phoneNumber
        .replace('+1', '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')
    : phoneNumber.replace('+1', '')
export const fullFormatPhoneNumber = (phoneNumber?: any) =>
  phoneNumber
    ? phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3 $4')
    : phoneNumber
export const parserPhoneNumber = (phoneNumber?: any) => {
  return phoneNumber?.toString().replace(/\D+/g, '').length !== 10
}

export const formatMoney = (value) =>
  `${Intl.NumberFormat('en-US').format(
    value.replace(/([^\d]*)(\d*(\.\d{0,2})?)(.*)/, '$2')
  )}`

export const formatName = (value?: any) =>
  value
    ? value.replace(/[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/g, '')
    : ''

export const formatPhone = (value?: any) =>
  value ? value.replace(/[^\d ]/g, '') : ''

export const formatAddress = (address?: TAddress) =>
  address && Object.values(address).every((i) => !!i)
    ? `${address.street}
    ${address.city},
    ${address.state}
    ${address.zip_code}`
    : ''

export const validateMessages = {
  default: 'Validation error on field ${label}',
  required: '${label} is required',
  whitespace: '${label} cannot be empty',
  types: {
    email: 'Enter a valid ${name}'
  },
  pattern: {
    mismatch: 'Enter a valid ${label}'
  }
}

export const isEqualObjects = (object1, object2) => {
  const props1 = Object.getOwnPropertyNames(object1)
  const props2 = Object.getOwnPropertyNames(object2)

  if (props1.length !== props2.length) {
    return false
  }

  for (let i = 0; i < props1.length; i += 1) {
    const prop = props1[i]
    const bothAreObjects =
      typeof object1[prop] === 'object' && typeof object2[prop] === 'object'

    if (
      (!bothAreObjects && object1[prop] !== object2[prop]) ||
      (bothAreObjects && !isEqualObjects(object1[prop], object2[prop]))
    ) {
      return false
    }
  }
  return true
}

export const validateFileTypes = (types, file) => {
  let message = ''
  if (
    !Object.values(types)
      .flat()
      .find((i) => file.type === i)
  ) {
    message = VALIDATION_MESSAGES.SM0038
  }
  if (file.size > 5242880) {
    message = 'Wrong file size.'
  }
  if (message) {
    notification.error({ message })
  }
  return null
}

export const sortAscByKey =
  <T>(key: keyof T, reverse = false) =>
  (a, b) =>
    reverse ? b[key] - a[key] : a[key] - b[key]

export const fileNameFormatter = (file) => {
  const res = decodeURI(file?.split('/')[4]?.split('?')[0])
  if (res !== 'undefined') {
    return res
  } else return file
}

export const returnSelectOptions = (
  arr: Array<object>,
  settings: { labelKey?: string; valueKey?: string } = {}
): TOption[] => {
  const { labelKey = 'name', valueKey = 'uuid' } = settings

  if (arr?.length) {
    return arr?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey]
    }))
  }

  return []
}

export const safeString = (value?: string | null) =>
  value ? String(value) : ''

export const safeNumber = (value?: string | number | null) =>
  parseFloat(String(value)) || 0

export const safeCurrency = (
  value?: string | number | null,
  options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD'
  }
) => safeNumber(value).toLocaleString('en-US', options)

export const isIncreasingSequence = (numbers: number[]) => {
  for (let num = 0; num < numbers.length - 1; num++) {
    if (
      numbers[num] >= numbers[num + 1] ||
      Number.isNaN(numbers[num]) ||
      Number.isNaN(numbers[num + 1])
    ) {
      return false
    }
  }

  return true
}

export const groupByField = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const getNameInitials = (fullName: string) => {
  const splitName = fullName.split(' ')
  return splitName[0].charAt(0) + splitName.pop()?.charAt(0)
}

export const getObjValueByQuery = (
  object: object,
  query: string[]
): any | undefined => {
  return query.reduce((obj, prop) => obj && obj[prop], object)
}

export const preventInitialToCorrectFormat = (data: any) => {
  return {
    ...data,
    phone_number: data?.phone_number?.replace('+1', ''),
    timezone_id: data.timezone?.uuid || ''
  }
}

export const canSeeMyContracts = (role: string) => {
  return Boolean(
    role === ROLES.ANALYST ||
      role === ROLES.CONTRACT_STEWARD ||
      role === ROLES.VICE_PRESIDENT ||
      role === ROLES.COMMONS_ANALYST ||
      role === ROLES.COMMONS_CONTRACT_STEWARD ||
      role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
      role === ROLES.COMMONS_VICE_PRESIDENT
  )
}

export const formatFirstLastName = ({
  firstName,
  lastName
}: {
  firstName?: string
  lastName?: string
}) => `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
