import './styles.scss'
import { Default } from './datepicker.default'
import { Filter } from './datepicker.filter'
import { Range } from './datepicker.range'
import { TDatePickerDefault as Props } from './types'

const Datepicker = (props: Props) => <Default {...props} />

Datepicker.Default = Default
Datepicker.Filter = Filter
Datepicker.Range = Range

export default Datepicker
