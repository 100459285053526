import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { ILink } from './types'
import './link.default.scss'

export const Default = ({ className, children, to, ...props }: ILink) => {
  return (
    <NavLink
      className={cn('link-default', className as string)}
      to={to}
      {...props}
    >
      {children}
    </NavLink>
  )
}
