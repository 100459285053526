import { useSelector } from 'react-redux'
import { IconFullName } from '../../HealthSystemDetails/IconFullName'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { getCommunityInfo } from 'redux/store/chapterInfo/getters'
import { ROLES_NAMES } from '../../../constants'
import './styles.scss'

export const CommunityInfo = () => {
  const communityInfo = useSelector(getCommunityInfo)

  return (
    <div className="community-info row space-between">
      <div>
        <Typography.Label
          className="community-info__label"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          Community name
        </Typography.Label>
        <Typography.Body1 className="community-info__community-name">
          {communityInfo.name}
        </Typography.Body1>
      </div>
      <div>
        <IconFullName
          className="community-info__contact-person column"
          firstName={communityInfo?.contact_person?.first_name}
          lastName={communityInfo?.contact_person?.last_name}
          roleName={ROLES_NAMES[communityInfo?.contact_person?.role]}
          src={communityInfo?.contact_person?.avatar?.file_preview}
          uuid={communityInfo?.contact_person?.uuid}
        />
      </div>
    </div>
  )
}
