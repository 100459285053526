import cn from 'classnames'
import Typography from 'components/Typography/Typography'
import './styles.scss'
import { Link } from 'components/Link'
import { routes } from 'router/Config/config.routes'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { Avatar } from '../../components/Avatar/Avatar'
import { TIconFullName } from './types'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'

export const IconFullName = ({
  title,
  firstName,
  lastName,
  src,
  roleName,
  className,
  uuid
}: TIconFullName) => {
  const name = `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
  return (
    <div className={cn('hs-details_icon-name_container', className)}>
      {!!title && (
        <Typography.Label className="hs-details_title">
          {title}
        </Typography.Label>
      )}
      <div className="hs-details_icon-name">
        <Avatar name={name} alt="User avatar" src={src} />
        <div className="hs-details_name-container">
          <Link
            to={`${routes.createUserProfile}/${uuid}`}
            state={{ redirectTab: SETTINGS_TABS[2].key }}
          >
            <Typography.Body1
              className="hs-details_user-name"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              {firstName} {lastName}
            </Typography.Body1>
          </Link>
          {!!roleName && (
            <Typography.Caption className="hs-details_role-name">
              {roleName}
            </Typography.Caption>
          )}
        </div>
      </div>
    </div>
  )
}
