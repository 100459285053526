import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { TGroupDataList } from './types'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import {
  currencyFormatter,
  sumPercentage
} from '../../features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { CellWithSubtitle } from '../../components/CellWithSubtitle/CellWithSubtitle'

export const initialData = {
  compliant_spend: 0,
  non_compliant_spend: 0,
  total_spend: 0
}
export const COMPILANCE_TABS = [
  {
    key: 'contract_category',
    label: 'Categories'
  },
  {
    key: 'vendor',
    label: 'Vendor'
  },
  {
    key: 'health_system',
    label: 'Health System'
  },
  {
    key: 'hospital',
    label: 'Hospitals'
  }
]

export const columns = ({ activeTab }): ColumnsType<TGroupDataList> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TGroupDataList>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        {activeTab
          ?.split('_')
          ?.map((n) => n.replace(/./, (m) => m.toUpperCase()))
          .join(' ')}
      </Sorter>
    ),
    render: (_, { name }) => <CellWithSubtitle title={name} />,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'compliant_spend',
    key: 'compliant_spend',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TGroupDataList>
        dataIndex="compliant_spend"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Compliant, $
      </Sorter>
    ),
    render: (_, { compliant_spend }) => currencyFormatter(compliant_spend || 0),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'non_compliant_spend',
    key: 'non_compliant_spend',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TGroupDataList>
        dataIndex="non_compliant_spend"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Non-compliant, $
      </Sorter>
    ),
    render: (_, { non_compliant_spend }) =>
      currencyFormatter(non_compliant_spend || 0),
    sorter: true,
    showSorterTooltip: false
  },
  {
    title: ' ',
    dataIndex: 'spendGraph',
    key: 'spendGraph',
    width: 556,
    render: (_, { compliant_spend, non_compliant_spend }) => {
      return (
        <div className="table__chart">
          <Tooltip
            title={
              <div>
                Compliant <br />
                Spend: ${currencyFormatter(compliant_spend || 0)} (
                {sumPercentage(compliant_spend, non_compliant_spend)}
                %) <br /> <br />
                Total spend: $
                {currencyFormatter(
                  (+non_compliant_spend + +compliant_spend).toFixed(2)
                )}
              </div>
            }
            placement="topLeft"
          >
            <div
              style={{
                backgroundColor: '#73A8D3',
                height: '24px',
                width: `${
                  (556 * +compliant_spend) /
                  (+compliant_spend + +non_compliant_spend)
                }px`
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <div>
                Non-compliant <br />
                Spend: ${currencyFormatter(non_compliant_spend || 0)} (
                {sumPercentage(non_compliant_spend, compliant_spend)}
                %) <br /> <br />
                Total spend: $
                {currencyFormatter(
                  (+non_compliant_spend + +compliant_spend).toFixed(2)
                )}
              </div>
            }
            placement="topLeft"
          >
            <div
              style={{
                backgroundColor: '#FCE3A6',
                height: '24px',
                width: `${
                  (556 * +non_compliant_spend) /
                  (+compliant_spend + +non_compliant_spend)
                }px`
              }}
            />
          </Tooltip>
        </div>
      )
    }
  }
]
