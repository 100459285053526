import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Link } from 'components/Link'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { routes } from 'router'
import {
  TVendorList,
  TVendorListColumnsFunc,
  TVendorsListFilters,
  TVendorsListFiltersFunc
} from './types'
import { Dropdown } from '../../../../components/Dropdown'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'

export const VENDOR_ACCESS_OPTIONS = [
  {
    label: 'Guest',
    value: 'guest'
  },
  {
    label: 'Full access',
    value: 'full_access'
  }
]

export const columns = ({
  applyFilters,
  filters
}: TVendorListColumnsFunc): ColumnsType<TVendorList> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorList>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor
      </Sorter>
    ),
    render: (_, { name, uuid }) => (
      <CellWithSubtitle
        title={
          <Link to={`${routes.myCommunity}/vendor-details/${uuid}`}>
            {name}
          </Link>
        }
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorList>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone number
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'vendor_permission',
    key: 'vendor_permission',
    width: 220,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorList>
        dataIndex="vendor_permission"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor permission
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TVendorsListFilters, TVendorsListFiltersFunc, unknown>
        items={VENDOR_ACCESS_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="vendor_permissions"
        {...props}
      />
    ),
    render: (_, { vendor_permission }) =>
      VENDOR_ACCESS_OPTIONS.find((i) => i.value === vendor_permission)?.label,
    filterIcon: !!filters?.vendor_permissions?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'address_string',
    key: 'address_string',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorList>
        dataIndex="address_string"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Address
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_admin',
    key: 'contract_admin',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TVendorList>
        dataIndex="contract_admin"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Contract Admin
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
