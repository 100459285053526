import { useCallback } from 'react'

import { routes } from 'router'
import { LABELS } from 'constants/txt'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import useRouter from 'hooks/useRouter'

const AdditionalInfoPreview = () => {
  const router = useRouter()

  const contractDetailsContext = useContractDetailsContext()

  const { details, isVendor } = contractDetailsContext.state

  const handleClickRfpLink = useCallback(() => {
    router.push(`${routes.bidding}${routes.rfp}/${details.rfp}`)
  }, [details.rfp, router])

  const handleClickPrsLink = useCallback(() => {
    router.push(`${routes.productRequests}/${details.prs}`)
  }, [details.prs, router])

  return (
    <div className="contract-details__grid-preview">
      {details.creation_type === 'health_system' &&
        !!details?.health_systems?.length && (
          <FieldWithLabel
            title={LABELS.HEALTH_SYSTEM}
            className="contract-details__preview-item"
          >
            <Typography.Body1>
              {details?.health_systems[0]?.name}
            </Typography.Body1>
          </FieldWithLabel>
        )}
      {isVendor ? (
        <FieldWithLabel
          title={LABELS.COMMUNITY}
          className="contract-details__preview-item"
        >
          <Typography.Body1>{details.community.name}</Typography.Body1>
        </FieldWithLabel>
      ) : (
        <FieldWithLabel
          title={LABELS.VENDOR}
          className="contract-details__preview-item"
        >
          <Typography.Body1>{details.vendor.name}</Typography.Body1>
        </FieldWithLabel>
      )}
      <FieldWithLabel
        title={LABELS.CONTRACT_CATEGORY}
        className="contract-details__preview-item"
      >
        <Typography.Body1>{details.contract_category_name}</Typography.Body1>
      </FieldWithLabel>
      {details?.rfp_name && (
        <FieldWithLabel
          title="Initial RFP"
          className="contract-details__preview-item"
        >
          <Button type={BUTTON_TYPES.LINK} onClick={handleClickRfpLink}>
            {details.rfp_name}
          </Button>
        </FieldWithLabel>
      )}
      {details?.prs_name && (
        <FieldWithLabel
          title="Initial PRS"
          className="contract-details__preview-item"
        >
          <Button type={BUTTON_TYPES.LINK} onClick={handleClickPrsLink}>
            {details.prs_name}
          </Button>
        </FieldWithLabel>
      )}
    </div>
  )
}

export default AdditionalInfoPreview
