import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse as CollapseAntd, Form } from 'antd'
import { Button, BUTTON_TYPES } from 'components/Button'
import {
  Typography,
  TYPOGRAPHY_WEIGHT,
  TYPOGRAPHY_ALIGNMENT
} from 'components/Typography'
import { Popup } from 'components/Popup'
import { Forms } from 'components/Forms'
import { Collapse } from 'components/Collapse'
import { HealthSystemDetails } from 'features/HealthSystemDetails/HealthSystemDetails'
import { setLoading } from 'redux/store/common/slice'
import { getUser } from 'redux/store/user/getters'
import { getHealthSystemsList } from 'redux/store/healthSystemsList/getters'
import { fetchHealthSystemsList } from 'redux/store/healthSystemsList/slice'
import { ACTIONS, SUBJECTS, Can } from 'features/Permission'
import { BTN_TXT } from 'constants/txt'
import { ReactComponent as Hospital } from 'assets/svg/Hospital.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import './styles.scss'
import usePopup from '../../hooks/usePopup'

const { Panel } = CollapseAntd

export const HealthSystems = () => {
  const [activeKey, setActiveKey] = useState('')
  const [openAddEditHealthSystem, setOpenAddEditHealthSystem] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const healthSystems = useSelector(getHealthSystemsList)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const discardAddHealthSystemPopup = usePopup()
  useEffect(() => {
    if (user.community) {
      getHealthSystems()
    }
  }, [user.community])
  const getHealthSystems = () => {
    dispatch(setLoading(true))
    dispatch(fetchHealthSystemsList(user.community as string)).finally(() => {
      dispatch(setLoading(false))
      setFirstLoad(false)
    })
  }
  const onChange = (uuid) => setActiveKey(uuid)
  const discardAddHealthSystem = () => {
    setOpenAddEditHealthSystem(false)
    form.resetFields()
  }
  const handleCloseAddEditHealthSystem = () => {
    if (form.isFieldsTouched()) {
      discardAddHealthSystemPopup.actions.open()

      return
    }
    discardAddHealthSystem()
  }

  const handleSubmitDiscardAddHealthSystemPopup = () => {
    discardAddHealthSystem()
    discardAddHealthSystemPopup.actions.close()
  }

  return (
    <div className="health-systems">
      {firstLoad || !!healthSystems.length ? (
        <>
          <Can I={ACTIONS.CRUD} a={SUBJECTS.HEALTH_SYSTEM}>
            <Button
              onClick={() => setOpenAddEditHealthSystem(true)}
              type={BUTTON_TYPES.PRIMARY}
              icon={<Plus16 />}
              className="health-systems__create-btn"
              upperCase
            >
              {BTN_TXT.ADD_HEALTH_SYSTEM}
            </Button>
          </Can>
          <Collapse activeKey={activeKey} onChange={onChange}>
            {healthSystems.map((i) => (
              <Panel key={i.uuid} header={i.name}>
                <HealthSystemDetails
                  hsId={activeKey}
                  getHealthSystems={getHealthSystems}
                  isCommunity
                />
              </Panel>
            ))}
          </Collapse>
        </>
      ) : (
        <div className="health-systems--empty">
          <Hospital />
          <Typography.Body1
            weight={TYPOGRAPHY_WEIGHT.BOLD}
            align={TYPOGRAPHY_ALIGNMENT.CENTER}
          >
            No health systems created.
            <br />
            Please add one at least to be able to work
          </Typography.Body1>
          <Can I={ACTIONS.CRUD} a={SUBJECTS.HEALTH_SYSTEM}>
            <Button
              onClick={() => setOpenAddEditHealthSystem(true)}
              type={BUTTON_TYPES.PRIMARY}
              icon={<Plus16 />}
              upperCase
            >
              {BTN_TXT.ADD_HEALTH_SYSTEM}
            </Button>
          </Can>
        </div>
      )}
      <Popup
        visible={openAddEditHealthSystem}
        onCancel={handleCloseAddEditHealthSystem}
        title="Add Health System"
        width={910}
        footer={
          <>
            <Button
              onClick={handleCloseAddEditHealthSystem}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              htmlType="submit"
              form="add-edit-health-system-form"
              upperCase
            >
              {BTN_TXT.ADD}
            </Button>
          </>
        }
      >
        <Forms.AddEditHealthSystem
          form={form}
          callback={() => {
            getHealthSystems()
            setOpenAddEditHealthSystem(false)
          }}
        />
      </Popup>
      <Popup.Discard
        visible={discardAddHealthSystemPopup.state.visible}
        onCancel={discardAddHealthSystemPopup.actions.close}
        onSubmit={handleSubmitDiscardAddHealthSystemPopup}
      />
    </div>
  )
}
