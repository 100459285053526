import { Table } from '../../components/Table'
import { columns } from './constants'
import {
  TPurchaseOrdersFiltersFunc,
  TPurchaseOrdersList,
  TPurchaseOrdersRequestData
} from './types'
import './styles.scss'
import { ability, ACTIONS, Can, SUBJECTS } from 'features/Permission'
import DeleteAction from './PurchaseOrdersActions/DeleteAction'
import EditAction from './PurchaseOrdersActions/EditAction'
import { useEffect, useRef, useState } from 'react'
import { DEFAULT_PAGE } from '../../components/Table/constants'
import moment from 'moment'
import { useDebounce } from '../../hooks/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { convertDateToRequestFormat } from '../../utils/moment'
import { getPurchaseOrders } from './api'
import { PLACEHOLDERS } from '../../constants'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { Input } from '../../components/Input'
import { ReactComponent as Search16 } from '../../assets/svg/Search16.svg'
import { PurchaseOrdersUpload } from './PurchaseOrdersActions'
import { getUser } from '../../redux/store/user/getters'
import { subject } from '@casl/ability'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import { RangeValue } from '../../pages/Compliance/types'
import Datepicker from '../../components/Datepicker/Datepicker'
import { timelineDateFormat } from '../../components/Timelines/constants'

export const PurchaseOrdersTable = () => {
  const [tableData, setTableData] = useState<TPurchaseOrdersList[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<TPurchaseOrdersList[]>([])
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [totalNumber, setTotalNumber] = useState(0)
  const [sortParams, setSortParams] = useState<TPurchaseOrdersRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const user = useSelector(getUser)
  const debouncedSearch = useDebounce(searchValue, 500)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [filters, setFilters] = useState<TPurchaseOrdersRequestData>({})

  const [period, setPeriod] = useState<RangeValue>([
    moment().startOf('year'),
    moment()
  ])

  const dispatch = useDispatch()

  const applyFilters: TPurchaseOrdersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    if (!firstLoad) {
      getTableData({ ...filters })
    }
  }, [period])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TPurchaseOrdersRequestData) => {
    dispatch(setLoading(true))
    const params = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (period && period[0] && period[1]) {
      params.date_from = convertDateToRequestFormat(period[0])
      params.date_to = convertDateToRequestFormat(period[1])
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    getPurchaseOrders({ params })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSearch = (val: string) => setSearchValue(val)

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const onSearchChangeValue = (e) => {
    handleSearch(e.target.value)
  }

  const onFinish = () => {
    setSelectedRowKeys([])
    setSelectedRows([])
    getTableData({ ...filters })
  }

  return (
    <div className="table-wrapper purchase-orders">
      <Typography.Caption
        className="datepicker__label"
        weight={TYPOGRAPHY_WEIGHT.MEDIUM}
      >
        Period
      </Typography.Caption>
      <Datepicker.Range
        propsRangeDate={{
          picker: 'date',
          format: timelineDateFormat,
          defaultValue: [moment().startOf('year'), moment()],
          onChange: (value) => setPeriod(value)
        }}
      />
      <div className="table-wrapper__header">
        {!!selectedRows.length ? (
          <>
            <Typography.Body1
              className="table-wrapper__select-title"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
          </>
        ) : (
          <>
            <Input
              className="allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH,
                prefix: <Search16 />,
                value: searchValue,
                onChange: onSearchChangeValue
              }}
            />
            <PurchaseOrdersUpload onFinish={onFinish} />
          </>
        )}
        {selectedRows.length > 0 && (
          <Can
            I={ACTIONS.UPLOAD}
            a={subject(SUBJECTS.PURCHASE_ORDERS, { ...user })}
          >
            <div className="row">
              <DeleteAction
                onFinish={onFinish}
                selectedRowKeys={selectedRowKeys}
              />
              {selectedRows.length === 1 && (
                <EditAction onFinish={onFinish} item={selectedRows[0]} />
              )}
            </div>
          </Can>
        )}
      </div>
      {!firstLoad && (
        <Table<TPurchaseOrdersList>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters: filters,
            community: user.community as string,
            health_system: user.health_system as string
          })}
          className="purchase-orders-table"
          hasSearchOrFilters={true}
          pageSize={pageInfo.pageSize}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={
            ability.can(ACTIONS.UPLOAD, SUBJECTS.PURCHASE_ORDERS)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  getCheckboxProps: ({ is_disabled }) => ({
                    disabled: is_disabled
                  }),
                  preserveSelectedRowKeys: true
                }
              : undefined
          }
        />
      )}
    </div>
  )
}
