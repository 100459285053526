import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TGroupDataListRequest,
  TGroupDataListResponse,
  TTotalSpendRequest
} from './types'

const fetcher = new Fetcher({})

export const getTotalSpend = async (data) =>
  fetcher.request<unknown, TTotalSpendRequest>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.TOTAL_SPEND}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getGroupedData: TGroupDataListRequest = async (data) =>
  fetcher.request<unknown, TGroupDataListResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.GROUPED_DATA}`,
    method: HTTP_METHODS.GET,
    ...data
  })
