import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { moduleName } from './constants'
import { IChat, IChatsState } from './types'

import { ResponseWithPagination } from 'constants/types'
import {
  IChatHistory,
  IChatListUpdate,
  IChatListUpdateTitle
} from '../chatHistory/types'

const initialState: IChatsState = {
  type: null,
  search: null,
  chats: [],
  total: 0,
  isLoading: false,
  isLoaded: false,
  error: null
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state: IChatsState) {
      state.isLoading = true
      state.error = null
    },
    finish(state: IChatsState) {
      state.isLoading = false
    },
    error(
      state: IChatsState,
      action: PayloadAction<{ error: IChatsState['error'] }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    set(
      state: IChatsState,
      action: PayloadAction<
        ResponseWithPagination<IChat> & Pick<IChatsState, 'type' | 'search'>
      >
    ) {
      state.type = action.payload.type
      state.search = action.payload.search
      state.chats = action.payload.results
      state.total = action.payload.count
    },
    add(state: IChatsState, action: PayloadAction<IChat>) {
      state.chats = [action.payload, ...state.chats]
    },
    bulk(state: IChatsState, action: PayloadAction<IChat[]>) {
      state.chats = [...state.chats, ...action.payload]
    },
    update(state: IChatsState, action: PayloadAction<Partial<IChat>>) {
      const { uuid, ...project } = action.payload
      state.chats = state.chats.map((p) =>
        p.uuid === uuid ? { ...p, ...project } : p
      )
    },
    updateUnreadMessages(
      state: IChatsState,
      action: PayloadAction<Partial<IChat>>
    ) {
      const { uuid } = action.payload
      state.chats = state.chats.map((p) =>
        p.uuid === uuid ? { ...p, unread_messages_count: 0 } : p
      )
    },
    updateUnreadMessagesDueSocket(
      state: IChatsState,
      action: PayloadAction<IChatHistory>
    ) {
      const chat = action.payload
      state.chats = state.chats
        .map((p) =>
          p.uuid === chat.chat_id
            ? {
                ...p,
                unread_messages_count: (p.unread_messages_count || 0) + 1,
                last_message_sent_at: chat?.message?.created_at
              }
            : p
        )
        .sort(function (p, y) {
          return p.uuid === chat.chat_id ? -1 : y.uuid === chat.chat_id ? 1 : 0
        })
    },
    updateUnreadMessagesAfterSend(
      state: IChatsState,
      action: PayloadAction<IChatHistory>
    ) {
      const chat = action.payload
      state.chats = state.chats
        .map((p) =>
          p.uuid === chat.chat_id
            ? {
                ...p,
                unread_messages_count: 0,
                last_message_sent_at: chat?.message.created_at
              }
            : p
        )
        .sort(function (p, y) {
          return p.uuid === chat.chat_id ? -1 : y.uuid === chat.chat_id ? 1 : 0
        })
    },
    updateChatMembers(
      state: IChatsState,
      action: PayloadAction<IChatListUpdate>
    ) {
      const chat = action.payload
      state.chats = state.chats.map((p) =>
        p.uuid === chat.uuid
          ? {
              ...p,
              members_count: chat.members
            }
          : p
      )
    },
    deleteChatMembers(state: IChatsState, action: PayloadAction<string>) {
      const chat = action.payload
      state.chats = state.chats.map((p) =>
        p.uuid === chat
          ? {
              ...p,
              members_count: p.members_count - 1
            }
          : p
      )
    },
    updateChatTitle(
      state: IChatsState,
      action: PayloadAction<IChatListUpdateTitle>
    ) {
      const chat = action.payload
      state.chats = state.chats.map((p) =>
        p.uuid === chat.uuid
          ? {
              ...p,
              name: chat.title
            }
          : p
      )
    },
    reset(state: IChatsState) {
      Object.keys(initialState).forEach((key) => {
        state[key as string] = initialState[key]
      })
    }
  }
})

export default slice.reducer
export const {
  loading,
  finish,
  error,
  set,
  add,
  bulk,
  update,
  reset,
  updateUnreadMessages,
  updateUnreadMessagesDueSocket,
  updateUnreadMessagesAfterSend,
  updateChatMembers,
  updateChatTitle,
  deleteChatMembers
} = slice.actions
