import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import classNames from 'classnames'
import { Table } from 'components/Table'
import { setLoading } from 'redux/store/common/slice'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { columns } from './constants'
import { getGroupedData } from './api'
import {
  TComplianceTable,
  TGroupDataList,
  TGroupDataListParams,
  TGroupDataTableRequestData
} from './types'
import { convertDateToRequestFormat } from '../../utils/moment'

export const ComplianceTable = ({
  activeTab,
  period,
  myContracts
}: TComplianceTable) => {
  const [tableData, setTableData] = useState<TGroupDataList[]>([])
  const [sortParams, setSortParams] = useState<TGroupDataTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()

  const getTableData = ({
    sortField,
    sortOrder,
    page
  }: TGroupDataTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TGroupDataListParams = { grouping_name: activeTab }
    if (period && period[0] && period[1]) {
      params.date_from = convertDateToRequestFormat(period[0]?.startOf('month'))
      params.date_to = convertDateToRequestFormat(period[1]?.endOf('month'))
    }
    if (myContracts) {
      params.my_contracts = myContracts
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    params.grouping_name = activeTab
    tableDataTokenRef.current = axios.CancelToken.source()
    getGroupedData({ params })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.name
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  useEffect(() => {
    getTableData({ ...sortParams })
  }, [activeTab, period, myContracts])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  return (
    <div className={classNames('table-wrapper__compilance')}>
      {!firstLoad && (
        <Table<TGroupDataList>
          dataSource={tableData}
          className="group-data-list-table"
          columns={columns({ activeTab })}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
    </div>
  )
}
