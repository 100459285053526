export const ACTIONS = {
  DELETE: 'DELETE',
  VIEW: 'VIEW',
  UPLOAD: 'UPLOAD',
  CANCEL: 'CANCEL',
  EDIT: 'EDIT',
  SUBMIT: 'SUBMIT',
  EXTEND: 'EXTEND',
  SETUP: 'SETUP',
  ADD: 'ADD',
  DECLINE: 'DECLINE'
}

export const SUBJECTS = {
  CONTRACT: 'CONTRACT',
  DOCUMENTS: 'DOCUMENTS',
  RESPONSIBLE_USER: 'RESPONSIBLE_USER',
  TIMELINES: 'TIMELINES',
  START_SIGNING_PROCESS: 'START_SIGNING_PROCESS',
  CONTRACT_SIGNED: 'CONTRACT_SIGNED',
  HEALTH_SYSTEM: 'HEALTH_SYSTEM',
  REBATE_CONDITIONS: 'REBATE_CONDITIONS',
  ITEM_LIST: 'ITEM_LIST'
}
