import { Button, BUTTON_TYPES } from '../../../components/Button'
import { ReactComponent as Edit16 } from '../../../assets/svg/Edit16.svg'
import { BTN_TXT } from '../../../constants'
import Popup from '../../../components/Popup/Popup'
import { useState } from 'react'
import { TPurchaseOrdersList } from '../types'
import { Forms } from 'components/Forms'
import { isEqualObjects } from '../../../helper/common'

type TProps = {
  onFinish: () => void
  item: TPurchaseOrdersList
}

const EditAction = ({ onFinish, item }: TProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDiscardChanges, setDiscardChanges] = useState(false)
  const [changedValues, setChangedValues] = useState({})
  const handleOpenPopup = () => setIsEditOpen(true)
  const handleClosePopup = () => setIsEditOpen(false)

  const changesHandler = (newValues) => {
    setChangedValues(newValues)
  }

  const findChangesHandler = () => {
    const initial = {
      quantity: item.quantity,
      price: item.price,
      total_cost: item.total_cost,
      order_date: item.order_date
    }
    if (!Object.keys(changedValues).length) {
      return false
    }
    return !isEqualObjects(initial, changedValues)
  }

  const checkChanges = () => {
    if (!findChangesHandler()) {
      setIsEditOpen(false)
      return false
    }
    return true
  }

  const onCancelEdit = () => {
    if (findChangesHandler()) {
      setDiscardChanges(true)
    } else {
      handleClosePopup()
    }
  }
  const handleCloseEditPOPopup = () => {
    setIsEditOpen(false)
    setDiscardChanges(false)
    setChangedValues({})
  }
  return (
    <>
      <Button
        className="purchase-orders-edit"
        type={BUTTON_TYPES.PRIMARY}
        icon={<Edit16 />}
        upperCase
        onClick={handleOpenPopup}
      >
        {BTN_TXT.EDIT}
      </Button>
      <Popup
        visible={isEditOpen}
        onCancel={handleClosePopup}
        title={'Edit purchase order data'}
        width={442}
        footer={
          <>
            <Button
              onClick={onCancelEdit}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              // onClick={editPurchaseOrdersPopupAction}
              upperCase
              htmlType="submit"
              form="edit-purchase-orders-form"
            >
              {BTN_TXT.SAVE}
            </Button>
          </>
        }
      >
        <Forms.EditPurchaseOrders
          callback={() => {
            handleClosePopup()
            onFinish()
          }}
          checkChanges={checkChanges}
          onChange={changesHandler}
          data={item}
        />
      </Popup>
      <Popup.Discard
        visible={isDiscardChanges}
        onCancel={() => setDiscardChanges(false)}
        onSubmit={handleCloseEditPOPopup}
      />
    </>
  )
}

export default EditAction
