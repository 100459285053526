import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Checkbox } from 'components/Checkbox'
import { PasswordStrength } from 'components/PasswordStrength'
import { Button, BUTTON_TYPES } from 'components/Button'
import { getPasswordData } from 'redux/store/setPassword/getters'
import { setPassword, resetPassword } from 'features/SignPopup/api'
import { setLoading } from 'redux/store/common/slice'
import { setLoginData } from 'redux/store/login/slice'
import useRouter from 'hooks/useRouter'
import { routes } from 'router'
import {
  BTN_TXT,
  LABELS,
  PLACEHOLDERS,
  VALIDATION_MESSAGES
} from 'constants/txt'
import { TFormSignUp, TFormSignUpForm, TError } from './forms.signUp.d'
import './forms.signUp.scss'
import { setLocalConfirmToken, setLocalEmail } from '../../helper/common'
import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import TermsOfUseAction from '../TermsOfServices/TermsOfUseAction'
import PrivacyAction from '../TermsOfServices/PrivacyAction'
import { signUpInitialValues } from './constants'
import { notification } from '../Notification'

export const SignUp: TFormSignUp = ({
  className,
  emailChange
}): JSX.Element => {
  const [error, setError] = useState<TError>()
  const passwordData = useSelector(getPasswordData)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const password = Form.useWatch('password', form)
  const { push } = useRouter()

  useEffect(() => {
    dispatch(setLoading(false))
  }, [])
  const createPassword = async (values) => {
    try {
      dispatch(setLoading(true))
      if (passwordData.isReset) {
        await resetPassword({
          token: passwordData.token,
          password: values.password,
          password_confirm: values.repeatPassword
        })
        dispatch(
          setLoginData({
            email: passwordData.email,
            password: values.password
          })
        )
        form.resetFields()
        notification.success({
          message: VALIDATION_MESSAGES.PASSWORD_UPDATE_SUCCESS
        })
        push(routes.login)
      } else {
        await setPassword({
          token: passwordData.token,
          password: values.password,
          password_confirm: values.repeatPassword
        })
          .then(() => {
            setLocalConfirmToken(passwordData.token)
            setLocalEmail(passwordData.email)
            push(routes.faNumber)
          })
          .catch((err) => {
            notification.error({ message: err?.data?.details[0] })
          })
        // form.resetFields()
      }
    } catch (e: any) {
      setError(e?.data)
    } finally {
      dispatch(setLoading(false))
    }
  }
  return (
    <>
      <Form<TFormSignUpForm>
        className={cn('forms-sign-up', className)}
        layout="vertical"
        onFinish={createPassword}
        initialValues={signUpInitialValues}
        form={form}
      >
        {!!passwordData.isReset ? (
          <>
            <div className="row mt-32">
              <Typography.Headline4 weight={TYPOGRAPHY_WEIGHT.BOLD}>
                Create new password
              </Typography.Headline4>
            </div>
            <div className="row mt-12">
              <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
                To set a new password, please provide the new password twice
              </Typography.Headline6>
            </div>
          </>
        ) : (
          <div className="row mt-32">
            <Input
              propsInput={{
                placeholder: PLACEHOLDERS.EMAIL,
                disabled: true,
                value: passwordData.email,
                onChange: emailChange
              }}
              propsItem={{ label: LABELS.EMAIL }}
            />
          </div>
        )}
        <div className="row mt-24 vertical">
          <Input.Password
            propsInput={{ maxLength: 128 }}
            propsItem={{
              label: LABELS.CREATE_PASSWORD,
              help: error?.password || error?.non_field_errors,
              name: 'password',
              validateStatus:
                typeof error === 'object' && Object.keys(error).length
                  ? 'error'
                  : undefined
            }}
          />
          <PasswordStrength value={password} />
        </div>
        <div className="row mt-24">
          <Input.Password
            propsInput={{ maxLength: 128 }}
            propsItem={{
              name: 'repeatPassword',
              label: LABELS.REPEAT_PASSWORD,
              help: error?.password_confirm,
              validateStatus: error?.password_confirm && 'error'
            }}
          />
        </div>
        {!passwordData.isReset && (
          <div className="row mt-24">
            <Checkbox
              propsItem={{
                name: 'agreement',
                valuePropName: 'checked'
              }}
            >
              <Typography.Body2 className="terms-links">
                Creating an account indicates that you accept our &nbsp;
                <TermsOfUseAction />
                &nbsp;and&nbsp;
                <PrivacyAction />
                {/*, and our default&nbsp;*/}
                {/*<Link to="/">Notification Settings</Link>.*/}
              </Typography.Body2>
            </Checkbox>
          </div>
        )}
        <div className="row mt-24">
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { repeatPassword, agreement, password } = getFieldsValue()
              return (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  disabled={
                    !passwordData ||
                    !password ||
                    !repeatPassword ||
                    (!passwordData.isReset && !agreement)
                  }
                  htmlType="submit"
                  upperCase
                >
                  {passwordData.isReset
                    ? BTN_TXT.RESET_PASSWORD
                    : BTN_TXT.SIGN_UP}
                </Button>
              )
            }}
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
