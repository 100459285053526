import moment, { Moment, MomentInput, unitOfTime } from 'moment'

import {
  ITimeline,
  TTimelines,
  ITimelineSettings,
  ITimelineValidation
} from 'components/Timelines/types'
import {
  timelineDateFormat,
  timelineTimeFormat
} from 'components/Timelines/constants'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { TDropdownItem } from '../Dropdown/types'

export const timelineLabels = (
  desiredStartTime: MomentInput,
  interval: number,
  period: unitOfTime.Base
) => {
  const periodsInADay = moment.duration(1, 'day').as(period)
  const startTimeMoment = moment(desiredStartTime, timelineTimeFormat)

  const timeLabels: string[] = []

  for (let i = 0; i <= periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period)
    timeLabels.push(timelineLabelFormat(startTimeMoment))
  }

  return timeLabels
}

export const timelineLabelFormat = (date: Moment) =>
  date.format(timelineTimeFormat)

export const roundHoursUp = (date: Moment) =>
  date.minute() || date.second() || date.millisecond()
    ? date.add(1, 'h').startOf('h')
    : date.startOf('h')

export const disabledDateBeforeNow =
  (prevDate?: Moment | null, isPrs?: boolean) => (current: Moment) => {
    if (prevDate && isPrs) {
      return prevDate <= current || moment() >= current
    } else {
      return (
        moment().add(-1, 'days') >= current ||
        (prevDate ? prevDate > current : false)
      )
    }
  }

export const validateTimeline = <TKey extends Record<string, string>>(
  timeline: ITimeline<TKey>,
  prevTimeline?: ITimeline<TKey> | null
): ITimelineValidation | null => {
  const mergedDateTime = mergeTimelineDateTime(
    timeline.date,
    timeline.timeLabel
  )

  const mergedPrevDateTime = prevTimeline
    ? mergeTimelineDateTime(prevTimeline.date, prevTimeline.timeLabel)
    : moment()

  if (mergedDateTime?.isSameOrBefore(mergedPrevDateTime)) {
    if (timeline.key === 'overall') {
      return { status: 'error', message: VALIDATION_MESSAGES.SM00101 }
    } else {
      return { status: 'error', message: VALIDATION_MESSAGES.SM0030 }
    }
  }

  if (mergedDateTime?.isSameOrBefore(moment())) {
    return { status: 'error', message: '' }
  }

  return null
}

export const getTimelinesBySettings = <TKey extends Record<string, string>>(
  settings: ITimelineSettings<TKey>[]
): TTimelines<TKey> =>
  settings.reduce((timeline, setting, index, timelines) => {
    const { key, title, diffDays } = setting

    const prevDate =
      index === 0
        ? roundHoursUp(moment())
        : moment(timeline[timelines[index - 1].key].date)

    const timelineDate = prevDate.add(diffDays * 24, 'h')

    return {
      ...timeline,
      [key]: {
        order: index,
        key,
        title,
        date: timelineDate,
        timeLabel: timelineLabelFormat(timelineDate),
        hasChanges: false
      }
    }
  }, {} as TTimelines<TKey>)

export const calculateDuration = (
  date: Moment,
  prevDate?: Moment | null
): string | null => {
  const duration = moment.duration(
    date.diff(prevDate || roundHoursUp(moment()))
  )
  const daysFromMonth =
    duration.get('month') > 0
      ? Math.ceil(
          moment(prevDate || moment().startOf('day'))
            .add(duration.get('month'), 'month')
            .diff(moment(), 'days', true)
        )
      : 0

  return duration.isValid()
    ? ` ${daysFromMonth + duration.get('days')}d ${duration.get('hours')}h`
    : null
}

export const mergeTimelineDateTime = (
  date: Moment | null,
  time: string | null
) =>
  moment(
    date?.format(timelineDateFormat) + 'T' + time,
    `${timelineDateFormat}T${timelineTimeFormat}`
  )

export const getTimeOptionsStartFrom = (startTime?: MomentInput) =>
  timelineLabels(startTime, 1, 'h').map((label) => ({
    label,
    value: label
  }))
export const getTimeOptionsStartFromWithHalfInterval = (
  startTime?: MomentInput
) =>
  timelineLabels(startTime, 0.5, 'h').map((label) => ({
    label,
    value: label
  }))

export const filterTimesOptions = (
  options: TDropdownItem[],
  startTime: string
) => {
  const startIndex = options.findIndex((time) => time.label === startTime)
  return startIndex !== -1 ? options.slice(startIndex) : []
}

export const generateTimeOptions = (): TDropdownItem[] => {
  const options: TDropdownItem[] = []
  const format = 'hh:mm A'
  const current: Moment | null = moment('12:00 AM', format)
  const end = moment('11:59 PM', format)

  while (current && current <= end) {
    const formattedTime = current.format(format)
    options.push({ label: formattedTime, value: formattedTime })
    current.add(30, 'minutes')
  }

  return options
}
