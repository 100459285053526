import { Form } from 'antd'

import './styles.scss'

import Send from './Send'
import Message from './Message'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMessageToReply } from '../../../redux/store/chatHistory/getters'
import { resetMessageToReply } from '../../../redux/store/chatHistory/slice'
import { AttachmentsList } from '../../../components/AttachmentsList/AttachmentsList'

interface IChatInputForm {
  message: string
}

const Input = () => {
  const chatContext = useChatContext()

  const messageToReply = useSelector(getMessageToReply)

  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm<IChatInputForm>()

  const message = Form.useWatch('message', form)

  const dispatch = useDispatch()

  const { state, actions } = useChatContext()
  const { handleDeleteAttachment } = actions
  const { uploadedAttachments, currentFile } = state

  const handleFinish = async ({ message }) => {
    try {
      setIsLoading(true)
      const attachments = uploadedAttachments?.map((i) => i?.uuid)
      await chatContext.actions.sendMessageRequestAsync(
        message,
        messageToReply?.message.uuid,
        attachments
      )
      form.resetFields()
    } finally {
      dispatch(resetMessageToReply())
      setIsLoading(false)
    }
  }

  const onEnterHandler = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault()
      handleFinish({ message })
    }
  }

  return chatContext.state.canSendMessage ? (
    <Form<IChatInputForm>
      form={form}
      name="chat-message-input"
      onFinish={handleFinish}
    >
      <div className="chat-input-wrapper">
        {!!uploadedAttachments?.length && (
          <AttachmentsList.ChatAttachment
            label=""
            disableActions={false}
            files={
              !!currentFile?.file
                ? [...uploadedAttachments, currentFile]
                : uploadedAttachments
            }
            onDelete={handleDeleteAttachment}
          />
        )}
        {!!currentFile?.file?.length && !uploadedAttachments && (
          <AttachmentsList.ChatAttachment
            label=""
            disableActions={false}
            files={[currentFile]}
            onDelete={handleDeleteAttachment}
          />
        )}
        <div className="chat__input chat-input">
          <Message propsTextArea={{ onPressEnter: onEnterHandler }} />
          <Send
            disabled={
              isLoading ||
              !(
                (!!uploadedAttachments?.length && !currentFile?.file) ||
                message?.trim().length
              )
            }
          />
        </div>
      </div>
    </Form>
  ) : null
}

export default Input
