import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { AddressCell } from 'components/AddressCell/AddressCell'
import { IContractHealthSystem } from '../types'
import AmendmentCell from 'features/ContractDetails/HSList/HSTable/AmendmentCell/AmendmentCell'
import moment from 'moment'
import { timelineDateFormat } from '../../../../components/Timelines/constants'

export const columnsFullView = (): ColumnsType<IContractHealthSystem> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'tax_id',
    key: 'tax_id',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="tax_id"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Tax ID
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone #
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'address',
    key: 'address',
    width: 230,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="address"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Address
      </Sorter>
    ),
    render: (_, { address }) => <AddressCell address={address} />,
    sorter: true,
    showSorterTooltip: false
  }
]

export const columnsShortView = (): ColumnsType<IContractHealthSystem> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 480,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'pricing_effective_date',
    key: 'pricing_effective_date',
    width: 280,
    title: 'Pricing effective date',
    render: (dateString) => moment(dateString).format(timelineDateFormat)
  },
  {
    dataIndex: 'amendment_document',
    key: 'amendment_document',
    width: 380,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<IContractHealthSystem>
        dataIndex="amendment_document"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Amendment / LoP
      </Sorter>
    ),
    render: (_, { amendment_document }) => (
      <AmendmentCell amendment_document={amendment_document} />
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
