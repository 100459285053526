import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import {
  Conditions,
  ContractAbility,
  ContractAbilityBuilder,
  PossibleAbilities
} from 'features/ContractDetails/Abilities/types'
import { ACTIONS, SUBJECTS } from 'features/ContractDetails/Abilities/constants'
import {
  isInStatuses,
  isOwnerCondition,
  isResponsibleCommonsCondition,
  isResponsibleCommunityCondition,
  isResponsibleVendorCondition
} from 'features/ContractDetails/Abilities/utils'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'

const ContractAbilityClass = Ability as AbilityClass<ContractAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ContractAbilityClass)

  defineAnonymousAbilities(builder)

  switch (user.role) {
    case ROLES.COMMUNITY_PRESIDENT:
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
      defineOwnerAbilities(builder, user)
      break
    case ROLES.VENDOR_CONTRACT_STEWARD:
      defineVCSAbilities(builder, user)
      break
    case ROLES.ANALYST:
    case ROLES.LEGAL:
      defineResponsibleCommunityAbilities(builder, user)
      break
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CONTRACT_STEWARD:
    case ROLES.COMMONS_ANALYST:
    case ROLES.COMMONS_LEGAL:
    case ROLES.COMMONS_CLINICAL_COORDINATOR:
      defineCognusCommonsAbilities(builder, user)
      break
    case ROLES.VENDOR_ANALYST:
    case ROLES.VENDOR_LEGAL:
      defineResponsibleVendorAbilities(builder, user)
      break
  }

  return new Ability(builder.rules) as ContractAbility
}

const defineOwnerAbilities = (builder: ContractAbilityBuilder, user: IUser) => {
  builder.can(
    [ACTIONS.VIEW, ACTIONS.CANCEL],
    SUBJECTS.CONTRACT,
    isOwnerCondition(user)
  )

  builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.ADD, SUBJECTS.RESPONSIBLE_USER, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.EXPIRED
    ])
  })
  builder.can(ACTIONS.DELETE, SUBJECTS.RESPONSIBLE_USER, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.EXPIRED
    ])
  })

  builder.can(
    ACTIONS.SUBMIT,
    [SUBJECTS.START_SIGNING_PROCESS, SUBJECTS.CONTRACT_SIGNED],
    isOwnerCondition(user)
  )

  builder.can(ACTIONS.UPLOAD, SUBJECTS.DOCUMENTS, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.SETUP, SUBJECTS.TIMELINES, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.EXTEND, SUBJECTS.CONTRACT, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXPIRED,
      CONTRACT_STATUS.PENDING_FOR_START
    ])
  })

  builder.can(ACTIONS.EDIT, SUBJECTS.REBATE_CONDITIONS, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })

  builder.can(ACTIONS.ADD, SUBJECTS.HEALTH_SYSTEM, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.PENDING_FOR_START,
      CONTRACT_STATUS.ACTIVE
    ])
  })
  builder.can(ACTIONS.UPLOAD, SUBJECTS.ITEM_LIST, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })
}

const defineVCSAbilities = (builder: ContractAbilityBuilder, user: IUser) => {
  builder.can(
    [ACTIONS.VIEW, ACTIONS.CANCEL],
    SUBJECTS.CONTRACT,
    isResponsibleVendorCondition(user)
  )

  builder.can(ACTIONS.ADD, SUBJECTS.RESPONSIBLE_USER, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.EXPIRED
    ])
  })
  builder.can(ACTIONS.DELETE, SUBJECTS.RESPONSIBLE_USER, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.ACTIVE,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.EXPIRED
    ])
  })

  builder.can(ACTIONS.UPLOAD, SUBJECTS.DOCUMENTS, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })
  builder.can(ACTIONS.DECLINE, SUBJECTS.CONTRACT, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.PENDING_FOR_START,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.ACTIVE
    ])
  })
}

const defineResponsibleVendorAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.UPLOAD, SUBJECTS.DOCUMENTS, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })
  builder.can(ACTIONS.DECLINE, SUBJECTS.CONTRACT, {
    ...isResponsibleVendorCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE,
      CONTRACT_STATUS.PENDING_FOR_START,
      CONTRACT_STATUS.EXECUTED,
      CONTRACT_STATUS.ACTIVE
    ])
  })
}

const defineResponsibleCommunityAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.UPLOAD, SUBJECTS.DOCUMENTS, {
    ...isResponsibleCommunityCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })
}

const defineAnonymousAbilities = (builder: ContractAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT)

  builder.can(
    ACTIONS.VIEW,
    SUBJECTS.TIMELINES,
    isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  )
}
const defineCognusCommonsAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT)
  if (
    user.role === ROLES.COMMONS_ANALYST ||
    user.role === ROLES.COMMONS_LEGAL
  ) {
    builder.can(ACTIONS.UPLOAD, SUBJECTS.DOCUMENTS, {
      ...isResponsibleCommonsCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE
      ])
    })
  }
  if (
    user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT
  ) {
    builder.can(ACTIONS.CANCEL, SUBJECTS.CONTRACT, {
      ...isResponsibleCommonsCondition(user)
    })
    builder.can(ACTIONS.ADD, SUBJECTS.HEALTH_SYSTEM, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE,
        CONTRACT_STATUS.PENDING_FOR_START,
        CONTRACT_STATUS.ACTIVE
      ])
    })
    builder.can(ACTIONS.SETUP, SUBJECTS.TIMELINES, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE
      ])
    })
    builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE
      ])
    })

    builder.can(ACTIONS.UPLOAD, SUBJECTS.DOCUMENTS, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE
      ])
    })
  }
  if (user.role === ROLES.COMMONS_VICE_PRESIDENT) {
    builder.can(ACTIONS.ADD, SUBJECTS.RESPONSIBLE_USER, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE,
        CONTRACT_STATUS.ACTIVE,
        CONTRACT_STATUS.EXECUTED,
        CONTRACT_STATUS.EXPIRED
      ])
    })
    builder.can(ACTIONS.DELETE, SUBJECTS.RESPONSIBLE_USER, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE,
        CONTRACT_STATUS.ACTIVE,
        CONTRACT_STATUS.EXECUTED,
        CONTRACT_STATUS.EXPIRED
      ])
    })
  } else if (user.role === ROLES.COMMONS_CONTRACT_STEWARD) {
    builder.can(ACTIONS.ADD, SUBJECTS.RESPONSIBLE_USER, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE,
        CONTRACT_STATUS.ACTIVE,
        CONTRACT_STATUS.EXECUTED,
        CONTRACT_STATUS.EXPIRED
      ])
    })
    builder.can(ACTIONS.DELETE, SUBJECTS.RESPONSIBLE_USER, {
      ...isOwnerCondition(user),
      ...isInStatuses([
        CONTRACT_STATUS.CONTRACTING,
        CONTRACT_STATUS.OUT_FOR_SIGNATURE,
        CONTRACT_STATUS.ACTIVE,
        CONTRACT_STATUS.EXECUTED,
        CONTRACT_STATUS.EXPIRED
      ])
    })
  }

  builder.can(
    ACTIONS.SUBMIT,
    [SUBJECTS.START_SIGNING_PROCESS, SUBJECTS.CONTRACT_SIGNED],
    { ...isOwnerCondition(user) }
  )
  builder.can(ACTIONS.EDIT, SUBJECTS.REBATE_CONDITIONS, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })
  builder.can(ACTIONS.UPLOAD, SUBJECTS.ITEM_LIST, {
    ...isOwnerCondition(user),
    ...isInStatuses([
      CONTRACT_STATUS.CONTRACTING,
      CONTRACT_STATUS.OUT_FOR_SIGNATURE
    ])
  })
  builder.can(ACTIONS.CANCEL, SUBJECTS.CONTRACT, { ...isOwnerCondition(user) })
}
