import { useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'

import './styles.scss'
import { IAssignResponsibleForm } from './types'

import { routes } from 'router'
import { Select } from 'components/Select'
import { notification } from 'components/Notification'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import {
  assignOwnerToContractRequestAsync,
  getAvailableOwnersRequestAsync
} from 'features/ContractDetails/api'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { BTN_TXT, PLACEHOLDERS, VALIDATION_MESSAGES } from 'constants/txt'
import useRouter from 'hooks/useRouter'
import { OPTIONS_SORT_ORDER, ROLES_NAMES } from '../../../../../constants'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../redux/store/user/getters'
import { canSeeMyContracts } from 'helper/common'

const AssignResponsible = () => {
  const router = useRouter()
  const user = useSelector(getUser)

  const contractDetailsContext = useContractDetailsContext()
  const { assignResponsiblePopup } = useContractDetailsPopup()

  const { payload: assignResponsiblePayload } = assignResponsiblePopup.state

  const { createContractAsync } = contractDetailsContext.actions

  const [form] = Form.useForm<IAssignResponsibleForm>()

  const owner = Form.useWatch('owner', form)

  const [ownerOptions, setOwnersOptions] = useState([])

  const assignAndCreateDisabled =
    !assignResponsiblePayload?.creationDetails || !owner

  const handleFinishForm = async () => {
    if (!assignResponsiblePayload?.creationDetails || !owner) {
      return
    }

    const contractUuid = await createContractAsync(
      assignResponsiblePopup.state.payload.creationDetails
    )

    if (contractUuid) {
      await assignOwnerToContractRequestAsync(contractUuid, owner)

      assignResponsiblePopup.actions.close()

      notification.success({
        message: `Contract ${VALIDATION_MESSAGES.SM0009} created`
      })

      router.push(
        `${routes.contracts}${
          canSeeMyContracts(user.role) ? '/my_contracts' : ''
        }/${contractUuid}`
      )
    }
  }

  useEffect(() => {
    const categories =
      assignResponsiblePayload?.creationDetails.contract_category

    if (categories?.length) {
      getAvailableOwnersRequestAsync({ limit: 0, offset: 0, categories }).then(
        (response) => {
          if (response?.data?.results) {
            setOwnersOptions(
              Object.values(
                response?.data?.results.reduce(
                  (p, n) => ({
                    ...p,
                    [n.role]: {
                      label: ROLES_NAMES[n.role],
                      options: [
                        ...(p[n.role]?.options || []),
                        {
                          value: n.uuid,
                          label: `${n?.first_name} ${n?.last_name}`
                        }
                      ]
                    }
                  }),
                  {}
                )
              )
            )
          }
        }
      )
    }
  }, [assignResponsiblePayload?.creationDetails.contract_category])

  const FormActions = useMemo(
    () => (
      <div className="contract-assign-responsible__actions">
        <Button onClick={assignResponsiblePopup.actions.close} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          disabled={assignAndCreateDisabled}
          form="contract-assign-responsible"
          htmlType="submit"
          upperCase
        >
          {BTN_TXT.ASSIGN_AND_CREATE_CONTRACT}
        </Button>
      </div>
    ),
    [assignAndCreateDisabled, assignResponsiblePopup.actions.close]
  )

  return (
    <Form<IAssignResponsibleForm>
      id="contract-assign-responsible"
      form={form}
      onFinish={handleFinishForm}
    >
      <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        Community President can not be responsible. Select user to create{'\n'}
        contract.
      </Typography.Body1>
      <div className="contract-assign-responsible__select">
        <Select
          options={ownerOptions}
          propsItem={{
            name: 'owner',
            label: 'Vice President / Contract Steward'
          }}
          propsSelect={{
            filterSort: (optionA, optionB) =>
              optionA.label && optionB.label
                ? OPTIONS_SORT_ORDER[optionB.label as string] -
                  OPTIONS_SORT_ORDER[optionA.label as string]
                : 0,
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      {FormActions}
    </Form>
  )
}

export default AssignResponsible
