import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Forms from 'components/Forms/Forms'
import { Container } from 'components/Container/Container'
import { createUser, fetchUserData, getHealthSystem, updateUser } from './api'
import { fetchHealthSystemsList } from 'redux/store/healthSystemsList/slice'
import { getUser } from 'redux/store/user/getters'
import useRouter from 'hooks/useRouter'
import { USER_PROFILE_BREADCRUMBS } from './constants'
import './styles.scss'
import { setLoading } from 'redux/store/common/slice'
import history from 'router/history'
import { updateUserProfile } from '../../features/UserProfile/api'
import { notification } from '../../components/Notification'

export const CRUDUser = () => {
  const [userData, setUserData] = useState({})
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [isShow, setIsShow] = useState(false)
  const { id } = useParams()
  const { query } = useRouter()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(setLoading(true))
      fetchUserData(id).then((data) => {
        if (data?.data?.community) {
          getHealthSystem(
            data?.data?.community,
            data?.data?.health_system
          ).then(() => {
            setUserData({
              ...data?.data,
              ...{
                health_system: data?.data?.health_system,
                avatar:
                  data?.data?.avatar?.file || data?.data?.avatar_logo?.file
              }
            })
          })
        }
        setUserData({
          ...data?.data,
          ...{
            avatar: data?.data?.avatar?.file || data?.data?.avatar_logo?.file
          }
        })
        setIsShow(true)
      })
    } else {
      if (query?.hsUser || query?.hsHosUser) {
        const data = {
          health_system: query?.hsUser || query?.hsHosUser
        }
        if (location.search.includes('&role=rca')) {
          data['role'] = 'community_rca'
        }
        if (location.search.includes('&role=stakeholder')) {
          data['role'] = 'community_stakeholder'
        }
        setUserData({ ...data })
      }

      setIsShow(true)
    }
  }, [id])
  useEffect(() => {
    if (user?.community) {
      dispatch(fetchHealthSystemsList(user.community as string))
    }
  }, [user.community])
  const onSubmit = (values: any) => {
    const data: any = {}
    for (const key in values) {
      if (key === 'contract_stewards' || key === 'hospitals') {
        if (
          !!values[key]?.filter((k) => !!Object.values(k).find((i) => !!i))
            ?.length
        )
          data[key] = values[key].filter(
            (k) => !!Object.values(k).find((i) => !!i)
          )
        else if (values[key] === null) data[key] = []
        else if (!values[key]?.length) data[key] = values[key]

        if (key === 'hospitals' && !!data?.hospitals) {
          data?.hospitals?.forEach((i) => delete i?.name)
        }
      } else {
        if (
          (typeof values[key] !== 'number' && !values[key]?.length) ||
          !values[key] ||
          (Array.isArray(values[key]) &&
            typeof values[key][0] === 'object' &&
            !Object.keys(values[key][0]).length)
        ) {
          if (values[key] == 0) {
            data[key] = values[key]
          }
        } else {
          data[key] = values[key]
        }
      }
    }
    if (id === user.uuid) {
      updateUserProfile({
        first_name: data['first_name'],
        last_name: data['last_name'],
        phone_number: data['phone_number'] || '',
        avatar_id: data['avatar']
      })
        .then(history.back)
        .catch((err) => {
          dispatch(setLoading(false))
          notification.error({ message: err?.data?.email[0] })
        })
    }
    if (id !== user.uuid && id && id !== 'new') {
      updateUser(id, data)
        .then(history.back)
        .catch((err) => {
          dispatch(setLoading(false))
          notification.error({ message: err?.data?.email[0] })
        })
      return
    }
    if (id === 'new') {
      createUser(data)
        .then(() => {
          if (
            location.search.includes('&role=rca') ||
            location.search.includes('&role=stakeholder')
          ) {
            window.close()
          } else history.back()
        })
        .catch((err) => {
          dispatch(setLoading(false))
          if (!!err?.data?.email?.length) {
            notification.error({ message: err?.data?.email[0] })
          }
          if (
            !!err?.data?.contract_stewards &&
            !data.contract_stewards?.length
          ) {
            notification.error({
              message: 'Contract Steward is required'
            })
          } else if (!!err?.data?.contract_stewards) {
            notification.error({
              message: err?.data?.contract_stewards[0]
            })
          }
          throw new Error(err)
        })
    }
  }
  return (
    <Container
      className="user-profile"
      breadcrumbs={USER_PROFILE_BREADCRUMBS(id)}
      title={id && id !== 'new' ? 'User profile' : 'Create user profile'}
    >
      {isShow && (
        <Forms.User
          onSubmit={onSubmit}
          setIsActive={setIsActive}
          isActive={isActive}
          initialState={userData}
          hsHosUser={query?.hsHosUser}
          hsUser={query?.hsUser}
        />
      )}
    </Container>
  )
}
