import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { TContractItemsList } from './types'

export const columns = (): ColumnsType<TContractItemsList> => [
  {
    dataIndex: 'vendor_item_number',
    key: 'vendor_item_number',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractItemsList>
        dataIndex="vendor_item_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor item number
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractItemsList>
        dataIndex="description"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Description
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'units',
    key: 'units',
    width: 285,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractItemsList>
        dataIndex="units"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        UoM
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'rebate_eligible',
    key: 'rebate_eligible',
    width: 285,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TContractItemsList>
        dataIndex="rebate_eligible"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Rebate eligible
      </Sorter>
    ),
    render: (_, { rebate_eligible }) => (
      <div>{rebate_eligible ? 'Yes' : 'No'}</div>
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
