import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { AddPopup } from './AddPopup/AddPopup'
import { setLoading } from 'redux/store/common/slice'
import { getVendorTeamUsers } from './api'
import { columns } from './constants'
import { isTableHasParams } from 'helper/common'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { useDebounce } from 'hooks/hooks'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import {
  THealthSystemTableRequestData,
  THealthSystemUsersParams
} from 'features/HealthSystemUsers/types'
import {
  TRFPVendorUsersData,
  TRFPVendorUsersProps,
  TRFPVendorUsersFilters,
  TRFPVendorUsersFiltersFunc
} from './types'
import 'features/ChapterUsers/styles.scss'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { ReactComponent as Close16 } from '../../../assets/svg/Clos16.svg'
import { ReactComponent as Delete16 } from '../../../assets/svg/Delete16.svg'
import { updateVendorUsers } from '../RFPDetails/api'
import { Popup } from '../../../components/Popup'
import { notification } from '../../../components/Notification'
import { RFP_ACCEPTANCE_STATUSES } from '../constants'

export const RFPVendorsUsers = ({ disableActions }: TRFPVendorUsersProps) => {
  const rfpDetailsContext = useRFPDetailsContext()

  const { isCanSelectUsers, isVCS, biddingData } = rfpDetailsContext.state

  const [tableData, setTableData] = useState<TRFPVendorUsersData[]>([])
  const [sortParams, setSortParams] = useState<THealthSystemTableRequestData>()
  const [filters, setFilters] = useState<TRFPVendorUsersFilters>({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [totalNumber, setTotalNumber] = useState(0)
  const dispatch = useDispatch()
  const { id } = useParams()
  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: THealthSystemTableRequestData) => {
    dispatch(setLoading(true))
    if (firstLoad) {
      setFirstLoad(false)
    }
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: THealthSystemUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    if (searchValue) {
      params.search = searchValue
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getVendorTeamUsers(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp: any) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  useEffect(() => {
    setLoading(false)
  }, [tableData[0]?.uuid as string])
  const applyFilters: TRFPVendorUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  const handleDeleteUsers = () => {
    dispatch(setLoading(true))
    updateVendorUsers(id as string, {
      vendor_members:
        tableData.length !== selectedRowKeys.length
          ? tableData
              .map((i) => i.uuid)
              .filter((i) => !selectedRowKeys.find((j) => i === j))
          : []
    })
      .then(() => {
        getTableData({ ...sortParams, filters })
        handleCloseDeletePopup()
        notification.success({
          message: `User${selectedRowKeys.length > 1 ? 's' : ''} ${
            selectedRowKeys.length > 1 ? 'have' : 'has'
          } been successfully deleted.`
        })
      })
      .catch(() => dispatch(setLoading(false)))
    handleCloseDeletePopup()
  }
  const handleCloseDeletePopup = () => {
    setIsDeletePopupOpen(false)
    setSelectedRowKeys([])
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  return (
    <div className="table-wrapper">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              className: 'rfp-hs-users_search',
              placeholder: PLACEHOLDERS.SEARCH,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header_buttons">
          {isVCS &&
            !selectedRowKeys.length &&
            disableActions &&
            biddingData.acceptance_status ===
              RFP_ACCEPTANCE_STATUSES.ACCEPTED && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={() => setIsAddPopupOpen(true)}
                upperCase
              >
                {BTN_TXT.ADD_USER}
              </Button>
            )}
          {selectedRowKeys.length > 0 && disableActions && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={() => setIsDeletePopupOpen(true)}
              icon={<Delete16 />}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          )}
        </div>
      </div>
      <Table<TRFPVendorUsersData>
        dataSource={tableData}
        columns={columns({
          applyFilters,
          filters
        })}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        className="rfp-vendors-data-list-table"
        onChangePage={handleChangePageSize}
        onChange={handleTableChange}
        hasSearchOrFilters={isTableHasParams<TRFPVendorUsersFilters>(
          debouncedSearch,
          filters
        )}
        createButton={
          isVCS &&
          disableActions &&
          biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.ACCEPTED
            ? {
                buttonText: BTN_TXT.ADD_USER,
                availability: true,
                action: () => setIsAddPopupOpen(true)
              }
            : undefined
        }
        rowSelection={
          !isCanSelectUsers &&
          biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.ACCEPTED
            ? {
                selectedRowKeys,
                onChange: handleSelectRow
              }
            : undefined
        }
      />
      <Popup
        visible={isDeletePopupOpen}
        onCancel={handleCloseDeletePopup}
        title={`Delete user${selectedRowKeys.length > 1 ? 's' : ''}?`}
        width={442}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={handleCloseDeletePopup}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleDeleteUsers}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          {selectedRowKeys.length} user{selectedRowKeys.length > 1 ? 's' : ''}{' '}
          will be removed from RFP.
          <br />
          Do you really want to delete user
          {selectedRowKeys.length > 1 ? 's' : ''} from the list?
        </Typography.Body1>
      </Popup>
      <AddPopup
        selectedUsers={tableData}
        isAddPopupOpen={isAddPopupOpen}
        setIsAddPopupOpen={setIsAddPopupOpen}
        updateAssignedValues={() => getTableData({ ...sortParams, ...filters })}
      />
    </div>
  )
}
