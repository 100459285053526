import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { Status, USER_STATUS_TYPES_TO_CLASSNAME } from 'components/Status'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { fetchContractCategories, fetchDepartments } from 'pages/CRUDUser/api'
import { fullFormatPhoneNumber } from 'helper/common'
import { routes } from 'router'
import {
  ROLES_NAMES,
  ROLES_OPTIONS_HOSPITALS,
  USER_STATUSES_OPTIONS
} from 'constants/common'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import {
  TTableUser,
  TUsersColumnsFunc,
  TUsersFilters,
  TUsersFiltersFunc
} from './types'

export const columns = ({
  applyFilters,
  filters,
  userId,
  hsId
}: TUsersColumnsFunc): ColumnsType<TTableUser> => [
  {
    dataIndex: 'first_name',
    key: 'first_name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="first_name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        User name
      </Sorter>
    ),
    render: (_, { first_name, last_name, uuid }) => (
      <CellWithSubtitle
        title={
          <Link
            to={`${routes.createUserProfile}/${uuid}`}
            state={{ redirectTab: SETTINGS_TABS[4].key }}
          >
            {first_name} {last_name}
          </Link>
        }
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'role',
    key: 'role',
    width: 148,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="role"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Role
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TUsersFilters, TUsersFiltersFunc, unknown>
        items={ROLES_OPTIONS_HOSPITALS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="roles"
        {...props}
      />
    ),
    render: (text) => ROLES_NAMES[text],
    filterIcon: !!filters?.roles?.length ? <FilterApplied16 /> : <Filter16 />,
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    width: 126,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TUsersFilters, TUsersFiltersFunc, unknown>
        items={USER_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
        {status}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    width: 220,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone #
      </Sorter>
    ),
    render: (_, { phone_number }) => fullFormatPhoneNumber(phone_number),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'hospital',
    key: 'hospital',
    width: 227,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="hospital"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Hospital
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'departments',
    key: 'departments',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="departments"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Departments
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TUsersFilters, TUsersFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchDepartments(userId, hsId)}
        field="departments"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { departments }) => {
      const text = departments.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.departments?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_categories',
    key: 'contract_categories',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TTableUser>
        dataIndex="contract_categories"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Contract categories
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TUsersFilters, TUsersFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_categories }) => {
      const text = contract_categories.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  }
]
