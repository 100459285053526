import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Table } from 'components/Table'
import { setLoading } from 'redux/store/common/slice'
import { columns } from './constants'
import { isTableHasParams } from 'helper/common'
import { DEFAULT_PAGE } from 'components/Table/constants'
import {
  TContractPipelineList,
  TContractPipelineListFilters,
  TContractPipelineListFiltersFunc,
  TContractPipelineListParams,
  TContractPipelineTableRequestData
} from './types'
import { getContractPipelineList } from '../../Contracts/api'
import './style.scss'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { ReactComponent as Plus16 } from '../../../assets/svg/Plus16.svg'
import { BTN_TXT } from '../../../constants'
import { useContractPagePopup } from 'pages/Contracts/Providers/ContractPagePopupProvider'
import { ACTIONS, Can, SUBJECTS } from '../../Permission'
import { CreateContractPipelineModal } from '../CreateContractPipelineModal/CreateContractPipelineModal'
import { getUser } from '../../../redux/store/user/getters'
import { JoinModal } from '../JoinContainer'

export const ContractPipelineTable = () => {
  const user = useSelector(getUser)
  const [tableData, setTableData] = useState<TContractPipelineList[]>([])
  const [sortParams, setSortParams] =
    useState<TContractPipelineTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TContractPipelineListFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [selectedPipeline, setSelectedPipeline] =
    useState<TContractPipelineList | null>(null)
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const { addPipelinePopup } = useContractPagePopup()
  const { actions } = addPipelinePopup
  const dispatch = useDispatch()
  const isCommunity = user.role.includes('community')
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TContractPipelineTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TContractPipelineListParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getContractPipelineList({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const refreshTableDataWithInitialValues = () => {
    getTableData({ ...sortParams, filters, page: { ...DEFAULT_PAGE } })
    setPageInfo({ ...DEFAULT_PAGE })
    setSelectedPipeline(null)
  }

  useEffect(() => {
    refreshTableDataWithInitialValues()
  }, [])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TContractPipelineListFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  return (
    <div className="table-wrapper community-users contract-pipelines-table">
      <div className="table-wrapper__header flex justify-end">
        <Can I={ACTIONS.CREATE} a={SUBJECTS.CONTRACT_PIPELINE}>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            icon={<Plus16 />}
            onClick={actions.open}
            upperCase
          >
            {BTN_TXT.CREATE_PIPELINE_RECORD}
          </Button>
        </Can>
      </div>
      {!firstLoad && (
        <Table<TContractPipelineList>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            isCommunity,
            handelModal: setSelectedPipeline,
            userHs: user.health_system || ''
          })}
          className="contracts-table"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TContractPipelineListFilters>(
            '',
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
      <CreateContractPipelineModal
        successCreateCallback={refreshTableDataWithInitialValues}
      />
      {!!selectedPipeline && (
        <JoinModal
          uuid={selectedPipeline.uuid}
          alreadyJoined={selectedPipeline.joined}
          pipelineName={selectedPipeline.contract_category_name}
          successCallback={refreshTableDataWithInitialValues}
        />
      )}
    </div>
  )
}
